import * as types from "../types/PreOfferSetup";

const INITIAL_STATE = {}

export default function departmentsReducer(state = INITIAL_STATE, action) {
    let preofferData = action.payload
    switch (action.type) {
        case types.ADD_PREOFFER_RECEIVE:
            return { ...state, preofferData };
        case types.GET_PREOFFER_LIST_RECEIVE:
            return { ...state, ...preofferData };
        case types.GET_SINGLE_PREOFFER_RECEIVE:
            return { ...state, ...preofferData };
        case types.EDIT_PREOFFER_RECEIVE:
            return { ...state, ...preofferData };
        case types.DELETE_PREOFFER_RECEIVE:
            return { ...state, ...preofferData };
        default: return state;
    }
}