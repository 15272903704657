const Auth = {
  getAuth() {
    return localStorage.getItem("token") ? true : false;
  },
  logout() {
    localStorage.clear();
  },
};

export default Auth;
