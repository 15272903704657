
import * as types from '../types';

const INITIAL_STATE = {
    index : '/',
}

export default function locale(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CLICKED_PAGE_INDEX:
      return { ...state, ...action.payload};
    default:
      return state;
  }
};