import { call, put } from "redux-saga/effects";
import API from "../../network/apis/MyInterviews";
import * as myInterviews from "../actions/MyInterviews";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/MyInterviews";
import { dispatchError } from "utils/shared";

export function* myInterviewsRequest({ payload }) {
  try {
    const response = yield call(API.myInterviewsRequest, payload);
    yield put(myInterviews.myInterviewsReceive(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* myPrevInterviewsRequest({ payload }) {
  try {
    const response = yield call(API.myPrevInterviewsRequest, payload);
    yield put(myInterviews.myInterviewsReceive(response.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* InterviewDetailsRequest({ payload }) {
  try {
    const response = yield call(API.interviewDetailsRequest, payload);
    yield put(myInterviews.interviewDetailsRecieve(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* MyInterviewSagasWatch() {
  yield takeLatest(type.MY_INTERVIEWS_REQUEST, myInterviewsRequest);
  yield takeLatest(type.MY_PREV_INTERVIEWS_REQUEST, myPrevInterviewsRequest);
  yield takeLatest(type.INTERVIEW_DETAILS_REQUEST, InterviewDetailsRequest);
}
