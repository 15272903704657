import * as types from "../types/wizardSetup";

const INITIAL_STATE = {
  step: 1,
  setupIndex:1,
  isPhoneValid:false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_WIZARD_STEP:
      return { ...state, step: action.payload };
    case types.SETUP_WIZARD_INDEX:
      return { ...state, setupIndex: action.payload };
      case types.IS_PHONE_VALID:
        return { ...state, isPhoneValid : action.payload};
    default:
      return state;
  }
};
