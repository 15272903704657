import { call, put, takeLatest } from "redux-saga/effects";
import { dispatchError, showSnackbar } from "utils/shared";
import API from "../../network/apis/interviewEvaluationSetup";
import * as ACTION from "../actions/interviewEvaluationSetup";
import * as type from './../types/interviewEvaluationSetup'


export function* getInterviewEvaluationSetup() {
    try {
      const response = yield call(API.getInterviewEvaluationSetup);
      yield put(ACTION.receiveGetInterviewEvaluationSetup(response.data));
    } catch (err) {
      dispatchError(err.response?.data);
    }
  }

  export function* addInterviewEvaluationSetup(action) {
    try {
      yield call(API.addInterviewEvaluationSetup, action.payload);
      yield put(ACTION.requestGetInterviewEvaluationSetup());
      showSnackbar("savedSuccessfuly");
    } catch (err) {
      dispatchError(err.response?.data);
    }
  }

  export function* interviewEvaluationSetupSagasWatch() {
    yield takeLatest(
      type.GET_INTERVIEW_EVALUATION_SETUP_REQUEST,
      getInterviewEvaluationSetup
    );
    yield takeLatest(
      type.ADD_INTERVIEW_EVALUATION_SETUP_REQUEST,
      addInterviewEvaluationSetup
    );
  }