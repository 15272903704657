import { axiosInstance } from './config';

let handlerEnabled = true;

const requestSavedCards = async () =>
  await axiosInstance.get(`/Payment/GetCompanyCards`, {
    handlerEnabled,
  });

const requestActivateCards = async (data) =>
  await axiosInstance.put(`/Payment/ActivateCard?cardId=${data}`, {
    handlerEnabled,
  });

const requestDeleteCards = async (data) =>
  await axiosInstance.delete(`/Payment/DeleteCard?cardId=${data}`, {
    handlerEnabled,
  });

const requestSubscriptionPlans = async () =>
  await axiosInstance.get(`/admin/subscriptions/plans`, {
    handlerEnabled,
  });

const requestCompanyVatNumber = async () =>
  await axiosInstance.get(`/Company/checkOnCompanyVatNumberView`, {
    handlerEnabled,
  });
const requestCheckoutId = async ({
  isStandAloneMode,
  isMada,
  subscriptionPlanId,
  paymentType,
  billingForm,
  promocode,
  paymentBrand,
}) => {
  if (isStandAloneMode) {
    const data = billingForm;
    return await axiosInstance.post(`/Payment/GetHyperpayToken`, data, {
      handlerEnabled,
    });
  } else {
    const data = {
      SubscriptionPlanId: subscriptionPlanId,
      paymentType,
      billingAddress: billingForm,
      promocode,
      paymentBrand,
    };
    const URL = isMada ? '/Payment/PayWithMADA' : '/Payment/PayAndSubscribe';
    return await axiosInstance.post(URL, data, {
      handlerEnabled,
    });
  }
};

const requestCheckoutStatus = async ({
  IsMada,
  CheckoutId,
  ResourcePath,
  SubscriptionPlanId,
  paymentType,
  paymentProcess,
  IsSaveBillingAddress,
  promoCode,
}) => {
  const params = {
    CheckoutId,
    SubscriptionPlanId,
    ResourcePath,
    paymentType,
    IsSaveBillingAddress,
    PromoCode: promoCode,
  };
  const URL = IsMada
    ? '/Payment/PayWithMADAResponse'
    : '/Payment/PayAndSubscribeResponse';
  return await axiosInstance.get(URL, {
    params,
    handlerEnabled,
  });
};

const requestSubscriptionPayment = async (data) =>
  await axiosInstance.post(`/Payment/PayRecurring`, data, {
    handlerEnabled,
  });

const cancelSubscription = async () =>
  await axiosInstance.post(
    `/admin/subscriptions/cancel`,
    {},
    {
      handlerEnabled,
    }
  );
const requestGetBillingInfo = async () =>
  await axiosInstance.get(`/Payment/LastBillingAddress`, {
    handlerEnabled,
  });

const requestPayWithSavedCard = async (data) =>
  await axiosInstance.post(`/Payment/PayWithSavedCard`, data, {
    handlerEnabled,
  });

const requestRenewSubscriptionStatus = async ({ CheckoutId, ResourcePath }) =>
  await axiosInstance.get(`/Payment/RenewResult`, {
    params: {
      CheckoutId,
      ResourcePath,
    },
    handlerEnabled,
  });
const requestPaymentCalculation = async (data) => {
  return await axiosInstance.get(`/Payment/CalculateBalancForPlanChange`, {
    params: {
      ...data,
    },
    handlerEnabled,
  });
};

const requestPayFromWallet = async (data) => {
  return await axiosInstance.post(`/Payment/PayUsingWallet`, data, {
    handlerEnabled,
  });
};

const requestDowngradeLostData = async () => {
  return await axiosInstance.get(`/admin/subscriptions/downgrade_lost_data`, {
    handlerEnabled,
  });
};

const requestValidatePromoCode = async (data) => {
  return await axiosInstance.get(`/Payment/ValidatePromoCode`, {
    params: {
      ...data,
    },
    handlerEnabled,
  });
};

const invoiceGeneration = async () => {
  return await axiosInstance.get(`/admin/invoice/generate_invoice`, {
    handlerEnabled,
  });
};

const updateVatNumber = async (data) => {
  return await axiosInstance.put(`/Company/updateCompanyVatNumber`, data, {
    handlerEnabled,
  });
};
const companySubscribtionEndDate = async () => {
  return await axiosInstance.get(`/Company/subscribtionEndDate`, {
    handlerEnabled,
  });
};

export default {
  requestSavedCards,
  requestActivateCards,
  requestDeleteCards,
  requestSubscriptionPlans,
  requestCheckoutId,
  requestCheckoutStatus,
  requestSubscriptionPayment,
  cancelSubscription,
  requestGetBillingInfo,
  requestPayWithSavedCard,
  requestRenewSubscriptionStatus,
  requestPaymentCalculation,
  requestPayFromWallet,
  requestDowngradeLostData,
  requestValidatePromoCode,
  invoiceGeneration,
  requestCompanyVatNumber,
  updateVatNumber,
  companySubscribtionEndDate,
};
