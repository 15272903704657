export const GET_FLOWS_GRID_REQUEST = "GET_FLOWS_GRID_REQUEST";
export const GET_FLOWS_GRID_RECEIVE = "GET_FLOWS_GRID_RECEIVE";

export const DELETE_FLOW_REQUEST = 'DELETE_FLOW_REQUEST';
export const DELETE_FLOW_RECEIVE = 'DELETE_FLOW_RECEIVE';

export const INTERVIEWS_TITLES_REQUEST = 'INTERVIEWS_TITLES_REQUEST';
export const INTERVIEWS_TITLES_RECEIVE = 'INTERVIEWS_TITLES_RECEIVE';
export const SAVE_NEW_FLOW_REQUEST = "SAVE_NEW_FLOW_REQUEST";

export const EDIT_FLOW_DETAILS_REQUEST = "EDIT_FLOW_DETAILS_REQUEST";
export const EDIT_FLOW_DETAILS_RECEIVE = "EDIT_FLOW_DETAILS_RECEIVE";

export const SAVE_EDITED_FLOW_REQUEST = "SAVE_EDITED_FLOW_REQUEST";

export const SAVE_SINGLE_FLOW_STAGE_REQUEST = 'SAVE_SINGLE_FLOW_STAGE_REQUEST';

export const DELETE_STAGE_REQUEST = "DELETE_STAGE_REQUEST";
export const DELETE_STAGE_RECEIVE = "DELETE_STAGE_RECEIVE";

export const MOVE_APPLICANTS_TO_STAGE_REQUEST =
  "MOVE_APPLICANTS_TO_STAGE_REQUEST";

export const SET_DEFAULT_FLOW_REQUEST = 'SET_DEFAULT_FLOW_REQUEST';
export const DISABLE_SEND_OFFER_REQUEST = 'DISABLE_SEND_OFFER_REQUEST'
export const DISABLE_SEND_OFFER_RECEIVE = 'DISABLE_SEND_OFFER_RECEIVE'

export const DISABLE_PIPELINE_REQUEST = 'DISABLE_PIPELINE_REQUEST'
export const DISABLE_PIPELINE_RECEIVE = 'DISABLE_PIPELINE_RECEIVE'
export const ADS_SETTINGS_REQUEST = 'ADS_SETTINGS_REQUEST'
export const ADS_SETTINGS_RESPONSE = 'ADS_SETTINGS_RESPONSE'