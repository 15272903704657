import * as types from "../types/jobTitles";

export const listJobTitlesRequest = payload => ({
  type: types.LIST_JOB_TITLES_REQUEST,
  payload
});
export const listJobTitles = payload => ({
  type: types.LIST_JOB_TITLES,
  payload
});
export const addEditJobTitleRequest = (data,type) => ({
  type: types.ADD_EDIT_JOB_TITLE_REQUEST,
  payload : {data , type}
})

export const addEditJobTitleReceive = payload => ({
  type: types.ADD_EDIT_JOB_TITLE_RECEIVE,
  payload
})



export const requestGetJobTitle = data => {
  return {
    type: types.GET_JOB_TITLE_REQUEST,
    payload: data
  };
};

export const receiveGetJobTitle = data => {
  return {
    type: types.GET_JOB_TITLE_RECEIVE,
    payload: data
  };
};


export const requestGetJobTitleDescription = data => {
  return {
    type: types.GET_JOB_TITLE_DESCRIPTION_REQUEST,
    payload: data
  };
};

export const receiveGetJobTitleDescription = data => {
  return {
    type: types.GET_JOB_TITLE_DESCRIPTION_RECEIVE,
    payload: data
  };
};