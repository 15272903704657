import * as types from '../types';

const INITIAL_STATE = {
    closeBtn : 'block',
    closebtnStartLeft:"912px",
    openBtn : 'none',
    companyLogoSideMenuHeight : '100px',
    companyLogoSideMenuWidth : '100px',
    sideMenuWidth : '250px',
    sideMenuContent : 'block',
    sideMenuContentPaddingTop : '8px',
    sideMenuContentPaddingBottom : '8px',
    mainMrginLeft : '290px',
    mainMarginRight : '40px',
    navbarMarginLeft : '250px',
    navbarMarginRight : '0px',
    curvedNavbLeft : '16em',
    curvedNavbRight : 'auto',
    completeNave:"left: -164px",
    showPopover : 'none',


} ;

const startYourJournyMenu = (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.SET_START_YOUR_JOURNY_MENU :
            return {...state , ...action.payload};
        default:
            return state;
    }
}

export default startYourJournyMenu;