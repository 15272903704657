import * as types from '../types/uploadExcel';

export const requestAgentsExcelSample = () => ({
    type: types.AGENTS_EXCEL_SAMPLE_REQUEST
})
export const receiveAgentsExcelSample = payload => ({
    type: types.AGENTS_EXCEL_SAMPLE_RECEIVE,
    payload
})
export const requestUploadAgentsExcel = payload => ({
    type: types.UPLOAD_AGENTS_EXCEL_REQUEST,
    payload
})
export const receiveUploadAgentsExcel = payload => ({
    type: types.UPLOAD_AGENTS_EXCEL_RECEIVE,
    payload
})

export const requestSalaryExcelSample = () => ({
    type: types.SALARY_EXCEL_SAMPLE_REQUEST
})
export const receiveSalaryExcelSample = payload => ({
    type: types.SALARY_EXCEL_SAMPLE_RECEIVE,
    payload
})
export const requestUploadSalaryExcel = payload => ({
    type: types.UPLOAD_SALARY_EXCEL_REQUEST,
    payload
})
export const receiveUploadSalaryExcel = payload => ({
    type: types.UPLOAD_SALARY_EXCEL_RECEIVE,
    payload
})
export const salaryExcelFileUploaded = payload => ({
    type: types.SALARY_EXCEL_FILE_UPLOADED,
    payload
})


export const uploadApplicantsRequest= payload => ({
    type: types.IMPORT_APPLICANTS_REQUEST,
    payload
})

export const downloadApplicantsSampleRequest= payload => ({
    type: types.DOWNLOAD_APPLICANTS_SAMPLE_REQUEST,
    payload
})

export const downloadApplicantsSampleRecieve= payload => ({
    type: types.DOWNLOAD_APPLICANTS_SAMPLE_RECIEVE,
    payload
})