import {axiosInstance} from './config';

let handlerEnabled = true;


const getSocialMediaPlaceholder = async () => {
    return await axiosInstance.get(`/admin/social_media/placeholders` , {handlerEnabled});
}

const getSocialMediaSettings = async () => {
    return await axiosInstance.get(`/admin/social_media/settings` , {handlerEnabled});
}

const addEditSocialMediaSettings = async (data) => {
    return await axiosInstance.post(`/admin/social_media/upsert`, data , {handlerEnabled});
}


export default {
    getSocialMediaPlaceholder,
    getSocialMediaSettings,
    addEditSocialMediaSettings
};