import { axiosInstance } from "./config";

let handlerEnabled = true;

const getinvoices = async ({ page_number }) => {
  let params = {
    page_number: page_number,
    page_size: 10,
  };
  return await axiosInstance.get(`/admin/invoice/list`, {
    params,
    handlerEnabled,
  });
};
const getinvoiceDetails = async ({ invoice_id }) => {
  return await axiosInstance.get(
    `/admin/invoice/${invoice_id}`,
    {
      handlerEnabled,
    }
  );
};

export default {
  getinvoices,
  getinvoiceDetails,
};
