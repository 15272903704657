import { call, put } from 'redux-saga/effects';
import API from '../../network/apis/apis';
import * as ACTION from '../actions/login';
import store from '../../store';
import history from '../../routes/History';
import {
  dispatchError,
  getDecodedAccessToken,
  getDomain,
  showSnackbar,
  getURLParams,
} from '../../utils/shared';
import { takeLatest } from 'redux-saga/effects';
import * as type from '../types/index';
import { isUserGhost } from '../../utils/shared';
import {realTimeDbUnreadNotifications} from 'firebase/utils';

export function* login(action) {
  try {
    let response = {};
    if (action.payload.urlParam) {
      response = {
        data: {
          data: {
            token: getURLParams('token'),
            first_name: getURLParams('firstName'),
            last_name: getURLParams('lastName'),
            image_url: getURLParams('imageUrl'),
            company_image_url: getURLParams('companyImageUrl'),
            is_wizard: getURLParams('isWizard').toLowerCase(),
            company_name: getURLParams('companyName'),
            plan: getURLParams('plan'),
            subscription_end: getURLParams('subscriptionEnd'),
            is_view_only: getURLParams('isViewOnly').toLowerCase(),
            is_a_ghost_user: getURLParams('isGhost'),
            is_verified: getURLParams('isVerified').toLowerCase(),
            is_company_profile_complete: getURLParams(
              'isCompanyProfileComplete'
            ),
            show_email_sync_reminder: getURLParams(
              'showEmailSyncReminder'
            ),
          },
        },
      };
      localStorage.setItem('token', response.data.data.token);
      isUserGhost(response.data.data.is_ghost);
    } else {
      response = yield call(API.login, action.payload);
      isUserGhost(response.data.data.is_ghost);

    }

    yield put(ACTION.receiveLogin(response.data.data));
    const { redirect_url, ...authObj } = response.data.data;
    const decode_token = getDecodedAccessToken(response.data.data.token);
    realTimeDbUnreadNotifications(decode_token.sub)
    for (const property in authObj) {
      localStorage.setItem(property, response.data.data[property]);
    }
    if (decode_token.action_permission !== undefined) {
      localStorage.setItem('permissions', decode_token.action_permission);
    }
    localStorage.setItem('roles', decode_token.role);
    if (localStorage.getItem('prevPath')) {
      history.push(localStorage.getItem('prevPath'));
    } else {
      response.data.data.is_view_only === 'true'
        ? history.push('/subscriptionPlans')
        : history.push('/');
    }
    localStorage.removeItem('prevPath');
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* validateDomain(action) {
  try {
    const response = yield call(API.checkDomain, action.payload);
    yield put(ACTION.receiveValidateDomain(response.data.data));
    getDomain();
  } catch (err) {
    if (err.request.status === 404) {
      history.push('/notFound');
    } else if (err.request.status === 409) {
      yield put(ACTION.receiveValidateDomain(err.response?.data.error.message));
    }
    dispatchError(err.response?.data);
  }
}

export function* forgetPassword(action) {
  try {
    const response = yield call(API.forgetPassword, action.payload);
    yield put(ACTION.receiveForgetPassword(response.data.data));
    showSnackbar('done');
    history.push('/redirect-login');
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* resetPassword(action) {
  try {
    const response = yield call(API.resetPassword, action.payload);
    yield put(ACTION.receiveResetPassword(response.data.data));
    showSnackbar('done');
    window.location.href = response.data.data.redirect_url;
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* signinWithSocialSDKRequest({ payload }) {
  try {
    const response = yield call(API.socialLoginsRequest, payload);
    window.location.href = response.data.redirect_url;
  } catch (err) {
    if (err.response?.data.error?.message.includes('phone number')) {
      yield put(ACTION.socialLoginPhoneRequired(payload));
    }
    dispatchError(err.response?.data);
  }
}

export function* AuthSagasWatch() {
  yield takeLatest(type.LOGIN_REQUEST, login);
  yield takeLatest(type.VALIDATE_DOMAIN_REQUEST, validateDomain);
  yield takeLatest(type.FORGET_PASSWORD_REQUEST, forgetPassword);
  yield takeLatest(type.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(type.SOCIAL_LOGIN_REQUEST, signinWithSocialSDKRequest);
}
