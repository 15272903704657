import { axiosInstance } from "./config";

let handlerEnabled = true;
const getEmailTemplate = (props) => {
  return {
    updateResume: `admin/candidates/get_ResumeEmailData/${props.job_id}`,
    "reschedule-interview": `admin/job_requests/${props.job_request_id}/applications/${props.application_id}/get_interviewEmailData/${props.interview_type}`,
    interview: `admin/job_requests/${props.job_request_id}/applications/${props.application_id}/get_interviewEmailData/${props.interview_type}`,
    "add-applicant": `/admin/candidates/get_addAplicantEmailContentData`,
    "add-to-job-opening": `/admin/candidates/add_to_job_opening/${props.job_id}/email_template`,
    "regret-email": `admin/job_requests/{job_request_id}/get_regretEmailContentData${
      props.application_id?.length === 1 ? "/" + props.application_id[0] : ""
    }`,
    "preoffer-email": `admin/candidates/get_preofferEmailData/${props.application_id}`,
    "more-info": `admin/moreInformationRequest/get_addMoreInfoRequestEmailContentData/${props.job_id}`,
  };
};
const getEmailTemplateRequest = async (props) => {
  const handler = props.getParams
    ? {
        params: props.getParams,
        handlerEnabled,
      }
    : {
        handlerEnabled,
      };
  return await axiosInstance.get(
    `${getEmailTemplate(props)[props.template_type]}`,
    {
      ...handler,
    }
  );
};

export default {
  getEmailTemplateRequest,
};
