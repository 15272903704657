import { call, put, takeEvery } from "redux-saga/effects";
import * as types from '../types/contactUs';
import API from '../../network/apis/contactUs';
import { dispatchError, showSnackbar } from "utils/shared";
import * as ACTION from '../actions/contactUs';

export function* contactUsForm(action) {
  try {
    const response = yield call(API.postContactUs, action.payload);
    yield put(ACTION.recieveContactUsForm(response.data));
    showSnackbar('contactModalSuccess');
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getContactUsContent() {
  try {
    const response = yield call(API.getContactUs);
    yield put(ACTION.recieveContactUsContent(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* ContactUsSagasWatch() {
  yield takeEvery(types.FETCH_CONTACT_US_FORM_REQUEST, contactUsForm);
  yield takeEvery(types.FETCH_CONTACT_US_REQUEST, getContactUsContent);
}
