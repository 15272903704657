import { call, put } from "redux-saga/effects";
import API from "../../network/apis/candidateTalentPoolTable";
import * as ACTION from "../actions/candidateTalentPool";
import * as type from "../types/candidateTalentPool";
import { takeLatest } from "redux-saga/effects";
import { showSnackbar, dispatchError } from "utils/shared";
import { showSnackbarAction } from "../actions/snackbar";
import ReactGA from "react-ga";
import store from "../../store";
import History from "routes/History";

export function* candidateTalentPool({ payload }) {
  try {
    const response = yield call(API.candidateTalentPool, payload);
    yield put(ACTION.candidateTalentPoolRecieve(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* candidateTalentPoolJobOpening({ payload }) {
  try {
    yield call(API.candidateTalentPoolJobOpening, payload);
    yield showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* UploadApplicantsExcel({ payload }) {
  try {
    const response = yield call(API.UploadApplicantsExcel, payload);
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Add applicants`,
      action: "User requested add applicants ",
    });
    if (response.data.data) {
      yield put(showSnackbarAction(response.data.data, "error"));
    } else {
      showSnackbar("savedSuccessfuly");
    }
  } catch (err) {
    dispatchError(err.response?.data);
  }
  yield candidateTalentPool({ pageNumber: 1,pageSize: 10 })
}
export function* getPipelineApplication({ payload }) {
  try {
    const response = yield call(API.getPipelineApplications, payload);
    yield put(ACTION.receiveGetPipelineApplications(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* requestAddApplicant({ payload }) {
  try {
    const response = yield call(API.addApplicants, payload);
    showSnackbar("savedSuccessfuly");
    yield put(ACTION.receiveAddApplicants(response.data));
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Add applicants`,
      action: "User requested add applicants ",
    });
  } catch (error) {
    dispatchError(error.response?.data);
  }
  yield candidateTalentPool({ pageNumber: 1,pageSize: 10 })
}
export function* getPipelineStageTalentPool({ payload }) {
  try {
    const response = yield call(API.getPipelineStages, payload);
    yield put(ACTION.receivePipelineStages(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* CandidateTalentPoolWatch() {
  yield takeLatest(type.CANDIDATE_TALENT_POOL_REQUEST, candidateTalentPool);
  yield takeLatest(
    type.CANDIDATE_TALENT_POOL_JOB_OPENING_REQUEST,
    candidateTalentPoolJobOpening
  );
  yield takeLatest(type.IMPORT_APPLICANTS_REQUEST, UploadApplicantsExcel);
  yield takeLatest(type.REQUEST_ADD_APPLICANT, requestAddApplicant);
}
