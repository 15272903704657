import React from 'react';
import Button from '@material-ui/core/Button';
import './Button.scss';

export const ButtonComponent = ({
  variant,
  classes,
  content,
  color,
  handleClick,
  type,
  disabled,
  Icon,
  from
}) => {
  const is_view_only = JSON.parse(localStorage.getItem('is_view_only'));
  return (
    <Button
      disabled={disabled === false ? false : disabled || is_view_only}
      type={type}
      variant={
        disabled === false ? variant : is_view_only ? 'contained' : variant
      }
      className={`medium_font ${
        variant === 'contained' && 'text-white'
      } ${classes}`}
      color={color ? color : 'secondary'}
      onClick={handleClick}
      startIcon={Icon}
      from={from}
    >
      {content}
    </Button>
  );
};
