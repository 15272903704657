export const CANDIDATE_PORTAL_SETUP_REQUEST = 'CANDIDATE_PORTAL_SETUP_REQUEST'
export const CANDIDATE_PORTAL_SETUP_RECIEVE = 'CANDIDATE_PORTAL_SETUP_RECIEVE'

export const SAVE_PROFILE_SETUP_REQUEST = 'SAVE_PROFILE_SETUP_REQUEST'
export const SUBSCRIPTIONS_HISTORY_PLAN_RECIEVE = 'SUBSCRIPTIONS_HISTORY_PLAN_RECIEVE'

export const SET_CANDIDATE_DEFAULT_COLOR_RECIEVE = "SET_CANDIDATE_DEFAULT_COLOR_RECIEVE";
export const SET_CANDIDATE_DEFAULT_COLOR_REQUEST = "SET_CANDIDATE_DEFAULT_COLOR_REQUEST";

export const SET_CANDIDATE_DEFAULT_LOGO_RECIEVE = "SET_CANDIDATE_DEFAULT_LOGO_RECIEVE";
export const SET_CANDIDATE_DEFAULT_LOGO_REQUEST = "SET_CANDIDATE_DEFAULT_LOGO_REQUEST";


export const RECOMMENDED_CANDIDATE_REQUEST = 'RECOMMENDED_CANDIDATE_REQUEST'

export const GET_RECOMMENDED_CANDIDATE_REQUEST="GET_RECOMMENDED_CANDIDATE_REQUEST"
export const GET_RECOMMENDED_CANDIDATE__RECIEVE="GET_RECOMMENDED_CANDIDATE__RECIEVE"
