import { axiosInstance } from "./config";

const getContractTemplateSettings = async () =>
  await axiosInstance.get(`/admin/contract_template_settings`);

const getContractTemplateDefaultSettings = async () =>
  await axiosInstance.get(`/admin/contract_template_settings/default`);

const getContractTemplatePlaceholders = async () =>
  await axiosInstance.get(`/admin/contract_template_settings/placeholders`);

const saveContractTemplateSettings = async (data) =>
  await axiosInstance.post(`/admin/contract_template_settings`, data);

const deteleContractTemplateCoverLetter = async () =>
  await axiosInstance.delete(`/admin/contract_template_settings/cover_letter`);
export default {
  getContractTemplateSettings,
  getContractTemplateDefaultSettings,
  getContractTemplatePlaceholders,
  saveContractTemplateSettings,
  deteleContractTemplateCoverLetter,
};
