import { call, put } from "redux-saga/effects";
import API from "../../network/apis/offerTemplateSetup";
import * as ACTIONS from "../actions/offerTemplateSetup";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/offerTemplateSetup";
import { dispatchError, showSnackbar } from "utils/shared";
import history from "../../routes/History";


export function* getOfferTemplateSettings() {
  try {
    const response = yield call(API.getOfferTemplateSettings);
    yield put(ACTIONS.getOfferTemplateSettingsReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getOfferTemplateDefaultSettings() {
  try {
    const response = yield call(API.getOfferTemplateDefaultSettings);
    yield put(ACTIONS.getOfferTemplateDefaultSettingsReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getOfferTemplatePlaceholders() {
  try {
    const response = yield call(API.getOfferTemplatePlaceholders);
    yield put(ACTIONS.getOfferTemplatePlaceholdersReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* saveOfferTemplateSettings({ payload }) {
  try {
    yield call(API.saveOfferTemplateSettings, payload);
    yield put(ACTIONS.getOfferTemplateSettingsRequest());
    showSnackbar("done");
    if(payload.isSaveOnly){
      history.push("/setup");
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* deleteCoverLetter() {
  try {
    yield call(API.deteleOfferTemplateCoverLetter);
    yield put(ACTIONS.getOfferTemplateSettingsRequest());
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* getOfferBytesRequest({payload}) {
  try {
    yield put(ACTIONS.getOfferBytesRecieve(null));
    const response = yield call(API.get_offerFileBytes, payload);
    yield put(ACTIONS.getOfferBytesRecieve(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* OfferTemplateSetupSagasWatch() {
  yield takeLatest(
    type.GET_OFFER_TEMPLATE_SETTINGS_REQUEST,
    getOfferTemplateSettings
  );
  yield takeLatest(
    type.GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_REQUEST,
    getOfferTemplateDefaultSettings
  );
  yield takeLatest(
    type.GET_OFFER_TEMPLATE_PLACEHOLDERS_REQUEST,
    getOfferTemplatePlaceholders
  );
  yield takeLatest(
    type.SAVE_OFFER_TEMPLATE_SETTINGS_REQUEST,
    saveOfferTemplateSettings
  );
  yield takeLatest(
    type.DELETE_OFFER_TEMPLATE_COVER_LETTER_REQUEST,
    deleteCoverLetter
  );
  yield takeLatest(
    type.GET_OFFER_FILE_BYTES,
    getOfferBytesRequest
  );
}