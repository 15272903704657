import * as types from "../types/CompanySetup";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  let successBranch = action.payload;
  switch (action.type) {
    case types.ADD_UPDATE_BRANCH_RECEIVE:
      return { ...state, successBranch };
    case types.DELETE_BRANCH_RECEIVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
