export const GET_OFFER_TEMPLATE_SETTINGS_REQUEST = "GET_OFFER_TEMPLATE_SETTINGS_REQUEST";
export const GET_OFFER_TEMPLATE_SETTINGS_RECEIVE = "GET_OFFER_TEMPLATE_SETTINGS_RECEIVE";

export const SAVE_OFFER_TEMPLATE_SETTINGS_REQUEST = "SAVE_OFFER_TEMPLATE_SETTINGS_REQUEST";
export const SAVE_OFFER_TEMPLATE_SETTINGS_RECEIVE = "SAVE_OFFER_TEMPLATE_SETTINGS_RECEIVE";

export const DELETE_OFFER_TEMPLATE_COVER_LETTER_REQUEST = "DELETE_OFFER_TEMPLATE_COVER_LETTER_REQUEST";
export const DELETE_OFFER_TEMPLATE_COVER_LETTER_RECEIVE = "DELETE_OFFER_TEMPLATE_COVER_LETTER_RECEIVE";

export const GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_REQUEST = "GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_REQUEST";
export const GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_RECEIVE = "GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_RECEIVE";

export const GET_OFFER_TEMPLATE_PLACEHOLDERS_REQUEST = "GET_OFFER_TEMPLATE_PLACEHOLDERS_REQUEST";
export const GET_OFFER_TEMPLATE_PLACEHOLDERS_RECEIVE = "GET_OFFER_TEMPLATE_PLACEHOLDERS_RECEIVE";
export const GET_OFFER_FILE_BYTES = "GET_OFFER_FILE_BYTES";
export const GET_OFFER_FILE_BYTES_RECIEVE = "GET_OFFER_FILE_BYTES_RECIEVE";