import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/preOfferSetup";
import * as ACTION from "../actions/PreOfferSetup";
import * as type from "../types/PreOfferSetup";
import store from "..";
import history from "../../routes/History";
import {
  dispatchError,
  showSnackbar,
  pushToUrlNewParam,
} from "../../utils/shared";

export function* addNewPreOffer(action) {
  try {
    const response = yield call(API.addPreOfferDoc, action.payload.data);
    yield put(ACTION.receiveAddNewPreOffer(response.data.data));
    showSnackbar("savedSuccessfuly");
    action.payload.type !== "saveNew" && history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAllPreOffer(action) {
  try {
    const response = yield call(API.getAllPreOfferDocs, action.payload);
    yield put(ACTION.receiveGetAllPreOffer(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getSinglePreOffer(action) {
  try {
    const response = yield call(API.getPreOfferDoc, action.payload);
    yield put(ACTION.receiveGetSinglePreOffer(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* editPreOffer(action) {
  try {
    const response = yield call(API.editPreOfferDoc, action.payload);
    yield put(ACTION.receiveEditPreOffer(response.data));
    showSnackbar("savedSuccessfuly");
    history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deletePreOffer({
  payload: { preOfferId, preOfferDocsLength, activePage },
}) {
  try {
    const response = yield call(API.deletePreOfferDoc, preOfferId);
    yield put(ACTION.receiveDeletePreOffer(response.data));
    store.dispatch(
      ACTION.requestGetAllPreOffer(
        preOfferDocsLength === 1 && activePage > 1 ? activePage - 1 : activePage
      )
    );
    pushToUrlNewParam(
      preOfferDocsLength === 1 && activePage > 1 ? activePage - 1 : activePage
    );
    showSnackbar("deletedSuccessfully");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}


export function* PreOfferDocsSagasWatch() {
  yield takeLatest(type.ADD_PREOFFER_REQUEST, addNewPreOffer);
  yield takeLatest(type.GET_PREOFFER_LIST_REQUEST, getAllPreOffer);
  yield takeLatest(type.GET_SINGLE_PREOFFER_REQUEST, getSinglePreOffer);
  yield takeLatest(type.EDIT_PREOFFER_REQUEST, editPreOffer);
  yield takeLatest(type.DELETE_PREOFFER_REQUEST, deletePreOffer);
}