import { axiosInstance } from "./config";

let handlerEnabled = true;

const addPreOfferDoc = async data => {
    return await axiosInstance.post(`/admin/pre-offer_documents`, data, { handlerEnabled });
};

const getAllPreOfferDocs = async pageNumber => {
    let params = {
        page_number: pageNumber ? pageNumber : 1,
        page_size: 10
    };
    return await axiosInstance.get(`/admin/pre-offer_documents`, {
        params,
        handlerEnabled
    });
};


const getPreOfferDoc = async id => {
    return await axiosInstance.get(`/admin/pre-offer_documents/${id}`, { handlerEnabled });
};

const editPreOfferDoc = async data => {
    return await axiosInstance.put(`/admin/pre-offer_documents/${data.id}`, data, {
        handlerEnabled
    });
};

const deletePreOfferDoc = async id => {
    return await axiosInstance.delete(`/admin/pre-offer_documents/${id}`, {
        handlerEnabled
    });
};

export default {
    addPreOfferDoc,
    getAllPreOfferDocs,
    getPreOfferDoc,
    editPreOfferDoc,
    deletePreOfferDoc
};
