import { axiosInstance } from "network/apis/config";
import { blobToBase64 } from "utils/shared";
export const msalConfig = {
  auth: {
    clientId: "3295c599-7e51-45e0-9dda-8765bce07b32",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", " User.ReadWrite"],
};

//endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};

export const graphUserPhotoData = async (accessToken) => {
  createRequestHeaders(accessToken);
  return axiosInstance
    .get(graphConfig.graphMePhotoEndpoint, {
      handlerEnabled: true,
    })
    .then((response) => {
      if (response.status === 404) {
        throw new Error("");
      } else {
        return response.blob();
      }
    })
    .then(async (e) => {
      const imageURL = await blobToBase64(e);
      return imageURL;
    })

    .catch((e) => console.error(e, "error-graph-photo"));
};
export const graphUserData = async (accessToken) => {
  createRequestHeaders(accessToken);
  return axiosInstance
    .get(graphConfig.graphMeEndpoint, {
      handlerEnabled: true,
    })
    .then(e => e.data)
    .catch((e) => console.error(e, "error-graph-user"));
};

const createRequestHeaders = (token) => {
 // localStorage.setItem("token", token);
};
