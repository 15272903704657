export const AGENTS_EXCEL_SAMPLE_REQUEST = 'AGENTS_EXCEL_SAMPLE_REQUEST'
export const AGENTS_EXCEL_SAMPLE_RECEIVE = 'AGENTS_EXCEL_SAMPLE_RECEIVE'

export const UPLOAD_AGENTS_EXCEL_REQUEST = 'UPLOAD_AGENTS_EXCEL_REQUEST'
export const UPLOAD_AGENTS_EXCEL_RECEIVE = 'UPLOAD_AGENTS_EXCEL_RECEIVE'

export const SALARY_EXCEL_SAMPLE_REQUEST = 'SALARY_EXCEL_SAMPLE_REQUEST'
export const SALARY_EXCEL_SAMPLE_RECEIVE = 'SALARY_EXCEL_SAMPLE_RECEIVE'

export const UPLOAD_SALARY_EXCEL_REQUEST = 'UPLOAD_SALARY_EXCEL_REQUEST'
export const UPLOAD_SALARY_EXCEL_RECEIVE = 'UPLOAD_SALARY_EXCEL_RECEIVE'

export const SALARY_EXCEL_FILE_UPLOADED = 'SALARY_EXCEL_FILE_UPLOADED';

//UPLOAD APPLICANTS SHEET
export const IMPORT_APPLICANTS_REQUEST = 'UPLOAD_APPLICANTS_REQUEST';

//DOWNLOAD APPLICANTS SHEET
export const DOWNLOAD_APPLICANTS_SAMPLE_REQUEST = 'DOWNLOAD_APPLICANTS_SAMPLE_REQUEST';
export const DOWNLOAD_APPLICANTS_SAMPLE_RECIEVE = 'DOWNLOAD_APPLICANTS_SAMPLE_RECIEVE';
