import * as types from "../types/contactUs";

const INITIAL_STATE = {
    contact_us_data : {} ,
    contact_us_form : {},
    isModalOpen: false,
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.FETCH_CONTACT_US_FORM_RECEIVE:
            return { ...state, contact_us_form : {...action.payload}};
        case types.FETCH_CONTACT_US_RECEIVE:
            return { ...state, contact_us_data : {...action.payload}};
            case types.IS_MODAL_OPEN:
                return { ...state, isModalOpen : action.payload};    
        default: return state;
    }
}