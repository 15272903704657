import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/invoices";
import API from "../../network/apis/Invoices";
import { dispatchError } from "utils/shared";
import * as ACTION from "../actions/invoices";
import store from "store";
import { receiveInvoiceData } from "store/actions/payment";

export function* getInvoices(action) {
  try {
    const response = yield call(API.getinvoices, action.payload);
    yield put(ACTION.recieveInvoices(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getInvoiceDetails(action) {
  try {
    const response = yield call(API.getinvoiceDetails, action.payload);
    yield put(store.dispatch(receiveInvoiceData(response.data.data)));
    yield put(store.dispatch(ACTION.handleModalState(true)))
  } catch (err) {
    yield put(store.dispatch(ACTION.handleModalState(false)))
    dispatchError(err.response?.data);
  }
}

export function* InvoicesSagasWatch() {
  yield takeEvery(types.FETCH_INVOICES_REQUEST, getInvoices);
  yield takeEvery(types.FETCH_INVOICE_DETAILS_REQUEST, getInvoiceDetails);
}
