const ApprovalActions = [
  { id: 1, name_ar: "انشاء طلب وظيفة", name_en: "Create manpower requset" },
  { id: 2, name_ar: "إرسال العرض", name_en: "Send offer" },
  { id: 3, name_ar: "إرسال العقد", name_en: "Send contract" },
];

const maritlStatusOptions = [
  { id: 1, name_ar: "متزوج ", name_en: "Married" },
  { id: 2, name_ar: "أعزب ", name_en: "Single" },
  { id: 3, name_ar: "مطلق ", name_en: "Divorced" },
  { id: 4, name_ar: "أرمل ", name_en: "Widow" },
];

const proficiencyLevel = [
  { id: 1, name_ar: "بطلاقة", name_en: "Fluent" },
  { id: 2, name_ar: "متوسطة", name_en: "Intermediate" },
  { id: 3, name_ar: "منخفضة", name_en: "Low" },
  { id: 4, name_ar: "لا شيء", name_en: "None" },
];

const interviewsTypes = [
  { id: 1, name_ar: "فيديو", name_en: "Video" },
  { id: 2, name_ar: "وجها لوجه", name_en: "Face to face" },
  { id: 3, name_ar: "الهاتف", name_en: "Phone" },
];

const applicationStatus = [
  { id: 1, name_ar: "المتقدمين", name_en: "Applicants" },
  { id: 2, name_ar: "القائمة المختصرة", name_en: "Shortlisted" },
  { id: 3, name_ar: "المقابلات", name_en: "Interview" },
  { id: 4, name_ar: "العروض", name_en: "Offers" },
  { id: 5, name_ar: "المقبولين", name_en: "Rejected" },
  { id: 6, name_ar: "المرفوضين", name_en: "Accepted" },
];
const pipelineActions = [
  { id: 1, name_ar: "ارسال بريد الكترونى", name_en: "Send Email" },
  { id: 2, name_ar: "تحديد مقابلة", name_en: "Interview Form" },
  { id: 3, name_ar: "رفض", name_en: "Reject Form" },
  { id: 4, name_ar: "ارسال عرض", name_en: "Send Offer Form" },
  { id: 6, name_ar: "ارسال ارفاق ملفات", name_en: "Send pre-offer" },
  { id: 7, name_ar: "مقبول", name_en: "Accept" },
  { id: 10, name_ar: "إرسال عقد", name_en: "Send Contract" },
];

const actionsList = [
  { id: 1, name_ar: "انتقال الى", name_en: "Move to" },
  { id: 2, name_ar: "اضافة متقدم", name_en: "Add applicants" },
  { id: 3, name_ar: "اضف تصنيف", name_en: "Add rate" },
  { id: 4, name_ar: "ارسال بريد الكترونى", name_en: "Send email" },
  { id: 5, name_ar: " اضف الى وظيفة", name_en: "Add to job opening" },
  { id: 6, name_ar: "طلب معلومات إضافية", name_en: "More info request" },
];

const yearsOfExperience = [
  { id: 0, name_ar: "٠", name_en: "0" },
  { id: 1, name_ar: "١", name_en: "1" },
  { id: 2, name_ar: "٢", name_en: "2" },
  { id: 3, name_ar: "٣", name_en: "3" },
  { id: 4, name_ar: "٤", name_en: "4" },
  { id: 5, name_ar: "٥", name_en: "5" },
  { id: 6, name_ar: "٦", name_en: "6" },
  { id: 7, name_ar: "٧", name_en: "7" },
  { id: 8, name_ar: "٨", name_en: "8" },
  { id: 9, name_ar: "٩", name_en: "9" },
  { id: 10, name_ar: "١٠", name_en: "10" },
  { id: 11, name_ar: "١١", name_en: "11" },
  { id: 12, name_ar: "١٢", name_en: "12" },
  { id: 13, name_ar: "١٣", name_en: "13" },
  { id: 14, name_ar: "١٤", name_en: "14" },
  { id: 15, name_ar: "١٥", name_en: "15" },
  { id: 16, name_ar: "١٦", name_en: "16" },
  { id: 17, name_ar: "١٧", name_en: "17" },
  { id: 18, name_ar: "١٨", name_en: "18" },
  { id: 19, name_ar: "١٩", name_en: "19" },
  { id: 20, name_ar: "٢٠", name_en: "20" },
  { id: 21, name_ar: "٢١", name_en: "21" },
  { id: 22, name_ar: "٢٢", name_en: "22" },
  { id: 23, name_ar: "٢٣", name_en: "23" },
  { id: 24, name_ar: "٢٤", name_en: "24" },
  { id: 25, name_ar: "٢٥", name_en: "25" },
  { id: 26, name_ar: "٢٦", name_en: "26" },
  { id: 27, name_ar: "٢٧", name_en: "27" },
  { id: 28, name_ar: "٢٨", name_en: "28" },
  { id: 29, name_ar: "٢٩", name_en: "29" },
  { id: 30, name_ar: "٣٠", name_en: "30" },

];


const ageList = [
  { id: 18, name_ar: "١٨", name_en: "18" },
  { id: 19, name_ar: "١٩", name_en: "19" },
  { id: 20, name_ar: "٢٠", name_en: "20" },
  { id: 21, name_ar: "٢١", name_en: "21" },
  { id: 22, name_ar: "٢٢", name_en: "22" },
  { id: 23, name_ar: "٢٣", name_en: "23" },
  { id: 24, name_ar: "٢٤", name_en: "24" },
  { id: 25, name_ar: "٢٥", name_en: "25" },
  { id: 26, name_ar: "٢٦", name_en: "26" },
  { id: 27, name_ar: "٢٧", name_en: "27" },
  { id: 28, name_ar: "٢٨", name_en: "28" },
  { id: 29, name_ar: "٢٩", name_en: "29" },
  { id: 30, name_ar: "٣٠", name_en: "30" },
  { id: 31, name_ar: "٣١", name_en: "31" },
  { id: 32, name_ar: "٣٢", name_en: "32" },
  { id: 33, name_ar: "٣٣", name_en: "33" },
  { id: 34, name_ar: "٣٤", name_en: "34" }, 
  { id: 35, name_ar: "٣٥", name_en: "35" },
  { id: 36, name_ar: "٣٦", name_en: "36" },
  { id: 37, name_ar: "٣٧", name_en: "37" },
  { id: 38, name_ar: "٣٨", name_en: "38" },
  { id: 39, name_ar: "٣٩", name_en: "39" },
  { id: 40, name_ar: "٤٠", name_en: "40" },
  { id: 41, name_ar: "٤١", name_en: "41" },
  { id: 42, name_ar: "٤٢", name_en: "42" }, 
  { id: 43, name_ar: "٤٣", name_en: "43" },
  { id: 44, name_ar: "٤٤", name_en: "44" },
  { id: 45, name_ar: "٤٥", name_en: "45" },
  { id: 46, name_ar: "٤٦", name_en: "46" },
  { id: 47, name_ar: "٤٧", name_en: "47" },
  { id: 48, name_ar: "٤٨", name_en: "48" },
  { id: 49, name_ar: "٤٩", name_en: "49" },
  { id: 50, name_ar: "٥٠", name_en: "50" },
  { id: 51, name_ar: "٥١", name_en: "51" },
  { id: 52, name_ar: "٥٢", name_en: "52" },
  { id: 53, name_ar: "٥٣", name_en: "53" },
  { id: 54, name_ar: "٥٤", name_en: "54" },
  { id: 55, name_ar: "٥٥", name_en: "55" },
  { id: 56, name_ar: "٥٦", name_en: "56" },
  { id: 57, name_ar: "٥٧", name_en: "57" },
  { id: 58, name_ar: "٥٨", name_en: "58" },
  { id: 59, name_ar: "٥٩", name_en: "59" },
  { id: 60, name_ar: "٦٠", name_en: "60" },
  { id: 61, name_ar: "٦١", name_en: "61" },  
  { id: 62, name_ar: "٦٢", name_en: "62" }, 
  { id: 63, name_ar: "٦٣", name_en: "63" },
  { id: 64, name_ar: "٦٤", name_en: "64" },
  { id: 65, name_ar: "٦٥", name_en: "65" },
];

const genderOptions = [
  { id: 1, name_ar: "ذكر", name_en: "Male" },
  { id: 2, name_ar: "أنثى", name_en: "Female" },
];
const genderOptionsWithAll = [
  { id: 1, name_ar: "ذكر", name_en: "Male" },
  { id: 2, name_ar: "أنثى", name_en: "Female" },
  { id: null, name_ar: "الكل", name_en: "All" },
];

const emailTypeOptions = [
  { id: 1, name_ar: "بريد الكترونى معدل", name_en: "Customized email" },
  { id: 2, name_ar: " تحديث السيرة الذاتية", name_en: "Update resume" },
  // { id: 3, name_ar: "ارفاق ملفات", name_en: "Pre-offer" },
];
const AvaliableFor = [
  {
    id: 1,
    name_en: "New",
    name_ar: "وظيفة جديدة",
  },
  {
    id: 2,
    name_en: "Replacement",
    name_ar: "وظيفة بديلة",
  },
];

const integrationTypes = {
  GoogleCalendar: 1,
  Office365: 2,
  corvirtus: 3,
  Criteria: 4,
  IndeedAssessments: 5,
  Coderbyte: 6,
  Testdome: 7,
  Unifonic: 8,
};

const approvalStage = [
  { id: 1, name_en: "Job details", name_ar: "تفاصيل الوظيفة" },
  { id: 2, name_en: "Job requisition", name_ar: "إعدادات متطلبات الوظيفة" },
  { id: 3, name_en: "Summary", name_ar: "الموجز" },
];

const Status = [
  { id: true, name_ar: "مفعل", name_en: "Active" },
  { id: false, name_ar: "غير مفعل", name_en: "Inactive" },
];
const integrationsURL = {
  office365: "https://www.office.com/",
}
export {
  ApprovalActions,
  maritlStatusOptions,
  proficiencyLevel,
  interviewsTypes,
  applicationStatus,
  actionsList,
  pipelineActions,
  genderOptions,
  genderOptionsWithAll,
  emailTypeOptions,
  integrationTypes,
  AvaliableFor,
  approvalStage,
  Status,
  integrationsURL,
  ageList,
  yearsOfExperience
};

export const salary_allowance = [
  {
    name: 'housing',
    type: 1
  }, {
    name: "transportation",
    type: 2
  }, {
    name: 'mobileAllowance',
    type: 3
  }
]