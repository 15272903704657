import * as types from "../types/CompanySetup";

export const requestCompantDetails = () => {
    return {
      type: types.GET_COMPANY_DETAILS_REQUEST
    }
};

export const receiveCompanyDetails = (data) => {
    return {
      type: types.GET_COMPANY_DETAILS_RECEIVE,
      payload : data
    }
};
  
export const requestEditCompanyDetails = (data) => {
    return {
      type: types.EDIT_COMPANY_DETAILS_REQUEST,
      payload : data
    }
};

export const receiveEditCompanyDetails = (data) => {
    return {
      type: types.EDIT_COMPANY_DETAILS_RECEIVE,
      payload : data
    }
};

export const requestGetBranches = () => {
    return {
      type: types.GET_BRANCHES_REQUEST
    }
};

export const receiveGetBranches = (data) => {
    return {
      type: types.GET_BRANCHES_RECEIVE,
      payload : data
    }
};

export const requestAddEditBranches = (data , type) => {
    return {
      type: types.ADD_UPDATE_BRANCH_REQUEST,
      payload : {data , type}
    }
};

export const receiveAddEditBranches = (data) => {
    return {
      type: types.ADD_UPDATE_BRANCH_RECEIVE,
      payload : data
    }
};

export const requestDeleteBranch = (data) => {
    return {
      type: types.DELETE_BRANCH_REQUEST,
      payload : data
    }
};

export const receiveDeleteBranch = (data) => {
    return {
      type: types.DELETE_BRANCH_RECEIVE,
      payload : data
    }
};
export const companyPortalSetupRequest = () => {
  return {
    type: types.GET_COMPANY_PORTAL_SETUP_REQUEST
  }
}
export const companyPortalSetupRecieve = () => {
  return {
    type: types.GET_COMPANY_PORTAL_SETUP_RECIEVE
  }
}

export const getCompanyProfileStepsStatusRequest = () => {
  return {
    type: types.GET_COMPANY_PROFILE_STEPS_STATUS_REQUEST
  }
}
export const getCompanyProfileStepsStatusRecieve = (data) => {
  return {
    type: types.GET_COMPANY_PROFILE_STEPS_STATUS_RECIEVE,
    payload : data
  }
}


export const ProfileStepsFeedbackRequest = (data) => {
  return {
    type: types.PROFILE_STEPS_FEEDBACK_REQUEST,
    payload : data
  }
}
export const ProfileStepsFeedbackRecieve = (data) => {
  return {
    type: types.PROFILE_STEPS_FEEDBACK_RECIEVE,
    payload : data
  }
}






