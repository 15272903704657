import * as types from "../types/Integrations";

export const InteggrationList = (data) => {
  return {
    type: types.INTEGRATION_RECEIVE,
    payload: data,
  };
};

export const showSnackbarIntegrationAction = (data) => {
  return {
    type: types.SHOW_SNACKBAR_INTEGRATION,
    payload: data
  }
}
export const hideSnackbarIntegrationAction = () => {
  return {
    type: types.HIDE_SNACKBAR_INTEGRATION,
  }
}
export const requestInteggrationList = (data) => {
  return {
    type: types.INTEGRATION_REQUEST,
    payload: data,
  };
};
export const requestInteggrationStatus = () => {
  return {
    type: types.REQUEST_INTEGRATION_STATUS,
  };
};

export const recieveInteggrationStatus = (payload) => {
  return {
    type: types.RECIEVE_INTEGRATION_STATUS,
    payload
  };
};
export const CompanyUnifonicResponse = (data) => {
  return {
    type: types.UNIFONIC_INTEGRATION_RECEIVE,
    payload: data,
  };
};

export const requestCompanyUnifonic = (data) => {
  return {
    type: types.UNIFONIC_INTEGRATION_REQUEST,
    payload: data,
  };
};

export const requestAddEditCompanyUnifonic = (data) => {
  return {
    type: types.ADD_EDIT_UNIFONIC_INTEGRATION_REQUEST,
    payload: data,
  };
};

export const requestCompanyOffice365 = () => {
  return {
    type: types.OFFICE365_INTEGRATION_REQUEST,
  };
};
export const recieveCompanyOffice365 = (payload) => {
  return {
    type: types.OFFICE365_INTEGRATION_RECEIVE,
    payload,
  };
};
export const requestCompanyOffice365GrantAccess = (payload) => {
  return {
    type: types.OFFICE365_INTEGRATION_GRANT_ACCESS_REQUEST,
    payload,
  };
};
export const recieveCompanyOffice365GrantAccess = (payload) => {
  return {
    type: types.OFFICE365_INTEGRATION_GRANT_ACCESS_RECIEVE,
    payload,
  };
};
export const addEditCompanyOffice365 = (payload) => {
  return {
    type: types.ADD_EDIT_OFFICE365_INTEGRATION_REQUEST,
    payload,
  };
};
export const getUserCalendarRequest = (payload) => {
  return {
    type: types.GET_USER_CALENDAR_REQUEST,
    payload,
  };
};
export const getUserCalendarRecieve = (payload) => {
  return {
    type: types.GET_USER_CALENDAR_RECIEVE,
    payload,
  };
};
