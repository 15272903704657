import { axiosInstance } from "./config";

let handlerEnabled = true;

const wizardSetupRequest = async (data) => {
  return await axiosInstance.post(`/Company/SaveCompanyDataWizard`, data, {
    handlerEnabled,
  });
};
 const requestPhoneNumberValidation= async (data) => {
  return await axiosInstance.post(`Company/validate_phone`, data, {
    handlerEnabled,
  });
};
export default {
  wizardSetupRequest,
  requestPhoneNumberValidation,
};
