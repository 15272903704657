import { call, put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import API from "../../network/apis/payment";
import * as ACTIONS from "../actions/payment";
import * as type from "../types/payment";
import { dispatchError, showSnackbar } from "utils/shared";
import { static_features_plans } from "containers/dashboard/PaymentSetup/SubscriptionPlans/SubscriptionPlansConfig";
import { getCompanyPortalSetup } from "./CompanySetup";
import { companyPortalSetupRequest } from "store/actions";

function* requestSavedCards() {
  try {
    const response = yield call(API.requestSavedCards);
    yield put(ACTIONS.receiveSavedCards(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* requestCheckoutId({ payload }) {
  try {
    const response = yield call(API.requestCheckoutId, payload);
    localStorage.setItem("paymentcheckoutIdData", JSON.stringify(payload));
    yield put(ACTIONS.receiveCheckoutId(response.data.data.id));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* requestCheckoutStatus({ payload }) {
  try {
    const response = yield call(API.requestCheckoutStatus, payload);
    yield put(
      ACTIONS.receiveCheckoutStatus({
        is_success: true,
        message: response.data.data,
      })
    );
    localStorage.setItem(
      "plan",
      payload.SubscriptionPlanId === 2
        ? "basic"
        : payload.SubscriptionPlanId === 3
        ? "advance"
        : "premium"
    );
    localStorage.setItem("is_view_only", false);
    localStorage.removeItem("promoCode");
    yield put(ACTIONS.requestInvoiceData());
  } catch (error) {
    yield put(
      ACTIONS.receiveCheckoutStatus({
        is_success: false,
        message: error.response.data.data || "",
      })
    );
  }
}
function* requestActivateCards({ payload }) {
  try {
    yield call(API.requestActivateCards, payload);
    showSnackbar("done");
    yield put(ACTIONS.requestSavedCards());
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* requestDeleteCards({ payload }) {
  try {
    yield call(API.requestDeleteCards, payload);
    showSnackbar("done");
    yield put(ACTIONS.requestSavedCards());
  } catch (error) {
    console.log(error);
  }
}
function* requestSubscriptionPlans() {
  try {
    const response = yield call(API.requestSubscriptionPlans);
    const subscriptionPlans = yield response.data.data.subscriptionPlans.map(
      (item) => {
        if (item.type === "basic")
          return { ...item, ...static_features_plans["basic"] };
        if (item.type === "advance")
          return { ...item, ...static_features_plans["advance"] };
        else return { ...item, ...static_features_plans["premium"] };
      }
    );
    const revereseArr = [...subscriptionPlans.slice(0, 3).reverse()];
    const result = subscriptionPlans[3]
      ? [...revereseArr, subscriptionPlans[3]]
      : [...revereseArr];

    yield put(
      ACTIONS.receiveSubscriptionPlans({
        ...response.data.data,
        subscriptionPlans: result,
      })
    );
  } catch (error) {
    console.log(error);
    dispatchError(error.response?.data);
  }
}

function* requestCompanyVatNumber() {
  try {
    const response = yield call(API.requestCompanyVatNumber);
    yield put(ACTIONS.receiveCompanyVatNumber(response.data));
  } catch (error) {
    console.log(error);
    dispatchError(error.response.data);
  }
}
function* requestSubscriptionPayment({ payload }) {
  try {
    const response = yield call(API.requestSubscriptionPayment, payload);
    yield put(companyPortalSetupRequest())
    window.location.href = response.data.data;
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* cancelSubscription() {
  try {
    const response = yield call(API.cancelSubscription);
    yield put(ACTIONS.receiveCanelSubscription(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* requestBillingInfo() {
  try {
    const response = yield call(API.requestGetBillingInfo);
    yield put(ACTIONS.receiveBillingInfo(response.data.data));
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* requestPayWithSavedCard({ payload }) {
  try {
    const response = yield call(API.requestPayWithSavedCard, payload);
    yield put(companyPortalSetupRequest())
    yield put(ACTIONS.receivePaymentWitSavedCards(response.data.data));
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* requestPaymentCalculation({ payload }) {
  try {
    const response = yield call(API.requestPaymentCalculation, payload);
    yield put(ACTIONS.receivePaymentCalculation(response.data.data));
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* requestPaymentWithWallet({ payload }) {
  try {
    yield call(API.requestPayFromWallet, payload);
    yield put(ACTIONS.requestSubscriptionPlans());
    yield put(companyPortalSetupRequest())
    localStorage.removeItem("promoCode");
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* requestDowngradeLostItems() {
  try {
    const response = yield call(API.requestDowngradeLostData);
    yield put(ACTIONS.receiveDowngradeLostData(response.data.data));
    localStorage.removeItem("promoCode");
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* requestValidatePromoCode({ payload }) {
  try {
    const response = yield call(API.requestValidatePromoCode, {
      planId: payload.planId,
      promoCode: payload.promoCode,
      paymentType: payload.paymentType,
    });
    localStorage.setItem("promoCode", payload.promoCode);
    yield put(ACTIONS.receiveValidatePromocode(response.data.success));
    yield put(ACTIONS.requestPaymentCalculation(payload));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
    yield put(ACTIONS.receiveValidatePromocode(false));
  }
}

function* requestInvoiceGenerationData() {
  try {
    const response = yield call(API.invoiceGeneration);
    yield put(ACTIONS.receiveInvoiceData(response.data.data));
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* updateVatNumber({ payload }) {
  try {
    var response = yield call(API.updateVatNumber, payload);
    yield put(ACTIONS.receiveInvoiceData(response.data.data));
    if (response.data) {
      yield put(ACTIONS.receiveCompanyVatNumberUpdate(response.data));
    }
  } catch (error) {
    console.log(error.response.data);
  }
}
function* getCompanySubscribtionEndDate() {
  try {
    var response = yield call(API.companySubscribtionEndDate);
    yield put(ACTIONS.getCompanySubscribtionEndDate_Receieve(response?.data));
  } catch (error) {
    console.log(error.response.data);
  }
}
export function* PaymentSagasWatch() {
  yield takeLatest(type.REQUEST_SAVED_CARDS, requestSavedCards);
  yield takeLatest(type.REQUEST_CHECKOUT_ID, requestCheckoutId);
  yield takeLatest(type.REQUEST_CHECKOUT_STATUS, requestCheckoutStatus);
  yield takeLatest(type.ACTIVATE_PAYMENT_CARD_REQUEST, requestActivateCards);
  yield takeLatest(type.DELETE_PAYMENT_CARD_REQUEST, requestDeleteCards);
  yield takeLatest(type.SUBSCRIPTION_PLANS_REQUEST, requestSubscriptionPlans);
  yield takeLatest(type.VAT_NUMBER_REQUEST, requestCompanyVatNumber);
  yield takeLatest(
    type.SUBSCRIBTION_PAYMENT_REQUEST,
    requestSubscriptionPayment
  );
  yield takeLatest(type.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeLatest(type.BILLING_INFO_REQUEST, requestBillingInfo);
  yield takeLatest(
    type.PAYMENT_WITH_SAVED_CARDS_REQUEST,
    requestPayWithSavedCard
  );
  yield takeLatest(type.PAYMENT_CALCULATION_REQUEST, requestPaymentCalculation);
  yield takeLatest(type.PAYMENT_WITH_WALLET_REQUEST, requestPaymentWithWallet);
  yield takeLatest(type.DOWNGRADE_LOST_DATA_REQUEST, requestDowngradeLostItems);
  yield takeLatest(type.VALIDATE_PROMO_CODE_REQUEST, requestValidatePromoCode);
  yield takeLatest(
    type.INVOICE_GENERATEION_REQUEST,
    requestInvoiceGenerationData
  );

  yield takeLatest(type.VAT_NUMBER_UPDATE_REQUEST, updateVatNumber);
  yield takeLatest(
    type.SUBSCRIPTION_END_DATE_REQUEST,
    getCompanySubscribtionEndDate
  );
}
