export const LOOKUP_SALARY_GRADES_REQUEST = 'LOOKUP_SALARY_GRADES_REQUEST';
export const LOOKUP_SALARY_GRADES = 'LOOKUP_SALARY_GRADES';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_RECEIVE = 'GET_COUNTRIES_RECEIVE';

export const LOOKUP_RESIDENCIES_REQUEST = 'LOOKUP_RESIDENCIES_REQUEST';
export const LOOKUP_RESIDENCIES = 'LOOKUP_RESIDENCIES';
export const LOOKUP_NATIONALITIES_REQUEST = 'LOOKUP_NATIONALITIES_REQUEST';
export const LOOKUP_NATIONALITIES = 'LOOKUP_NATIONALITIES';
export const LOOKUP_LANGUAGES_REQUEST = 'LOOKUP_LANGUAGES_REQUEST';
export const LOOKUP_LANGUAGES = 'LOOKUP_LANGUAGES';
export const LOOKUP_CAREER_LEVELS_REQUEST = 'LOOKUP_CAREER_LEVELS_REQUEST';
export const LOOKUP_CAREER_LEVELS = 'LOOKUP_CAREER_LEVELS';
export const LOOKUP_JOB_TITLES_REQUEST = 'LOOKUP_JOB_TITLES_REQUEST';
export const LOOKUP_JOB_TITLES = 'LOOKUP_JOB_TITLES';
export const LOOKUP_COLLEGES_REQUEST = 'LOOKUP_COLLEGES_REQUEST';
export const LOOKUP_COLLEGES = 'LOOKUP_COLLEGES';
export const LOOKUP_DEGREES_REQUEST = 'LOOKUP_DEGREES_REQUEST';
export const LOOKUP_DEGREES = 'LOOKUP_DEGREES';
export const LOOKUP_COMPETENCIES_REQUEST = 'LOOKUP_COMPETENCIES_REQUEST';
export const LOOKUP_COMPETENCIES = 'LOOKUP_COMPETENCIES';

export const GET_ALL_JOB_ROLES_LOOKUP_REQUEST = 'GET_ALL_JOB_ROLES_LOOKUP_REQUEST';
export const GET_ALL_JOB_ROLES_LOOKUP_RECEIVE = 'GET_ALL_JOB_ROLES_LOOKUP_RECEIVE';

export const LOOKUP_SALARY_GRADES_FILTER_REQUEST = 'LOOKUP_SALARY_GRADES_FILTER_REQUEST';
export const LOOKUP_SALARY_GRADES_FILTER_RECEIVE = 'LOOKUP_SALARY_GRADES_FILTER_RECEIVE';

export const LOOKUP_COMPANY_BRANCHES_REQUEST = 'LOOKUP_COMPANY_BRANCHES_REQUEST';
export const LOOKUP_COMPANY_BRANCHES_RECEIVE = 'LOOKUP_COMPANY_BRANCHES_RECEIVE';

export const LOOKUP_CURRENCY_REQUEST = 'LOOKUP_CURRENCY_REQUEST';
export const LOOKUP_CURRENCY_RECEIVE = 'LOOKUP_CURRENCY_RECEIVE';

export const LOOKUP_JOBTITLES_CATEGORIES_REQUEST = 'LOOKUP_JOBTITLES_CATEGORIES_REQUEST'
export const LOOKUP_JOBTITLES_CATEGORIES_RECEIVE = 'LOOKUP_JOBTITLES_CATEGORIES_RECEIVE'

//AGENTS
export const LOOKUP_AGENTS_REQUEST = 'LOOKUP_AGENTS_REQUEST'
export const LOOKUP_AGENTS_RECEIVE = 'LOOKUP_AGENTS_RECEIVE'

export const LOOKUP_COMPANY_FIELDS_REQUEST = 'LOOKUP_COMPANY_FIELDS_REQUEST'
export const LOOKUP_COMPANY_FIELDS_RECEIVE = 'LOOKUP_COMPANY_FIELDS_RECEIVE'

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_RECEIVE = 'GET_CITIES_RECEIVE';
export const GET_ALL_PERMISSIONS_REQUEST = 'GET_ALL_PERMISSIONS_REQUEST';
export const GET_ALL_PERMISSIONS_RECEIVE = 'GET_ALL_PERMISSIONS_RECEIVE';

export const LOOKUP_GET_JOB_OPENINGS_REQUEST = 'LOOKUP_GET_JOB_OPENINGS_REQUEST'
export const LOOKUP_GET_JOB_OPENINGS_RECEIVE = 'LOOKUP_GET_JOB_OPENINGS_RECEIVE'

export const LOOKUP_GET_PIPELINE_FLOWS_REQUEST = 'LOOKUP_GET_PIPELINE_FLOWS_REQUEST'
export const LOOKUP_GET_PIPELINE_FLOWS_RECEIVE = 'LOOKUP_GET_PIPELINE_FLOWS_RECEIVE'

export const LOOKUP_GET_REJECTION_REASONS_REQUEST = 'LOOKUP_GET_REJECTION_REASONS_REQUEST'
export const LOOKUP_GET_REJECTION_REASONS_RECEIVE = 'LOOKUP_GET_REJECTION_REASONS_RECEIVE'

export const LOOKUP_COMPANY_SIZES_REQUEST = 'LOOKUP_COMPANY_SIZES_REQUEST'
export const LOOKUP_COMPANY_SIZES_RECEIVE = 'LOOKUP_COMPANY_SIZES_RECEIVE'

export const LOOKUP_COMPANY_TYPES_REQUEST = 'LOOKUP_COMPANY_TYPES_REQUEST'
export const LOOKUP_COMPANY_TYPES_RECEIVE = 'LOOKUP_COMPANY_TYPES_RECEIVE'
export const GET_COMP_PLAN = "GET_COMP_PLAN";
export const RECIEVE_COMP_PLAN = "RECIEVE_COMP_PLAN";

export const LOOKUP_DEPARTMENTS_REQUEST = 'LOOKUP_DEPARTMENTS_REQUEST'
export const LOOKUP_DEPARTMENTS_RECEIVE = 'LOOKUP_DEPARTMENTS_RECEIVE'

// JOB OPENINGS FILTERS 
 export const JOB_TITLE_FILTER_LOOKUP_REQUEST = 'JOB_TITLE_FILTER_LOOKUP_REQUEST'
 export const JOB_TITLE_FILTER_LOOKUP_RECEIVE = 'JOB_TITLE_FILTER_LOOKUP_RECEIVE'

 export const BRANCH_FILTER_LOOKUP_REQUEST = 'BRANCH_FILTER_LOOKUP_REQUEST'
 export const BRANCH_FILTER_LOOKUP_RECEIVE = 'BRANCH_FILTER_LOOKUP_RECEIVE'

 export const JOB_STATUS_FILTER_LOOKUP_REQUEST = 'JOB_STATUS_FILTER_LOOKUP_REQUEST'
 export const JOB_STATUS_FILTER_LOOKUP_RECEIVE = 'JOB_STATUS_FILTER_LOOKUP_RECEIVE'

