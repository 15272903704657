import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "components/NameAvatar/NameAvatar";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Auth from "../../utils/Auth";
import { requestGetAgentProfile } from "../../store/actions/agents";
import './UserDropdownMenu.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function DropDownMenu(props) {
  const classes = useStyles();
  let userNameLocal =
    localStorage.getItem("first_name") +
    " " +
    localStorage.getItem("last_name");
  const userProfile = localStorage.getItem("image_url");
  const userData = useSelector((state) => state.userProfile);
  let userName = userData.first_name
    ? userData.first_name + " " + userData.last_name
    : userNameLocal;
  let userImage = userData.image_url ? userData.image_url : userProfile;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { messages } = props.intl;
  const lang = useSelector((state) => state.locale.lang);
  const ListArr = [
    {
      id: 1,
      name: messages.agents.agentProfile,
      link: "/profile",
    },
    {
      id: 2,
      name: messages.shared.changePassword,
      link: "/changePassword",
    },
    {
      id: 3,
      name: messages.shared.logout,
      link: "/redirect-login",
    },
  ];
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, linkId) => {
    linkId === 3 && Auth.logout();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
    userName = userData.first_name
      ? userData.first_name + " " + userData.last_name
      : localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name");
    userImage = userData.image_url ? userData.image_url : userProfile;
  }, [open]);
  const is_a_ghost_user = JSON.parse(localStorage.getItem("is_ghost"));

  const renderDropDownList = (listArr) => {
    return listArr.map((item, index) => {
      return (
        <Link to={item.link} className={is_a_ghost_user && index === 1 ? "disable-password-change": ""}  key={item.name}>
          <MenuItem
            className={
              lang === "ar"
                ? "text-right medium_font text-dark"
                : "text-left medium_font text-dark"
            }
            dir={lang === "ar" ? "rtl" : "ltr"}
            onClick={(event) => handleClose(event, item.id)}
          >
            {item.name}
          </MenuItem>
        </Link>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div style={{ zIndex: 999 }}>
        <Button
          className="text-white"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar
            isWhiteColor={true}
            name={userName}
            image={userImage == "null" ? null : userImage}
          />
          <KeyboardArrowDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {renderDropDownList(ListArr)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

const DropDownMenuComponent = injectIntl(DropDownMenu);

export default DropDownMenuComponent;
