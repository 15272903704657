import * as types from "../types/ContractTemplate";

export const getContractTemplateSettingsRequest = () => ({
  type: types.GET_CONTRACT_TEMPLATE_SETTINGS_REQUEST,
});
export const getContractTemplateSettingsReceive = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_SETTINGS_RECEIVE,
  payload,
});

export const getContractTemplateDefaultSettingsRequest = () => ({
  type: types.GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_REQUEST,
});
export const getContractTemplateDefaultSettingsReceive = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_RECEIVE,
  payload,
});

export const getContractTemplatePlaceholdersRequest = () => ({
  type: types.GET_CONTRACT_TEMPLATE_PLACEHOLDERS_REQUEST,
});
export const getContractTemplatePlaceholdersReceive = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_PLACEHOLDERS_RECEIVE,
  payload,
});

export const saveContractTemplateSettingsRequest = (payload) => ({
  type: types.SAVE_CONTRACT_TEMPLATE_SETTINGS_REQUEST,
  payload,
});

export const deleteContractTemplateCoverLetterRequest = (payload) => ({
  type: types.DELETE_CONTRACT_TEMPLATE_COVER_LETTER_REQUEST,
  payload,
});