import { axiosInstance } from "./config";

let handlerEnabled = true;

const getEmailTemplatesRequest = async (data) => {
  return await axiosInstance.get(
    `/admin/CustomizedEmailTemplates/getlist?page_size=${data.page_size}&page_number=${data.pageNumber}`,
    { handlerEnabled }
  );
};
const addEmailTemplateRequest = async (data) => {
  return await axiosInstance.post(
    `/admin/CustomizedEmailTemplates/add_customizedEmailTemplate`,
    data,
    { handlerEnabled }
  );
};
const getEmailTemplateRequest = async ({ template_id }) => {
  return await axiosInstance.get(
    `/admin/CustomizedEmailTemplates/get_editTemplate/${template_id}`,
    { handlerEnabled }
  );
};
const editEmailTemplateRequest = async ({ data }) => {
  return await axiosInstance.post(
    `/admin/CustomizedEmailTemplates/edit_customizedEmailTemplate`,
    data,
    { handlerEnabled }
  );
};
const deleteEmailTemplateRequest = async (id) => {
  return await axiosInstance.delete(
    `/admin/CustomizedEmailTemplates/delete_emailTemplate/${id}`,
    { handlerEnabled }
  );
};
const CustomizedEmailTemplatesPlaceholdersRequest = async () => {
  return await axiosInstance.get(
    `/admin/CustomizedEmailTemplates/placeholders`,
    { handlerEnabled }
  );
};
const getCustomizeEmailTemplateList = async () => {
  return await axiosInstance.get(
    `/admin/CustomizedEmailTemplates/GetCustomizeEmailTemplateList`,
    { handlerEnabled }
  );
};
const getCustomizeEmailTemplateRequest = async (data) => {
  return await axiosInstance.get(
    `/admin/candidates/GetCustomizeEmailTemplate?application_id=${data.application_id}&templateId=${data.templateId}`,
    { handlerEnabled }
  );
};

const backToDefaultRequest = async (type) => {
  return await axiosInstance.get(
    `/admin/CustomizedEmailTemplates/default/${type}`,
    { handlerEnabled }
  );
};
export default {
  getEmailTemplatesRequest,
  addEmailTemplateRequest,
  editEmailTemplateRequest,
  getEmailTemplateRequest,
  deleteEmailTemplateRequest,
  getCustomizeEmailTemplateRequest,
  CustomizedEmailTemplatesPlaceholdersRequest,
  getCustomizeEmailTemplateList,
  backToDefaultRequest
};
