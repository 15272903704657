import * as type from "../types/lookups";

export const requestCountries = () => {
  return {
    type: type.GET_COUNTRIES_REQUEST,
  };
};

export const receiveCountries = (data) => {
  return {
    type: type.GET_COUNTRIES_RECEIVE,
    payload: data,
  };
};

export const requestCities = (data) => {
  return {
    type: type.GET_CITIES_REQUEST,
    payload: data,
  };
};

export const receiveCities = (data) => {
  return {
    type: type.GET_CITIES_RECEIVE,
    payload: data,
  };
};

export const requestAllPermissions = () => {
  return {
    type: type.GET_ALL_PERMISSIONS_REQUEST,
  };
};

export const receiveAllPermissions = (data) => {
  return {
    type: type.GET_ALL_PERMISSIONS_RECEIVE,
    payload: data,
  };
};

export const requestAllJobRolesLookup = () => {
  return {
    type: type.GET_ALL_JOB_ROLES_LOOKUP_REQUEST,
  };
};

export const receiveAllJobRolesLookup = (data) => {
  return {
    type: type.GET_ALL_JOB_ROLES_LOOKUP_RECEIVE,
    payload: data,
  };
};

export const requestSalaryGradesLookup = () => ({
  type: type.LOOKUP_SALARY_GRADES_REQUEST,
});

export const receiveSalaryGradesLookup = (payload) => ({
  type: type.LOOKUP_SALARY_GRADES,
  payload,
});

export const requestResidenciesLookup = () => ({
  type: type.LOOKUP_RESIDENCIES_REQUEST,
});
export const receiveResidenciesLookup = (payload) => ({
  type: type.LOOKUP_RESIDENCIES,
  payload,
});
export const requestNationalitiesLookup = () => ({
  type: type.LOOKUP_NATIONALITIES_REQUEST,
});
export const receiveNationalitiesLookup = (payload) => ({
  type: type.LOOKUP_NATIONALITIES,
  payload,
});
export const requestLanguagesLookup = () => ({
  type: type.LOOKUP_LANGUAGES_REQUEST,
});
export const receiveLanguagesLookup = (payload) => ({
  type: type.LOOKUP_LANGUAGES,
  payload,
});
export const requestCareerLevelsLookup = () => ({
  type: type.LOOKUP_CAREER_LEVELS_REQUEST,
});
export const receiveCareerLevelsLookup = (payload) => ({
  type: type.LOOKUP_CAREER_LEVELS,
  payload,
});
export const requestJobTitlesLookup = () => ({
  type: type.LOOKUP_JOB_TITLES_REQUEST,
});
export const receiveJobTitlesLookup = (payload) => ({
  type: type.LOOKUP_JOB_TITLES,
  payload,
});
export const requestCollegesLookup = () => ({
  type: type.LOOKUP_COLLEGES_REQUEST,
});
export const receiveCollegesLookup = (payload) => ({
  type: type.LOOKUP_COLLEGES,
  payload,
});
export const requestDegreesLookup = () => ({
  type: type.LOOKUP_DEGREES_REQUEST,
});
export const receiveDegreesLookup = (payload) => ({
  type: type.LOOKUP_DEGREES,
  payload,
});
export const requestCompetenciesLookup = () => ({
  type: type.LOOKUP_COMPETENCIES_REQUEST,
});
export const receiveCompetenciesLookup = (payload) => ({
  type: type.LOOKUP_COMPETENCIES,
  payload,
});

export const requestSalaryGradeFilterLookup = (data) => ({
  type: type.LOOKUP_SALARY_GRADES_FILTER_REQUEST,
  payload: data,
});

export const receiveSalaryGradeFilterLookup = (data) => ({
  type: type.LOOKUP_SALARY_GRADES_FILTER_RECEIVE,
  payload: data,
});

export const requestCompanyBranches = () => ({
  type: type.LOOKUP_COMPANY_BRANCHES_REQUEST,
});

export const receiveCompanyBranches = (data) => ({
  type: type.LOOKUP_COMPANY_BRANCHES_RECEIVE,
  payload: data,
});

export const requestCurrency = () => ({
  type: type.LOOKUP_CURRENCY_REQUEST,
});

export const receiveCurrency = (data) => ({
  type: type.LOOKUP_CURRENCY_RECEIVE,
  payload: data,
});

export const requestJobTitlesCategories = () => ({
  type: type.LOOKUP_JOBTITLES_CATEGORIES_REQUEST,
});
export const receiveJobTitlesCategories = (payload) => ({
  type: type.LOOKUP_JOBTITLES_CATEGORIES_RECEIVE,
  payload,
});

export const agentsLookupsRequest = () => ({
  type: type.LOOKUP_AGENTS_REQUEST,
});
export const agentsLookupsRecieve = (payload) => ({
  type: type.LOOKUP_AGENTS_RECEIVE,
  payload,
});

export const companyFieldsLookupsRequest = () => ({
  type: type.LOOKUP_COMPANY_FIELDS_REQUEST,
});
export const companyFieldsLookupsRecieve = (payload) => ({
  type: type.LOOKUP_COMPANY_FIELDS_RECEIVE,
  payload,
});

export const jobOpeningsLookupRequest = () => ({
  type: type.LOOKUP_GET_JOB_OPENINGS_REQUEST,
});
export const jobOpeningsLookupReceive = (payload) => ({
  type: type.LOOKUP_GET_JOB_OPENINGS_RECEIVE,
  payload,
});

export const pipelineFlowsLookupRequest = () => ({
  type: type.LOOKUP_GET_PIPELINE_FLOWS_REQUEST,
});
export const pipelineFlowsLookupReceive = (payload) => ({
  type: type.LOOKUP_GET_PIPELINE_FLOWS_RECEIVE,
  payload,
});

export const rejectionReasonsRequest = () => ({
  type: type.LOOKUP_GET_REJECTION_REASONS_REQUEST,
});
export const rejectionReasonsReceive = (payload) => ({
  type: type.LOOKUP_GET_REJECTION_REASONS_RECEIVE,
  payload,
});

export const companysizesRequest = () => ({
  type: type.LOOKUP_COMPANY_SIZES_REQUEST,
});
export const companysizesReceive = (payload) => ({
  type: type.LOOKUP_COMPANY_SIZES_RECEIVE,
  payload,
});

export const companyTypesRequest = () => ({
  type: type.LOOKUP_COMPANY_TYPES_REQUEST,
});
export const companyTypesReceive = (payload) => ({
  type: type.LOOKUP_COMPANY_TYPES_RECEIVE,
  payload,
});
export const requestCompanPlan = (payload) => ({
  type: type.GET_COMP_PLAN,
  payload,
});
export const receiveCompanyPlan = (payload) => ({
  type: type.RECIEVE_COMP_PLAN,
  payload,
});

// JOB OPENINGS FILTERS ACTIONS
export const jobTitlesFiltersLookupRequest = () => ({
  type: type.JOB_TITLE_FILTER_LOOKUP_REQUEST,
});
export const jobTitlesFiltersLookupReceive = (payload) => ({
  type: type.JOB_TITLE_FILTER_LOOKUP_RECEIVE,
  payload,
});

export const jobStatusFiltersLookupRequest = () => ({
  type: type.JOB_STATUS_FILTER_LOOKUP_REQUEST,
});
export const jobStatusFiltersLookupReceive = (payload) => ({
  type: type.JOB_STATUS_FILTER_LOOKUP_RECEIVE,
  payload,
});

export const branchesFiltersLookupRequest = () => ({
  type: type.BRANCH_FILTER_LOOKUP_REQUEST,
});
export const branchesFiltersLookupReceive = (payload) => ({
  type: type.BRANCH_FILTER_LOOKUP_RECEIVE,
  payload,
});

export const departmentsLookupRequest = () => ({
  type: type.LOOKUP_DEPARTMENTS_REQUEST,
});
export const departmentsLookupReceive = (payload) => ({
  type: type.LOOKUP_DEPARTMENTS_RECEIVE,
  payload,
});
