import * as types from "../types/invoices";

export const requestInvoices = (data) => {
    return {
      type: types.FETCH_INVOICES_REQUEST,
      payload: data
    }
};

export const recieveInvoices = (data) => {
    return {
      type: types.FETCH_INVOICES_RECIEVE,
      payload: data
    }
};

export const requestInvoiceDetails = (data) => {
  return {
    type: types.FETCH_INVOICE_DETAILS_REQUEST,
    payload: data
  }
};

export const handleModalState = (state) => {
  return {
    type: types.HANDLE_MODAL_STATE,
    payload: state
  }
}





