import { call, put } from "redux-saga/effects";
import API from "../../network/apis/dashboard";
import * as ACTION from "../actions/dashboard";
import { takeLatest } from "redux-saga/effects";
import * as TYPE from "../types/dashboard";
import { dispatchError } from "utils/shared";
import { downloadContractPDFFileRequest } from "../../network/apis/manageCandidates";
import FileSaver from "file-saver";
export function* dashboardDataRequest({ payload }) {
  try {
    const response = yield call(API.dashboardSettingsData, payload);
    yield put(ACTION.dsahboardCardsReceive(response.data.data));
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}
export function* reportDataRequest() {
  try {
    const response = yield call(API.reportRequest);
    yield put(ACTION.reportDataRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}



export function* exportExcelRequest() {
  try {
    const response = yield call(API.exportExcelRequest);
    yield put(ACTION.exportExcelRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Reports.xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsRequest() {
  try {
    const response = yield call(API.exportCvsRequest);
    yield put(ACTION.exportCvsRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Reports.csv");

  } catch (error) {
    console.log(error);
  }
}
export function* verifyEmailRequest({ payload }) {
  try {
    yield call(API.requestVerifyEmail, payload);
    yield put(ACTION.verifyEmailRecieve({
      agentRequestedConfirmEmail: true
    }));
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}
export function* downloadPDFFile({ payload }) {
  const getDownloadAPI = {
    contract: downloadContractPDFFileRequest,
    offer: API.downloadPDFFileRequest
  }
  try {
    const downloadAPI = getDownloadAPI[payload.downloadPDFFrom];
    const response = yield call(downloadAPI, payload);
    /* Download File */
    let BLOB = new Blob([response.data], { type: "application/pdf" });
    let url = window.URL.createObjectURL(BLOB);
    let link = document.createElement("a");
    link.style.visibility = "hidden";
    link.href = url;
    link.setAttribute("download", payload.fileName);
    document.body.appendChild(link);
    link.click();
    /* Download File */
  } catch (error) {
    console.log(error);
  }
}
export function* dashboardSagasWatch() {
  yield takeLatest(TYPE.DASHBOARD_CARDS_DATA_REQUEST, dashboardDataRequest);
  yield takeLatest(TYPE.REPORT_DATA_REQUEST, reportDataRequest);
  yield takeLatest(TYPE.DOWNLOAD_PDF_FILE, downloadPDFFile);
  yield takeLatest(TYPE.VERIFY_EMAIL_REQUEST, verifyEmailRequest);
  yield takeLatest(TYPE.EXPORT_CVS_REQUEST, exportCvsRequest);
  yield takeLatest(TYPE.EXPORT_EXCEL_REQUEST, exportExcelRequest);

}
