import * as types from "../types/dashboard";

export const dsahboardCardsRequest = payload => ({
  type: types.DASHBOARD_CARDS_DATA_REQUEST,
  payload
});

export const dsahboardCardsReceive = payload => ({
  type: types.DASHBOARD_CARDS_DATA_RECEIVE,
  payload
});

export const reportDataRequest = payload => ({
  type: types.REPORT_DATA_REQUEST,
  payload
});

export const reportDataRecieve = payload => ({
  type: types.REPORT_DATA_RECIEVE,
  payload
});


export const exportExcelRequest = payload => ({
  type: types.EXPORT_EXCEL_REQUEST,
  payload
});

export const exportExcelRecieve = payload => ({
  type: types.EXPORT_EXCEL_RECIEVE,
  payload
});

export const exportCvsRequest = payload => ({
  type: types.EXPORT_CVS_REQUEST,
  payload
});

export const exportCvsRecieve = payload => ({
  type: types.EXPORT_CVS_RECIEVE,
  payload
});
export const downloadPDFFile = (data) => {
  return {
    type: types.DOWNLOAD_PDF_FILE,
    payload: data,
  };
};
export const verifyEmailRequest = (data) => {
  return {
    type: types.VERIFY_EMAIL_REQUEST,
    payload: data,
  };
};
export const verifyEmailRecieve = (data) => {
  return {
    type: types.VERIFY_EMAIL_RECIEVE,
    payload: data,
  };
};