import { call, put } from "redux-saga/effects";
import API from "../../network/apis/advertisementSetupAPI";
import * as ACTION from "../actions/SocialMediaSetup";
import history from "../../routes/History";
import store from "../../store";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/advertisementSetup";

export function* getSocialMediaPlaceholder() {
  try {
    const response = yield call(API.getSocialMediaPlaceholder);
    yield put(ACTION.receiveGetSociaMediaPlaceholder(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getSocialMediaSettings() {
  try {
    const response = yield call(API.getSocialMediaSettings);
    yield put(ACTION.receiveGetSociaMediaSettings(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* addEditSocialMediaSettings(action) {
  try {
    const response = yield call(API.addEditSocialMediaSettings, action.payload);
    yield put(ACTION.receiveAddEditSociaMediaSettings(response.data));
    showSnackbar("savedSuccessfuly");
    history.push("/setup");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* SocialMediaSetupSagasWatch() {
  yield takeLatest(
    type.SOCIAL_MEDIA_PLACEHOLDER_REQUEST,
    getSocialMediaPlaceholder
  );
  yield takeLatest(
    type.GET_SOCIAL_MEDIA_SETTINGS_REQUEST,
    getSocialMediaSettings
  );
  yield takeLatest(
    type.ADD_EDIT_SOCIAL_MEDIA_REQUEST,
    addEditSocialMediaSettings
  );
}
