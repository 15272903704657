export const INTEGRATION_REQUEST = "INTEGRATION_REQUEST";
export const INTEGRATION_RECEIVE = "INTEGRATION_RECEIVE";

export const UNIFONIC_INTEGRATION_REQUEST = "UNIFONIC_INTEGRATION_REQUEST";
export const UNIFONIC_INTEGRATION_RECEIVE = "UNIFONIC_INTEGRATION_RECEIVE";

export const ADD_EDIT_UNIFONIC_INTEGRATION_REQUEST =
  "ADD_EDIT_UNIFONIC_INTEGRATION_REQUEST";

export const OFFICE365_INTEGRATION_REQUEST = "OFFICE365_INTEGRATION_REQUEST";
export const OFFICE365_INTEGRATION_RECEIVE = "OFFICE365_INTEGRATION_RECEIVE";

export const ADD_EDIT_OFFICE365_INTEGRATION_REQUEST =
  "ADD_EDIT_OFFICE365_INTEGRATION_REQUEST";

export const OFFICE365_INTEGRATION_GRANT_ACCESS_REQUEST =
  "OFFICE365_INTEGRATION_GRANT_ACCESS_REQUEST";
export const OFFICE365_INTEGRATION_GRANT_ACCESS_RECIEVE =
  "OFFICE365_INTEGRATION_GRANT_ACCESS_RECIEVE";

export const GET_USER_CALENDAR_REQUEST = "GET_USER_CALENDAR_REQUEST";
export const GET_USER_CALENDAR_RECIEVE = "GET_USER_CALENDAR_RECIEVE";
export const SHOW_SNACKBAR_INTEGRATION = "SHOW_SNACKBAR_INTEGRATION";
export const HIDE_SNACKBAR_INTEGRATION = "HIDE_SNACKBAR_INTEGRATION";
export const REQUEST_INTEGRATION_STATUS = "REQUEST_INTEGRATION_STATUS";
export const RECIEVE_INTEGRATION_STATUS = "RECIEVE_INTEGRATION_STATUS";