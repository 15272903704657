import React from "react";
import HRCOMLogo from "assets/images/ic_logo-hr.png";
import "./NameAvatar.scss";

const Avatar = ({showHRCOMLogo, isWhiteColor, name, image, classes }) => {
  const getInitials = function (name) {
    let initials = "";
    name.split(" ").map((name) => {
        name.length > 0 && name !== "" && (initials += name[0]);
    });
    return initials;
  };
  const showNameAvatar = (name) => {
    return (
      <div className="d-flex align-items-center">
        {image ? (
          <div
            className="avatarImage d-flex justify-content-center align-items-center"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ) : (
          <div className="avatarName bold_font d-flex justify-content-center align-items-center">
            {getInitials(name)}
          </div>
        )}
        <p
          className={
            isWhiteColor
              ? `mb-0 ${classes ? classes: "mx-3"} fullName text-white`
              : `mb-0 ${classes ? classes: "mx-3"} fullName text-dark`
          }
        >
          {name}
        </p>
        {showHRCOMLogo && <img src={HRCOMLogo} className="hrcom-logo" alt="hrcom-logo"/>}
      </div>
    );
  };
  return (
    <React.Fragment>{(name && name.length > 0) && showNameAvatar(name)}</React.Fragment>
  );
};

export default Avatar;
