import { axiosInstance } from "./config";

const getOfferTemplateSettings = async () =>
  await axiosInstance.get(`/admin/offer_template_settings`);

const getOfferTemplateDefaultSettings = async () =>
  await axiosInstance.get(`/admin/offer_template_settings/default`);

const getOfferTemplatePlaceholders = async () =>
  await axiosInstance.get(`/admin/offer_template_settings/placeholders`);

const saveOfferTemplateSettings = async (data) =>
  await axiosInstance.post(`/admin/offer_template_settings`, data);

const deteleOfferTemplateCoverLetter = async () =>
  await axiosInstance.delete(`/admin/offer_template_settings/cover_letter`);

const get_offerFileBytes = async ({ offer_id }) =>
  await axiosInstance.get(
    `/admin/candidates/get_offerFileBytes/${offer_id}`
  );

export default {
  getOfferTemplateSettings,
  getOfferTemplateDefaultSettings,
  getOfferTemplatePlaceholders,
  saveOfferTemplateSettings,
  deteleOfferTemplateCoverLetter,
  get_offerFileBytes,
};
