export const SET_CURRENT_LANG = "SET_CURRENT_LANG";
export const GET_CURRENT_LANG = "GET_CURRENT_LANG";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVE = "LOGIN_RECEIVE";

export const VALIDATE_DOMAIN_REQUEST = "VALIDATE_DOMAIN_REQUEST";
export const VALIDATE_DOMAIN_RECEIVE = "VALIDATE_DOMAIN_RECEIVE ";
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_RECEIVE = "FORGET_PASSWORD_RECEIVE ";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVE = "RESET_PASSWORD_RECEIVE ";

export const LOGED_USER_PERMISSIONS = "LOGED_USER_PERMISSIONS";
export const CLICKED_PAGE_INDEX = "CLICKED_PAGE_INDEX";

export const SET_STEPPER = "SET_STEPPER";
export const GET_STEPPER = "GET_STEPPER";
export const SET_SIDE_MENU = "SET_SIDE_MENU";
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST = "SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST";
export const SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST = "SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST";
export const SOCIAL_LOGIN_POPUP_OPENED_REQUEST = "SOCIAL_LOGIN_POPUP_OPENED_REQUEST";
export const SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED = "SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED";

export const SET_START_YOUR_JOURNY_MENU="SET_START_YOUR_JOURNY_MENU"
