import * as types from "../types/viewAllRequests";

const INITIAL_STATE = {
  approvalsList: [],
  companyMinutes: {},
  jobsLimit: null,
  duplicatedJobPost : null,
  allRequestsJobOpeningFirstView: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.REQUESTS_CLOSED_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_RECIEVE:
      return {
        ...state,
        allRequestsJobOpeningFirstView: action.payload === 0 ? true: false,
      };
    case types.RECEIVE_ALL_JOB_OPENINGS:
      return {
        ...state,
        ...action.payload,
      };
    case types.DELAY_CLOSING_DATE_RECIEVE:
      return {
        ...state,
        closingDate: action.payload,
      };
    case types.APPROVAL_SETUP_STATUS_FLOW_RECEIVE:
      return {
        ...state,
        approvalsList: [...action.payload],
      };
    case types.COMPANY_INTERVIEW_MINUTES_RECEIVE:
      return {
        ...state,
        companyMinutes: { ...action.payload },
      };
    case types.CHECK_JOB_REQUESTS_PLAN_LIMIT_RECEIVE:
      return {
        ...state,
        jobsLimit: action.payload,
      };
    case types.GET_HOLDED_JOBS_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.DUPLICATE_JOB_POST_RECEIVE:
      return {
        ...state,
        duplicatedJobPost : action.payload,
      };
    default:
      return state;
  }
};
