import * as types from "../types/rejectionReasons";

const INITIAL_STATE = {
  isReasonDeleted: false,
  paging: {},
  data: [],
  currentReason: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_REJECTION_REASON_RECIEVE:
      return { ...state, agent: action.payload };
    case types.GET_REJECTION_REASONS_RECIEVE:
      return {
        ...state,
        data: action.payload?.data,
        paging: action.payload?.paging,
      };
    case types.GET_REJECTION_REASON_RECIEVE:
      return { ...state, currentReason: action.payload };
    case types.DELETE_REJECTION_REASON_RECIEVE:
      return { ...state, isReasonDeleted: action.payload };
    default:
      return state;
  }
};
