import { call, put, takeLatest } from "redux-saga/effects";
import history from "../../routes/History";
import { dispatchError, showSnackbar } from "../../utils/shared";
import API from "../../network/apis/pipelineSetup";
import * as actions from "../actions/pipelineSetup";
import * as types from "../types/pipelineSetup";
import { pipelineFlowsLookupRequest } from "../actions/lookups";

export function* getFlowsGrid({ payload }) {
  try {
    const response = yield call(API.getflows, payload);
    yield put(actions.getFlowsReceive(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* saveNewFlow({ payload }) {
  try {
    yield call(API.saveNewFlow, payload);
    showSnackbar("savedSuccessfuly");
    history.push("/pipelineFlows");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestEditFlowDetails({ payload }) {
  try {
    const response = yield call(API.requestEditFlowDetails, payload);
    yield put(actions.receiveEditFlowDetails(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* saveEditedFlow({ payload }) {
  try {
    yield call(API.saveEditedFlow, payload);
    !payload.is_order && showSnackbar("savedSuccessfuly");
    !payload.is_order && history.push("/pipelineFlows");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* saveSingleFlowStage({ payload }) {
  try {
    yield call(API.saveSingleFlowStage, payload);
    yield put(actions.requestEditFlowDetails(payload.flow_id));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* deleteStage({ payload }) {
  try {
    yield call(API.deleteStage, payload.stage_id);
    yield put(actions.receiveDeleteStage(false));
    yield put(actions.requestEditFlowDetails(payload.flow_id));
  } catch (error) {
    yield put(actions.receiveDeleteStage(true));
  }
}
export function* moveApplicantsToStage({ payload }) {
  try {
    yield call(API.moveApplicantsToStage, payload);
    yield put(actions.receiveDeleteStage(false));
    yield put(actions.requestEditFlowDetails(payload.flow_id));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* deleteFlow({ payload }) {
  try {
    yield call(API.deleteFlow, payload);
    showSnackbar("done");
    yield put(actions.getFLowsRequest(history.location.search.split("=")[1]));
    yield put(actions.disablePipelineRequest());
    yield put(pipelineFlowsLookupRequest());
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* setDefaultFlowRequest({ payload }) {
  try {
    yield call(API.setDefaultFlow, payload);
    yield put(actions.getFLowsRequest(history.location.search.split("=")[1]));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* disableSendOffer() {
  try {
    const response = yield call(API.disableSendOffer);
    yield put(actions.disableSendOfferReceive(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* disablePipeline() {
  try {
    const response = yield call(API.disablePipeline);
    yield put(actions.disablePipelineReceive(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* adsSettings() {
  try {
    const response = yield call(API.adsSettings);
    yield put(actions.adsSettingsResponse(response.data.data));
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}

export function* PipelineSetupSagasWatch() {
  yield takeLatest(types.GET_FLOWS_GRID_REQUEST, getFlowsGrid);
  yield takeLatest(types.GET_FLOWS_GRID_REQUEST, getFlowsGrid);
  yield takeLatest(types.SAVE_NEW_FLOW_REQUEST, saveNewFlow);
  yield takeLatest(types.EDIT_FLOW_DETAILS_REQUEST, requestEditFlowDetails);
  yield takeLatest(types.SAVE_EDITED_FLOW_REQUEST, saveEditedFlow);
  yield takeLatest(types.DELETE_STAGE_REQUEST, deleteStage);
  yield takeLatest(
    types.MOVE_APPLICANTS_TO_STAGE_REQUEST,
    moveApplicantsToStage
  );
  yield takeLatest(types.SET_DEFAULT_FLOW_REQUEST, setDefaultFlowRequest);
  yield takeLatest(types.DELETE_FLOW_REQUEST, deleteFlow);
  yield takeLatest(types.SAVE_SINGLE_FLOW_STAGE_REQUEST, saveSingleFlowStage);
  yield takeLatest(types.DISABLE_SEND_OFFER_REQUEST, disableSendOffer);
  yield takeLatest(types.DISABLE_PIPELINE_REQUEST, disablePipeline);
  yield takeLatest(types.ADS_SETTINGS_REQUEST, adsSettings)
}
