import { call, put } from "redux-saga/effects";
import API from "../../network/apis/uploadExcel";
import * as ACTION from "../actions/uploadExcel";
import { requestGetAllAgents } from "../actions/agents";
import {
  requestPipelineStages,
  requestGetPipelineApplications,
} from "../actions/manageCandidates";
import { requestGetSalaryGrade } from "../actions/salaryGrade";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { showSnackbarAction } from "../actions/snackbar";
import store from "../../store";
import History from "./../../routes/History";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/uploadExcel";
import ReactGA from "react-ga";

export function* requestAgentsExcelSample() {
  try {
    const response = yield call(API.requestAgentsExcelSample);
    yield put(ACTION.receiveAgentsExcelSample(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* requestUploadAgentsExcel({ payload }) {
  try {
    const response = yield call(API.requestUploadAgentsExcel, payload);
    yield put(requestGetAllAgents(1));
    if (response.data.data) {
      yield put(showSnackbarAction(response.data.data, "error"));
    } else {
      showSnackbar("savedSuccessfuly");
    }
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestSalaryExcelSample() {
  try {
    const response = yield call(API.requestSalaryExcelSample);
    yield put(ACTION.receiveSalaryExcelSample(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* requestUploadSalaryExcel({ payload }) {
  try {
    const response = yield call(API.requestUploadSalaryExcel, payload);
    yield put(requestGetSalaryGrade(1));
    yield put(ACTION.salaryExcelFileUploaded(true));
    if (response.data.data.length > 0) {
      yield put(showSnackbarAction(response.data.data, "error"));
    } else {
      showSnackbar("savedSuccessfuly");
    }
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* UploadApplicantsExcel({ payload }) {
  try {
    const response = yield call(API.UploadApplicantsExcel, payload);
    const stage_id = localStorage.getItem("active_stage_id");
    yield put(requestPipelineStages(History.location.pathname.split("/")[2]));
    yield put(
      requestGetPipelineApplications({
        job_request_id: History.location.pathname.split("/")[2],
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Add applicants`,
      action: "User requested add applicants ",
    });
    if (response.data.data) {
      yield put(showSnackbarAction(response.data.data, "error"));
    } else {
      showSnackbar("savedSuccessfuly");
    }
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* downloadApplicantsSample({ payload }) {
  try {
    const response = yield call(API.downloadApplicantsSample, payload);
    yield put(ACTION.downloadApplicantsSampleRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* UploadExcelSagasWatch() {
  yield takeLatest(type.AGENTS_EXCEL_SAMPLE_REQUEST, requestAgentsExcelSample);
  yield takeLatest(type.UPLOAD_AGENTS_EXCEL_REQUEST, requestUploadAgentsExcel);
  yield takeLatest(type.SALARY_EXCEL_SAMPLE_REQUEST, requestSalaryExcelSample);
  yield takeLatest(type.UPLOAD_SALARY_EXCEL_REQUEST, requestUploadSalaryExcel);
  yield takeLatest(type.IMPORT_APPLICANTS_REQUEST, UploadApplicantsExcel);
  yield takeLatest(
    type.DOWNLOAD_APPLICANTS_SAMPLE_REQUEST,
    downloadApplicantsSample
  );
}
