import * as types from "../types/MyInterviews";

export default (
  state = {
    interviewDetails: {
      candidateInfo: {
        advertQuestions: {}
      },
      jobDetails: {},
    },
  },
  action
) => {
  switch (action.type) {
    case types.MY_INTERVIEWS_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.INTERVIEW_DETAILS_RECEIVE:
      return {
        ...state,
        interviewDetails: action.payload,
      };
    default:
      return state;
  }
};
