import * as types from "../types/MyInterviews";

export const myInterviewsRequest = (payload) => ({
  type: types.MY_INTERVIEWS_REQUEST,
  payload,
});
export const myInterviewsReceive = (payload) => ({
  type: types.MY_INTERVIEWS_RECEIVE,
  payload,
});
export const myPrevInterviewsRequest = (payload) => ({
  type: types.MY_PREV_INTERVIEWS_REQUEST,
  payload,
});
export const  myPrevInterviewsReceive = (payload) => ({
  type: types.MY_PREV_INTERVIEWS_RECEIVE,
  payload,
});
export const interviewDetailsRequest = (payload) => ({
  type: types.INTERVIEW_DETAILS_REQUEST,
  payload,
});
export const interviewDetailsRecieve = (payload) => ({
  type: types.INTERVIEW_DETAILS_RECEIVE,
  payload,
});