import * as types from "../types/candidateTalentPool";

export const candidateTalentPoolRequest = (payload) => {
  return {
    type: types.CANDIDATE_TALENT_POOL_REQUEST,
    payload,
  };
};

export const candidateTalentPoolRecieve = (payload) => {
  return {
    type: types.CANDIDATE_TALENT_POOL_RECIEVE,
    payload,
  };
};
export const candidateTalentPoolJobOpeningRecieve = (payload) => {
  return {
    type: types.CANDIDATE_TALENT_POOL_JOB_OPENING_RECIEVE,
    payload,
  };
};
export const candidateTalentPoolJobOpeningRequest = (payload) => {
  return {
    type: types.CANDIDATE_TALENT_POOL_JOB_OPENING_REQUEST,
    payload,
  };
};
export const uploadApplicantsRequest= payload => ({
  type: types.IMPORT_APPLICANTS_REQUEST,
  payload
});

export const requestAddApplicants = (payload) => ({
  type: types.REQUEST_ADD_APPLICANT,
  payload,
});

export const receiveAddApplicants = (payload) => ({
  type: types.RECEIVE_ADD_APPLICANTS,
  payload,
});
export const requestGetPipelineApplications = (payload) => {
  return {
    type: types.REQUEST_GET_PIPELINE_APPLICATIONS_TALENT_POOL,
    payload,
  };
}
export const receiveGetPipelineApplications = (payload) => ({
  type: types.RECEIVE_GET_PIPELINE_APPLICATIONS,
  payload,
});
export const requestPipelineStages = (payload) => {
  return {
    type: types.REQUEST_GET_PIPELINE_STAGES_TALENT_POOL,
    payload,
  };
};

export const receivePipelineStages = (payload) => ({
  type: types.RECIEVE_GET_PIPELINE_STAGES,
  payload,
});