import * as types from '../types';

export const setSideMenu = (data) => {
  return {
  type: types.SET_SIDE_MENU, 
  payload : data ,
  };
};


