import { call, put } from "redux-saga/effects";
import API from "../../network/apis/jobRequisitionSetupAPI";
import * as ACTION from "../actions/jobRequisitionSetup";
import store from "../../store";
import history from "../../routes/History";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/jobRequisitionSetup";

export function* getJobRequisitionSetup() {
  try {
    const response = yield call(API.getJobRequisitionSetup);
    yield put(ACTION.receiveGetJobRequisitionSetup(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* addEditJobRequisitionSetup(action) {
  try {
    yield call(API.addEditJobRequisitionSetup, action.payload);
    yield put(ACTION.requestGetJobRequisitionSetup());
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* addEditJobRequisitionNoRateSetup(action) {
  try {
    yield call(
      API.addEditJobRequisitionNoRateSetup,
      action.payload
    );
    yield put(ACTION.requestGetJobRequisitionSetup());
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* JobRequisitionSetupSagasWatch() {
  yield takeLatest(
    type.GET_JOB_REQUISITION_SETUP_REQUEST,
    getJobRequisitionSetup
  );
  yield takeLatest(
    type.ADD_EDIT_JOB_REQUISITION_SETUP_REQUEST,
    addEditJobRequisitionSetup
  );
  yield takeLatest(
    type.ADD_EDIT_JOB_REQUISITION_NO_RATE_SETUP_REQUEST,
    addEditJobRequisitionNoRateSetup
  );
}
