import * as types from "../types/SalaryGrade";

const INITIAL_STATE = {}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.GET_SALARY_GRADE_RECEIVE:
            return { ...state, ...action.payload};
        case types.ADD_EDIT_SALARY_GRADE_RECEIVE:
            return { ...state, ...action.payload};
        case types.DELETE_SALARY_GRADE_RECEIVE:
            return { ...state, ...action.payload};
        default: return state;
    }
}