import * as types from "../types";

export const requestLogin = (data) => {
  return {
    type: types.LOGIN_REQUEST,
    payload: data,
  };
};

export const receiveLogin = (data) => {
  return {
    type: types.LOGIN_RECEIVE,
    payload: data,
  };
};

export const requestValidateDomain = (data) => {
  return {
    type: types.VALIDATE_DOMAIN_REQUEST,
    payload: data,
  };
};

export const receiveValidateDomain = (data) => {
  return {
    type: types.VALIDATE_DOMAIN_RECEIVE,
    payload: data,
  };
};

export const requestForgetPassword = (data) => {
  return {
    type: types.FORGET_PASSWORD_REQUEST,
    payload: data,
  };
};

export const receiveForgetPassword = (data) => {
  return {
    type: types.FORGET_PASSWORD_RECEIVE,
    payload: data,
  };
};

export const requestResetPassword = (data) => {
  return {
    type: types.RESET_PASSWORD_REQUEST,
    payload: data,
  };
};

export const receiveResetPassword = (data) => {
  return {
    type: types.RESET_PASSWORD_RECEIVE,
    payload: data,
  };
};
export const socialLoginRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  payload
})
export const socialLoginModalRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST,
  payload,
});
export const socialLoginModalFillRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST,
  payload,
});
export const socialLoginPopupOpenedRequest = (payload) => ({
  type: types.SOCIAL_LOGIN_POPUP_OPENED_REQUEST,
  payload,
});
export const socialLoginPhoneRequired = (payload) => ({
  type: types.SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED,
  payload,
});
