import * as types from "../types/jobRequisitionSetup";

export const requestGetJobRequisitionSetup = () => {
    return {
    type: types.GET_JOB_REQUISITION_SETUP_REQUEST
    }
};

export const receiveGetJobRequisitionSetup = (data) => {
    return {
    type: types.GET_JOB_REQUISITION_SETUP_RECEIVE,
    payload : data
    }
};

export const requestAddEditJobRequisitionSetup = (data) => {
    return {
    type: types.ADD_EDIT_JOB_REQUISITION_SETUP_REQUEST ,
    payload : data
    }
};

export const receiveAddEditJobRequisitionSetup = (data) => {
    return {
    type: types.ADD_EDIT_JOB_REQUISITION_SETUP_RECEIVE,
    payload : data
    }
};

export const requestAddEditJobRequisitionNoRateSetup = (data) => {
    return {
    type: types.ADD_EDIT_JOB_REQUISITION_NO_RATE_SETUP_REQUEST ,
    payload : data
    }
};

export const receiveAddEditJobRequisitionNoRateSetup = (data) => {
    return {
    type: types.ADD_EDIT_JOB_REQUISITION_NO_RATE_SETUP_RECEIVE,
    payload : data
    }
};
