export const percentNumber = (value, total) => {
  let result = value / total;
  return result > 0 ? (result * 100).toFixed(1).replace(/\.0$/, '') : 0;
};
const interviewsFields = [
  'total_face_to_face_interviews',
  'total_phone_interviews',
  'total_video_interviews',
];
const offersFields = [
  'total_accepted_offer',
  'total_negotiation_offer',
  'total_pending_offer',
  'total_rejected_offer',
  'total_waiting_candidate_offer',
];

const contractsFields = [
  'total_accepted_contract',
  'total_negotiation_contract',
  'total_pending_contract',
  'total_rejected_contract',
  'total_waiting_candidate_contract',
];
export const manipulateData = (report) => {
  let interviews,
    offers, contracts = {}

  interviewsFields.forEach((item) => {
    interviews = {
      ...interviews,
      [`${item}_percent`]: percentNumber(
        report?.interviews[item],
        report?.interviews?.total_interviews
      ),
    };
  });
  offersFields.forEach((item) => {
    offers = {
      ...offers,
      [`${item}_percent`]: percentNumber(
        report?.offers[item],
        report?.offers?.total_offers
      ),
    };
  });


  contractsFields.forEach((item) => {
    contracts = {
      ...contracts,
      [`${item}_percent`]: percentNumber(
        report?.contracts[item],
        report?.contracts?.total_contracts
      ),
    };
  });
  return {
    ...interviews,
    ...offers,
    ...contracts
  };
};
