import History from "routes/History";
import { isUserHasPermission, isSuperAdmin } from "../utils/Permissions";
export const handleNotificationRedirection = (
  redirectionId,
  item_id = null
) => {
  localStorage.setItem("active_stage_index", 0);
  switch (parseInt(redirectionId)) {
    case 8:
      History.push(`/Requests`);
      break;
    case 9:
      History.push(`/candidateApprovals`);
      break;
    case 10:
      History.push(`/Requests`);
      break;
    case 11:
      History.push(`/viewAllRequests`);
      break;
    case 12:
      History.push(`/viewAllRequests?state=closed`);
      break;
    case 13:
      localStorage.setItem("active_stage_index", 4);
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 14:
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 15:
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 16:
      History.push(`/applicantProfile?candidate_id=${item_id}&tab=5`);
      break;
    case 17:
      History.push(`/applicantProfile?candidate_id=${item_id}`);
      break;
    case 18:
      History.push(`/applicantProfile?candidate_id=${item_id}&tab=3`);
      break;
    case 19:
      History.push(`/myInterviews?page=1&type=new`);
      break;
    case 20:
      History.push(`/myInterviews?page=1&type=new`);
      break;
    case 26:
      window.location.href = `/applicantProfile?candidate_id=${item_id}&tab=9`;
      break;
    case 29:
      History.push(`/Requests`);
      break;
    case 27:
      localStorage.setItem("active_stage_index", 7);
      window.location.href = `/manageCandidates/${item_id}`;
      break;
    case 28:
      localStorage.setItem("active_stage_index", 7);
      window.location.href = `/manageCandidates/${item_id}`;
      break;
    case 31:
      (isUserHasPermission("TALENT_POOL_LIST") || isSuperAdmin()) && History.push(`/applicantProfile?candidate_id=${item_id}`);
      break;
  }
};
