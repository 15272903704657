export const EDIT_REJECTION_REASON_REQUEST = 'EDIT_REJECTION_REASON_REQUEST';
export const EDIT_REJECTION_REASON_RECIEVE = 'EDIT_REJECTION_REASON_RECIEVE';
export const GET_REJECTION_REASONS_REQUEST = 'GET_REJECTION_REASONS_REQUEST';
export const GET_REJECTION_REASONS_RECIEVE = 'GET_REJECTION_REASONS_RECIEVE';
export const ADD_REJECTION_REASON_REQUEST = 'ADD_REJECTION_REASON_REQUEST';
export const ADD_REJECTION_REASON_RECIEVE = 'ADD_REJECTION_REASON_RECIEVE';
export const DELETE_REJECTION_REASON_REQUEST = 'DELETE_REJECTION_REASON_REQUEST';
export const DELETE_REJECTION_REASON_RECIEVE = 'DELETE_REJECTION_REASON_RECIEVE'; 
export const GET_REJECTION_REASON_RECIEVE = 'GET_REJECTION_REASON_RECIEVE';
export const GET_REJECTION_REASON_REQUEST = 'GET_REJECTION_REASON_REQUEST';
