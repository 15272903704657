export const CANDIDATE_TALENT_POOL_REQUEST = "CANDIDATE_TALENT_POOL_REQUEST";
export const CANDIDATE_TALENT_POOL_RECIEVE = "CANDIDATE_TALENT_POOL_RECIEVE";
export const CANDIDATE_TALENT_POOL_JOB_OPENING_REQUEST = "CANDIDATE_TALENT_POOL_JOB_OPENING_REQUEST";
export const CANDIDATE_TALENT_POOL_JOB_OPENING_RECIEVE = "CANDIDATE_TALENT_POOL_JOB_OPENING_RECIEVE";
export const IMPORT_APPLICANTS_REQUEST = "IMPORT_APPLICANTS_REQUEST";
export const REQUEST_ADD_APPLICANT = "REQUEST_ADD_APPLICANT";
export const RECEIVE_ADD_APPLICANTS = "RECEIVE_ADD_APPLICANTS";
export const REQUEST_GET_PIPELINE_APPLICATIONS_TALENT_POOL = "REQUEST_GET_PIPELINE_APPLICATIONS_TALENT_POOL";
export const RECEIVE_GET_PIPELINE_APPLICATIONS = "RECEIVE_GET_PIPELINE_APPLICATIONS";
export const REQUEST_GET_PIPELINE_STAGES_TALENT_POOL = "REQUEST_GET_PIPELINE_STAGES_TALENT_POOL";
export const RECIEVE_GET_PIPELINE_STAGES = "RECIEVE_GET_PIPELINE_STAGES";
