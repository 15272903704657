import * as Yup from "yup";

export const EditEmailSchema = Yup.object().shape({
  subject: Yup.string().trim().required("required"),
  title: Yup.string().trim().required("required"),
});

export const EditEmailEditorValidation = (values) => {
  let errors = {};
  if (values?.body?.plain_text.trim().length === 0) {
    errors = {
      ...errors,
      body: "required",
    };
  }
  return errors;
};
