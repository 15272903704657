import * as types from '../types';

export const setStartMenu = (data) => {
  return {
  type: types.SET_START_YOUR_JOURNY_MENU, 
  payload : data ,
  };
};


