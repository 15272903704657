import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import img from "../../assets/images/black-white.png";
import logo from "../../assets/images/ic_upload.svg";
import "./UploadFile.scss";
import { fileExtensions } from "./Extensions";
import CancelIcon from "@material-ui/icons/Cancel";
import {

  IconButton,
} from "@material-ui/core";
const UploadFile = ({
  fileSrc,
  onUploadFile,
  customFileSize,
  CustomUI,
  reupload,
  customFileFormate,
  isImage,
  previewLogo,
  ratio,
  UploadedFileName,
  btnName,
  width,
  uploadCVOnly,
  disabled,
  customFileFormateMessage,
  onRemoveFile,
  hasRemove
}) => {
  const [file, setFile] = useState(img);
  const [fileType, setFileType] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [imageSizeErr, setImageSizeErr] = useState(false);
  const [fileSizeErr, setFileSizeErr] = useState(false);
  const [fileName, setFileName] = useState("");
  let fileFormateMessage =
    customFileFormateMessage || "png, jpg, jpeg, pdf, doc, docx, msword,xlsx";
  let fileFormate =
    customFileFormate ||
    "image/png, image/jpeg, application/pdf, .doc,.docx,.xlsx,.xls,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  useEffect(() => {
    setFileName(UploadedFileName);
    if (fileSrc) {
      setFile(fileSrc);
      setFileName(UploadedFileName);
    } else {
      setFile(img);
    }
  });

  const previewFile = (e) => {
    let fileSize = e.target?.files[0]?.size / 1024 / 1024;
    let fileSizeLarge = customFileSize
      ? fileSize > customFileSize
      : fileSize > 3;
    let fileType = e.target?.files[0]?.type;
    console.log(fileType)
    const fileNameArr = e.target.value.split("\\");
    const fileName = fileNameArr[fileNameArr.length - 1];
    const extension = fileExtensions[fileType];
    let isFileCorrect = false;
    if (uploadCVOnly) {
      if (
        fileType === "application/pdf" ||
        fileType === ".doc" ||
        fileType === ".docx" ||
        fileType === ".xlsx" ||
        fileType === "application/msword" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

      ) {
        if (fileSize > 10) {
          handleUploadFile(false, false, true, null);
          isFileCorrect = false;
          setFileName("");
        } else {
          isFileCorrect = true;
        }
      } else {
        handleUploadFile(true, false, false, null);
        isFileCorrect = false;
        setFileName("");
      }
    } else {
      if (fileFormate.includes(fileType) && (fileType === "image/jpg" || fileType === "image/png" || fileType === "image/jpeg")) {
        if (fileSize > 10) {
          handleUploadFile(false, true, false, null);
          isFileCorrect = false;
        } else {
          isFileCorrect = true;
        }
      } else if (
        fileFormate.includes(fileType)||
        // fileType === "application/pdf" ||
        // fileType === ".doc" ||
        // fileType === ".docx" ||
        // fileType === ".xml" ||
        // fileType === "application/msword" ||
        // fileType === "application/vnd.ms-excel" ||
        fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === ".xlsx" ||
        fileType === ".xls" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

      ) {
        if (fileSizeLarge) {
          handleUploadFile(false, false, true, null);
          isFileCorrect = false;
        } else {
          isFileCorrect = true;
        }
      } else {
        handleUploadFile(true, false, false, null);
        isFileCorrect = false;
      }
    }

    if (isFileCorrect) {
      setFileType(fileType);
      setFileName(fileName);
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
        if (fileType === "image/png" || fileType === "image/jpeg") {
          let img = new Image();
          img.src = _event.target.result;
          img.onload = function () {
            let resultImage = reader.result;
            handleUploadFile(
              false,
              false,
              false,
              resultImage,
              extension,
              fileName
            );
          };
        } else {
          let resultFile = reader.result;
          handleUploadFile(
            false,
            false,
            false,
            resultFile,
            extension,
            fileName
          );
        }
      };
    }
  };

  const handleUploadFile = (
    fileTypeErrParam,
    imgSizeErrParam,
    fileSizeErrParam,
    fileParam,
    fileTypeParam,
    fileNameParam
  ) => {
    setFile(fileParam);
    setFileTypeError(fileTypeErrParam);
    setImageSizeErr(imgSizeErrParam);
    setFileSizeErr(fileSizeErrParam);
    onUploadFile({
      file: fileParam,
      fileTypeError: fileTypeErrParam,
      imgSizeErr: imgSizeErrParam,
      fileSizeErr: fileSizeErrParam,
      fileType: fileTypeParam,
      fileName: fileNameParam,
    });
  };
  const typeOfFile = () => {
    if (previewLogo && previewLogo.includes("https")) {  
      if (
        previewLogo.endsWith(".png") ||
        previewLogo.endsWith(".jpg") ||
        previewLogo.endsWith(".jpeg")
      ) {
        return "is-image";
      } else {
        return "not-image";
      }
    } else {
      if (
        fileType &&
        (fileType?.includes("png") ||
          fileType?.includes("jpeg") ||
          fileType?.includes("jpg"))
      ) {
        return "is-image";
      } else {
        return "not-image";
      }
    }
  };
  const DefaultUI = () => {
    return (
      <>
        {typeOfFile() === "is-image" ? (
          <div
            className={
              ratio
                ? "uploade-image__img uploade-image__img--lg"
                : "uploade-image__img uploade-image__img--triangle"
            }
            style={{
              backgroundImage: `url(${previewLogo})`,
              backgroundSize: "contain",
            }}
          ></div>
        ) : (
          <div className="upload-logo-container d-flex justify-content-center align-items-center">
            <img src={logo} alt="upload-img" className="upload-logo" />
          </div>
        )}
        {!isImage && (
          <p
            className={`semi_bold_font ${fileName ? "no-file-logo mb-0 mt-1" : "my-2"
              }`}
          >
            {fileName ? fileName : btnName}
          </p>
        )}

        {!fileName && !isImage && (
          <p className="file-type mb-1">{fileFormateMessage}</p>
        )}
        {isImage && !previewLogo && (
          <p className="file-type mt-2 mb-0">{"png, jpg, jpeg"}</p>
        )}
        {hasRemove && <div className=" d-flex justify-content-end align-self-end cancel-btn">

          <IconButton >
            <CancelIcon
              onClick={(e) => {
                e.preventDefault()
                onRemoveFile()
              }}
              color={"gray"}
              fontSize="small"
              className="action_icons medium_font"
            />
          </IconButton>
        </div>}

      </>
    );
  };
  return (
    <>
      {!reupload ? (
        <div className="file-uploader mb-2 text-center">
          <div className="d-flex justify-content-center align-items-center file-uploader-wrapper">
            <button
              type="button"
              className="btn d-flex flex-column justify-content-center align-items-center btn-defualt--small uploade-image__btn medium_font"
            >
              <DefaultUI />
              <input
             
                type="file"
                accept={fileFormate}
                onChange={(e) => previewFile(e)}
                disabled={disabled || false}
                className="file-input"
              />
            </button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="btn relative_element uploade-image__btn medium_font"
        >
          <CustomUI />
          <input
            type="file"
            accept={fileFormate}
            className="file-input"
            onChange={(e) => previewFile(e)}
            disabled={disabled || false}
          />
        </button>
      )}
    </>
  );
};

const UploadFileComponent = injectIntl(UploadFile);

export default UploadFileComponent;
