import { expireAfter3Days } from "./shared";
import { jobSeekerUrl } from "./redirections";
import HRCOMLogoNoWord from "../assets/images/ic_logo-hr.png";
import HRCOMLogoWhite from "../assets/images/ic_logo-footer.svg";
import HRCOMLogoNavbar from "../assets/images/0-01.png";
import HRCOMLogoAuth from "../assets/images/0-06.png";
import cssVariables from "../scss/_export-variables.scss";

export const defaultSetupColors_Logos = {
  colors: {
    primary_color: cssVariables.defaultPrimaryColor,
    secondary_color: cssVariables.defaultSecondaryColor,
  },
  logos: {
    logo_navbar: HRCOMLogoNavbar,
    logo_auth: HRCOMLogoAuth,
  },
};

export const candidatePortalSetupPage_Colors = ({
  primary_color,
  secondary_color,
  plan_features: { has_brand_colors },
}) => {
  if (
    has_brand_colors &&
    !expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      primary_color: primary_color ? primary_color : "#86364e",
      secondary_color: secondary_color ? secondary_color : "#ea8685",
      disabled: false,
    };
  }
  if (
    !has_brand_colors ||
    expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      primary_color: primary_color ? primary_color : "#86364e",
      secondary_color: secondary_color ? secondary_color : "#ea8685",
      disabled: true,
    };
  }
};

export const colorsWithPlan = ({
  primary_color,
  secondary_color,
  plan_features: { has_brand_colors },
  company_subscription_details: { current_plan_end_date },
}) => {
  if (!has_brand_colors || expireAfter3Days(current_plan_end_date)) {
    return {
      ...defaultSetupColors_Logos.colors,
    };
  } else if (has_brand_colors && !expireAfter3Days(current_plan_end_date)) {
    return {
      transparent_color:
        primary_color === "#86364e" && secondary_color === "#ea8685"
          ? `rgba(172, 69, 86, 0.06)`
          : `rgba(0, 0, 0, 0.03)`,
      primary_color: primary_color
        ? primary_color
        : defaultSetupColors_Logos.primary_color,
      secondary_color: secondary_color
        ? secondary_color
        : defaultSetupColors_Logos.secondary_color,
    };
  }
};

export const logoWithPlan = ({
  company_logo_url,
  secondary_color,
  primary_color,
  company_subscription_details,
  plan_features: { has_brand_logo, has_brand_colors },
}) => {
  const expire = company_subscription_details.current_plan_end_date;
  if (expireAfter3Days(expire)) {
    return defaultSetupColors_Logos.logos;
  }
  if (has_brand_logo) {
    return {
      logo_navbar: company_logo_url,
      logo_auth: company_logo_url,
    };
  }
  if (
    has_brand_colors &&
    (primary_color !== defaultSetupColors_Logos.colors.primary_color ||
      secondary_color !== defaultSetupColors_Logos.colors.secondary_color)
  ) {
    return {
      logo_navbar: HRCOMLogoNoWord,
      logo_auth: HRCOMLogoWhite,
    };
  }
  if (
    primary_color === defaultSetupColors_Logos.colors.primary_color &&
    secondary_color === defaultSetupColors_Logos.colors.secondary_color
  ) {
    return defaultSetupColors_Logos.logos;
  }
};

export const candidatePortalLogo = (props) => {
  const {
    website_url,
    plan_features: { has_linking_your_website },
  } = props;
  const expire = props.company_subscription_details.current_plan_end_date;
  let disableLink = "";
  let url = jobSeekerUrl();
  if (has_linking_your_website) {
    url = website_url && website_url !== "" ? `${website_url}` : jobSeekerUrl();
  }
  if (expireAfter3Days(expire)) {
    url = jobSeekerUrl();
  }
  return {
    url,
    disableLink,
  };
};
export const companyBriefWithPlan = ({
  plan_features: { has_company_brief },
}) => {
  if (
    has_company_brief &&
    !expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      hide: false,
    };
  }
  if (
    !has_company_brief ||
    expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      hide: true,
    };
  }
};
export const LinkWebsiteWithPlan = ({
  plan_features: { has_linking_your_website },
}) => {
  if (
    has_linking_your_website &&
    !expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      disabled: false,
    };
  }
  if (
    !has_linking_your_website ||
    expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      disabled: true,
    };
  }
};
export const PostingThroughSocialMediaWithPlan = ({
  plan_features: { has_posting_jobs_via_socialmedia },
}) => {
  if (
    has_posting_jobs_via_socialmedia &&
    !expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      hide: false,
    };
  }
  if (
    !has_posting_jobs_via_socialmedia ||
    expireAfter3Days(localStorage.getItem("subscription_end"))
  ) {
    return {
      hide: true,
    };
  }
};
