import * as type from "../types/lookups";

const INITIAL_STATE = {
  countries: [],
  cities: [],
  permissions: [],
  jobRoles: [],
  salaryGrades: [],
  residencies: [],
  nationalities: [],
  languages: [],
  careerLevels: [],
  jobTitles: [],
  colleges: [],
  companyPlan: null,
  degrees: [],
  competencies: [],
  companyBranches: [],
  currencies: [],
  jobTitlesCategories: [],
  agents: [],
  companyFields: [],
  jobOpenings: [],
  pipelineFLows: [],
  rejectionReasons: [],
  companySizes: [],
  companyTypes: [],
  jobTitlesFilterLookup: [],
  jobStatusFilterLookup: [],
  branchesFilterLookup: [],
  departments: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.GET_COUNTRIES_RECEIVE:
      return { ...state, countries: [...action.payload] };
    case type.GET_CITIES_RECEIVE:
      return { ...state, cities: [...action.payload] };
    case type.GET_ALL_PERMISSIONS_RECEIVE:
      return { ...state, permissions: [...action.payload] };
    case type.GET_ALL_JOB_ROLES_LOOKUP_RECEIVE:
      return { ...state, jobRoles: [...action.payload] };
    case type.LOOKUP_SALARY_GRADES:
      return { ...state, salaryGrades: [...action.payload] };
    case type.LOOKUP_RESIDENCIES:
      return { ...state, residencies: [...action.payload] };
    case type.LOOKUP_NATIONALITIES:
      return { ...state, nationalities: [...action.payload] };
    case type.LOOKUP_LANGUAGES:
      return { ...state, languages: [...action.payload] };
    case type.LOOKUP_CAREER_LEVELS:
      return { ...state, careerLevels: [...action.payload] };
    case type.LOOKUP_JOB_TITLES:
      return { ...state, jobTitles: [...action.payload] };
    case type.LOOKUP_COLLEGES:
      return { ...state, colleges: [...action.payload] };
    case type.LOOKUP_DEGREES:
      return { ...state, degrees: [...action.payload] };
    case type.LOOKUP_COMPETENCIES:
      return { ...state, competencies: [...action.payload] };
    case type.LOOKUP_COMPANY_BRANCHES_RECEIVE:
      return { ...state, companyBranches: [...action.payload] };
    case type.LOOKUP_SALARY_GRADES_FILTER_RECEIVE:
      return { ...state, salaryGrades: [...action.payload] };
    case type.LOOKUP_CURRENCY_RECEIVE:
      return { ...state, currencies: [...action.payload] };
    case type.LOOKUP_JOBTITLES_CATEGORIES_RECEIVE:
      return { ...state, jobTitlesCategories: [...action.payload] };
    case type.LOOKUP_AGENTS_RECEIVE:
      return { ...state, agents: [...action.payload] };
    case type.LOOKUP_COMPANY_FIELDS_RECEIVE:
      return { ...state, companyFields: [...action.payload] };
    case type.LOOKUP_GET_JOB_OPENINGS_RECEIVE:
      return { ...state, jobOpenings: [...action.payload] };
    case type.LOOKUP_GET_PIPELINE_FLOWS_RECEIVE:
      return { ...state, pipelineFLows: [...action.payload] };
    case type.LOOKUP_GET_REJECTION_REASONS_RECEIVE:
      return { ...state, rejectionReasons: [...action.payload] };
    case type.LOOKUP_COMPANY_TYPES_RECEIVE:
      return { ...state, companyTypes: [...action.payload] };
    case type.LOOKUP_COMPANY_SIZES_RECEIVE:
      return { ...state, companySizes: [...action.payload] };
    case type.RECIEVE_COMP_PLAN:
      return { ...state, companyPlan: [...action.payload] };
    case type.JOB_TITLE_FILTER_LOOKUP_RECEIVE:
      return { ...state, jobTitlesFilterLookup: [...action.payload] };
    case type.JOB_STATUS_FILTER_LOOKUP_RECEIVE:
      return { ...state, jobStatusFilterLookup: [...action.payload] };
    case type.BRANCH_FILTER_LOOKUP_RECEIVE:
      return { ...state, branchesFilterLookup: [...action.payload] };
    case type.LOOKUP_DEPARTMENTS_RECEIVE:
      return { ...state, departments: [...action.payload] };
    default:
      return state;
  }
};
