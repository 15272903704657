export const CUSTOMIZE_EMAIL_TEMPLATE_REQUEST =
  "CUSTOMIZE_EMAIL_TEMPLATE_REQUEST";
export const CUSTOMIZE_EMAIL_TEMPLATE_RECIEVE =
  "CUSTOMIZE_EMAIL_TEMPLATE_RECIEVE";
export const ADD_EMAIL_TEMPLATE_REQUEST = "ADD_EMAIL_TEMPLATE_REQUEST";
export const EDIT_EMAIL_TEMPLATE_REQUEST = "EDIT_EMAIL_TEMPLATE_REQUEST";
export const EDIT_EMAIL_TEMPLATE_RECIEVE = "EDIT_EMAIL_TEMPLATE_RECIEVE";
export const DELETE_EMAIL_TEMPLATE_REQUEST = "DELETE_EMAIL_TEMPLATE_REQUEST";

export const GET_EMAIL_TEMPLATE_PLACEHOLDERS_REQUEST = "GET_EMAIL_TEMPLATE_PLACEHOLDERS_REQUEST";
export const GET_EMAIL_TEMPLATE_PLACEHOLDERS_RECIEVE = "GET_EMAIL_TEMPLATE_PLACEHOLDERS_RECIEVE";
export const GET_EMAIL_TEMPLATE_LIST_REQUEST = "GET_EMAIL_TEMPLATE_LIST_REQUEST";
export const GET_EMAIL_TEMPLATE_LIST_RECIEVE = "GET_EMAIL_TEMPLATE_LIST_RECIEVE";
export const GET_CANDIDATE_EMAIL_TEMPLATE_REQUEST = "GET_CANDIDATE_EMAIL_TEMPLATE_REQUEST";
export const GET_CANDIDATE_EMAIL_TEMPLATE_RECIEVE = "GET_CANDIDATE_EMAIL_TEMPLATE_RECIEVE";
export const UPDATE_CUSTOM_EMAIL_TEMPLATE_REQUEST = "UPDATE_CUSTOM_EMAIL_TEMPLATE_REQUEST";
export const EMAIL_TEMPLATE_DELETED = "EMAIL_TEMPLATE_DELETED";

export const BACK_TO_DEFAULT_REQUEST ="BACK_TO_DEFAULT_REQUEST"
export const BACK_TO_DEFAULT_RECIEVE="BACK_TO_DEFAULT_RECIEVE"
export const CLEAR_DEFAULT="CLEAR_DEFAULT"