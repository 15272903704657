export const dynamicTextEditorUpdates = (content, oldValue, newValue) => {
  return content.replace(oldValue, newValue);
};

export const replaceText = (content, valuesToBeReplaced) => {
  // console.log(content)
  // console.log(valuesToBeReplaced)
  const re = /\[INTERVIEW_TIME]|\[INTERVIEW_LOCATION]|\[INTERVIEW_DATE]|\[expiration-date]|\[INTERVIEWER_NAME]/gi;
  return content.replace(re, function (matched) {
    const key = matched.slice(1, -1);
    console.log(valuesToBeReplaced)
    return `<strong>${valuesToBeReplaced[key]}</strong>`;
  });
};
