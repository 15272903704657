export const REQUEST_SAVED_CARDS = 'REQUEST_SAVED_CARDS';
export const RECEIVE_SAVED_CARDS = 'RECEIVE_SAVED_CARDS';
export const REQUEST_CHECKOUT_ID = 'REQUEST_CHECKOUT_ID';
export const RECEIVE_CHECKOUT_ID = 'RECEIVE_CHECKOUT_ID';
export const REQUEST_CHECKOUT_STATUS = 'REQUEST_CHECKOUT_STATUS';
export const RECEIVE_CHECKOUT_STATUS = 'RECEIVE_CHECKOUT_STATUS';
export const ACTIVATE_PAYMENT_CARD_REQUEST = 'ACTIVATE_PAYMENT_CARD_REQUEST';
export const DELETE_PAYMENT_CARD_REQUEST = 'DELETE_PAYMENT_CARD_REQUEST';
export const SUBSCRIPTION_PLANS_REQUEST = 'SUBSCRIPTION_PLANS_REQUEST';
export const SUBSCRIPTION_PLANS_RECEIVE = 'SUBSCRIPTION_PLANS_RECEIVE';
export const SUBSCRIBTION_PAYMENT_REQUEST = 'SUBSCRIBTION_PAYMENT_REQUEST';
export const SUBSCRIBTION_PAYMENT_RECEIVE = 'SUBSCRIBTION_PAYMENT_RECEIVE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_RECEIVE = 'CANCEL_SUBSCRIPTION_RECEIVE';
export const BILLING_INFO_REQUEST = 'BILLING_INFO_REQUEST';
export const BILLING_INFO_RECEIVE = 'BILLING_INFO_RECEIVE';
export const PAYMENT_WITH_SAVED_CARDS_REQUEST =
  'PAYMENT_WITH_SAVED_CARDS_REQUEST';
export const PAYMENT_WITH_SAVED_CARDS_RECEIVE =
  'PAYMENT_WITH_SAVED_CARDS_RECEIVE';
export const RENEW_STATUS_RESPONSE_REQUEST = 'RENEW_STATUS_RESPONSE_REQUEST';
export const PAYMENT_CALCULATION_REQUEST = 'PAYMENT_CALCULATION_REQUEST';
export const PAYMENT_CALCULATION_RECEIVE = 'PAYMENT_CALCULATION_RECEIVE';
export const PAYMENT_WITH_WALLET_REQUEST = 'PAYMENT_WITH_WALLET_REQUEST';
export const PAYMENT_WITH_WALLET_RECEIVE = 'PAYMENT_WITH_WALLET_RECEIVE';
export const DOWNGRADE_LOST_DATA_REQUEST = 'DOWNGRADE_LOST_DATA_REQUEST';
export const DOWNGRADE_LOST_DATA_RECEIVE = 'DOWNGRADE_LOST_DATA_RECEIVE';
export const VALIDATE_PROMO_CODE_REQUEST = 'VALIDATE_PROMO_CODE_REQUEST';
export const VALIDATE_PROMO_CODE_RECEIVE = 'VALIDATE_PROMO_CODE_RECEIVE';
export const INVOICE_GENERATEION_REQUEST = 'INVOICE_GENERATEION_REQUEST';
export const INVOICE_GENERATEION_RECEIVE = 'INVOICE_GENERATEION_RECEIVE';
export const VAT_NUMBER_REQUEST = 'VAT_NUMBER_REQUEST';
export const VAT_NUMBER_RECEIVE = 'VAT_NUMBER_RECEIVE';
export const VAT_NUMBER_UPDATE_REQUEST = 'VAT_NUMBER_UPDATE_REQUEST';
export const VAT_NUMBER_UPDATE_RECIEVE = 'VAT_NUMBER_UPDATE_RECIEVE';
export const SUBSCRIPTION_END_DATE_REQUEST = 'SUBSCRIPTION_END_DATE_REQUEST';
export const SUBSCRIPTION_END_DATE_RECEIVE = 'SUBSCRIPTION_END_DATE_RECEIVE';
