import React, { useEffect } from "react";
import { InputField } from "../../Controls/InputField/InputField";
import { connect, useSelector } from "react-redux";
import messages from "../../../assets/Local/messages";
import "./AddApplicantModal.scss";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import UploadFileComponent from "../../UploadFile/UploadFile";
import UploadExcelFileComponent from "components/UploadExcelFile/UploadExcelFile";
import { ButtonComponent } from "components/Controls/Button/Button";
import History from "routes/History";
import { AutocompleteField } from "../../Controls/Autocomplete/Autocomplete";
import { Checkboxes } from "components/Controls/Checkboxes/Checkboxes";
import { genderOptions } from "utils/enums";
import { ActiveUnifunicSMSRequest } from "store/actions/requests";
import "./AddApplicantModal.scss";
import { EditEmail } from "components/EditEmail/EditEmail";
import {ProfileStepsFeedbackRequest} from "../../../store/actions/index"
import {  useDispatch } from "react-redux";
import cone from "../../../assets/images/cone-image.png"
import Excellent from "../../../assets/images/exellent.png"
import poor from "../../../assets/images/poor.png"
import fair from "../../../assets/images/fair.png"

const CongratulationModal = (props) => {
  const dispatch = useDispatch();
  const is_a_ghost_user = JSON.parse(localStorage.getItem("is_ghost"));
  const lang = useSelector((state) => state.locale.lang);
  const {
    isOpen,
    handleModalState,

 
  } = props;
  const message = messages[lang];





  

  useEffect(() => {
    // dispatch(ProfileStepsFeedbackRequest({feedback_status:1}))
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      scroll="body"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={`${lang === "ar" ? "text-right" : "text-left"}`}
    >
      <div className="my-3 ">
 
        <DialogContent className="text-center">
          <img className="cone" src={cone}></img>
          <h3 className="m-3 font-weight-bold cong ">{message.shared.Congratulations}</h3>
          <p className="primary_color">{message.shared.Thankyou}</p>
          <p className="primary_color mb-0">{message.shared.Enjoythefeatures}</p>
          <div className="row">  <div className=" col-md-1"></div>
         <div className="excited col-md-10"></div>
         <div className=" col-md-1"></div></div>
        
          <p className="mb-0">{message.shared.excited}</p>
          <div className="row "> 
          <div  className="col-md-4"></div>
           <div className="col-md-1" onClick={()=>{
           dispatch(ProfileStepsFeedbackRequest({feedback_status:1}))
           }}>  <img className="react pointer" src={Excellent}></img></div>
          <div className=" col-md-1" onClick={()=>{
           dispatch(ProfileStepsFeedbackRequest({feedback_status:2}))
           }}> <img className="react pointer" src={fair}></img></div>
          <div className=" col-md-1" onClick={()=>{
           dispatch(ProfileStepsFeedbackRequest({feedback_status:3}))
           }}> <img className="react pointer" src={poor}></img></div>
           <div  className="col-md-4"></div>
           </div>

          
          <ButtonComponent
          classes={"enjoy mb-4"}
               variant="contained"
              content={message.shared.Enjoy}
              color="primary"
              handleClick={() => {     
               History.push('/')
               handleModalState(false)
              }}
            />
        </DialogContent>
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = {
  getCompanyUnifonicIntegrationIsActive: ActiveUnifunicSMSRequest,
};

export default connect(null, mapDispatchToProps)(CongratulationModal);
