import React from "react";
import { Link } from "react-router-dom";
import "./Link.scss";

export const LinkComponent = ({ route, content, classes , target , handleClick , disabled}) => {
  const is_view_only = JSON.parse(localStorage.getItem("is_view_only"));
  return (
    <Link
      disabled={is_view_only || disabled}
      to={is_view_only || disabled ? "/notFound" : route}
      className={`${is_view_only || disabled ? "disabled-link" : ""} ${classes}`}
      target={target}
      onClick={handleClick}
    >
      {content}
    </Link>
  );
};
