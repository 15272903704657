import {axiosInstance} from './config';

let handlerEnabled = true;


const getJobRequisitionSetup = async () => {
    return await axiosInstance.get(`/admin/job_requisition/get`, {handlerEnabled});
}

const addEditJobRequisitionSetup = async (data) => {
    return await axiosInstance.put(`/admin/job_requisition/add_edit`, data ,{handlerEnabled});
}

const addEditJobRequisitionNoRateSetup = async (data) => {
    return await axiosInstance.put(`/admin/job_requisition/add_edit/no_rate`, data ,{handlerEnabled});
}

export default {
    getJobRequisitionSetup ,
    addEditJobRequisitionSetup,
    addEditJobRequisitionNoRateSetup
};