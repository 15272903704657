import { axiosInstance } from "./config";

let handlerEnabled = true;

const salaryGradesLookup = async () => {
  return await axiosInstance.get(`/admin/salary_grade/Lookup/get`, {
    handlerEnabled,
  });
};
const residenciesLookup = async () => {
  return await axiosInstance.get(`/lookup/residency/get`, { handlerEnabled });
};
const nationalitiesLookup = async () => {
  return await axiosInstance.get(`/lookup/nationality/get`, { handlerEnabled });
};
const languagesLookup = async () => {
  return await axiosInstance.get(`/lookup/language/get`, { handlerEnabled });
};
const careerLevelsLookup = async () => {
  return await axiosInstance.get(`/lookup/career_level/get`, {
    handlerEnabled,
  });
};
const jobtitlesLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/lookup`, { handlerEnabled });
};
const collegesLookup = async () => {
  return await axiosInstance.get(`/lookup/college/get`, { handlerEnabled });
};
const degreesLookup = async () => {
  return await axiosInstance.get(`/lookup/degree/get`, { handlerEnabled });
};
const competenciesLookup = async () => {
  return await axiosInstance.get(`/lookup/competencies/get`, {
    handlerEnabled,
  });
};

const jobTitleLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/lookup`, { handlerEnabled });
};

const salaryGradeFilterLookup = async (job_title_id) => {
  return await axiosInstance.get(`/admin/salary_grade/lookup/${job_title_id}`, {
    handlerEnabled,
  });
};

const companyBranchesLookup = async () => {
  return await axiosInstance.get(`/branch/lookup`, { handlerEnabled });
};

const currencyLookup = async () => {
  return await axiosInstance.get(`/lookup/currency/get`, { handlerEnabled });
};

const jobTitlesCategoryLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/categories/lookup`, {
    handlerEnabled,
  });
};

const agentsLookup = async () => {
  return await axiosInstance.get(`/admin/agent/lookups`, { handlerEnabled });
};

const companyFieldsLookup = async () => {
  const params = {
    isWizard: false,
  };
  return await axiosInstance.get(`/admin/job_title/categories/lookup`, {
    params,
    handlerEnabled,
  });
};

const jobOpeningsLookup = async () => {
  return await axiosInstance.get(`/admin/job_request/lookup`, {
    handlerEnabled,
  });
};

const pipelineFlowsLookup = async () => {
  return await axiosInstance.get(
    `/company/pipelineSetup/get_piplineSetupFlowsWithoutPaging`,
    { handlerEnabled }
  );
};

const rejectionReasonsLookup = async () => {
  return await axiosInstance.get(`/admin/companyRejectionReasons/get_allRejectionReasons`, {
    handlerEnabled,
  });
};

const companySizesLookup = async () => {
  return await axiosInstance.get(`/admin/lookups/company_sizes`, {
    handlerEnabled,
  });
};

const companyTypesLookup = async () => {
  return await axiosInstance.get(`/admin/lookups/company_types`, {
    handlerEnabled,
  });
};

const getCompanyPlan = async (id) => {
  return await axiosInstance.get(`/admin/subscriptions/plan`, {
    handlerEnabled,
  });
};

const getJobTitlesFilterLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/lookup`, {
    handlerEnabled,
  });
};

const getJobStatusFilterLookup = async () => {
  return await axiosInstance.get(
    `/admin/lookups/job_request_status_without_pending`,
    {
      handlerEnabled,
    }
  );
};

const getBranchesFilterLookup = async () => {
  return await axiosInstance.get(`/branch/lookup`, {
    handlerEnabled,
  });
};

const getDepartmentsLookup = async () => {
  return await axiosInstance.get(`/admin/departments/lookup`, {
    handlerEnabled,
  });
};

export default {
  salaryGradesLookup,
  residenciesLookup,
  nationalitiesLookup,
  languagesLookup,
  careerLevelsLookup,
  jobtitlesLookup,
  collegesLookup,
  degreesLookup,
  competenciesLookup,
  jobTitleLookup,
  salaryGradeFilterLookup,
  companyBranchesLookup,
  currencyLookup,
  jobTitlesCategoryLookup,
  agentsLookup,
  companyFieldsLookup,
  jobOpeningsLookup,
  pipelineFlowsLookup,
  rejectionReasonsLookup,
  companySizesLookup,
  companyTypesLookup,
  getCompanyPlan,
  getJobTitlesFilterLookup,
  getJobStatusFilterLookup,
  getBranchesFilterLookup,
  getDepartmentsLookup
};
