import * as types from "../types/DepartmentsSetup";

const INITIAL_STATE = {}

export default function departmentsReducer (state = INITIAL_STATE , action) {
    let departmentData = action.payload
    switch (action.type) {
        case types.ADD_DEPARTMENT_RECEIVE:
            return { ...state, departmentData};
        case types.GET_DEPARTMENTS_LIST_RECEIVE:
            return { ...state, ...departmentData};
        case types.GET_SINGLE_DEPARTMENT_RECEIVE:
            return { ...state, ...departmentData};
        case types.EDIT_DEPARTMENT_RECEIVE:
            return { ...state, ...departmentData};
        case types.DELETE_DEPARTMENT_RECEIVE:
            return { ...state, ...departmentData};
        default: return state;
    }
}