export const PERMISSIONS = {
  SETUP: 1,
  JOB_DETAILS: 2,
  JOB_REQUISITION: 3,
  CREATE_JOB_TITLE: 4,
  APPROVE_CANDIDATE_REQUESTS: 7,
  TALENT_POOL_LIST: 8,
  EDIT_JOBS: 9,
  DELETE_TEMPLATE: 10,
  VIEW_PENDING_JOB_REQUESTS: 51,
  EDIT_PENDING_JOB_REQUESTS: 52,
  DELETE_PENDING_JOB_REQUESTS: 53,
  LIST_JOB_REQUESTS: 54,
  VIEW_PUBLISHED_JOB_REQUESTS: 55,
  COPY_PUBLISHED_JOB_REQUESTS: 56,
  CLOSE_PUBLISHED_JOB_REQUESTS: 57,
  LIST_ALL_JOB_OPENINGS: 58,
  CHANGE_CLOSING_DATE_ALL_JOB_OPENINGS: 59,
  MANAGE_CANDIDATES_ALL_JOB_OPENINGS: 60,
  VIEW_CLOSED_JOB_REQUESTS: 61,
  MY_INTERVIEW_GRID: 62,
  APPROVAL: 63,
  CLOSE_RUNNING_JOBS: 64,
  HOLD_PUBLISHED_JOBS: 65,
  DUPLICATE_JOBS: 66,
  ADD_APPLICANT: 70,
  ADD_TO_JOB_OPENING: 71,
  VIEW_INTEGRATION: 74,
  EDIT_INTEGRATION: 75,
  VIEW_EMAIL_TEMPLATES: 76,
  CREATE_EMAIL_TEMPLATES: 77,
  EDIT_EMAIL_TEMPLATES: 78,
  DELETE_EMAIL_TEMPLATES: 79,
  VIEW_REPORTS: 80,
  VIEW_REJECTION_REASON: 81,
  ADD_REJECTION_REASON: 82,
  Edit_REJECTION_REASON: 83,
  DELETE_REJECTION_REASON: 84,
  UPDATE_CONTARCT_TEMPLATE: 85,
  VIEW_DEPARTMENTS: 86,
  CREATE_DEPARTMENTS: 87,
  EDIT_DEPARTMENTS: 88,
  DELETE_DEPARTMENTS: 89,
  VIEW_DOCUMENTS: 91,
  CREATE_DOCUMENTS: 92,
  EDIT_DOCUMENTS: 93,
  DELETE_DOCUMENTS: 94,
  EXPORT_FILE: 95,
  EXPORT_FILE_PENDING_JOB_REQUESTS: 96,
  EXPORT_FILE_LIST_JOB_REQUESTS: 97,
  VIEW_INTERVIEW_EVALUATION_FORM: 98,
  EDIT_INTERVIEW_EVALUATION_FORM: 99,
  QUICK_MANPOWER_REQUEST:100,
};

export const ConvertPermissions = () => {
  if (localStorage.getItem("token") && localStorage.getItem("permissions")) {
    let permissions = localStorage.getItem("permissions").split(",");
    console.log(permissions)
    let PERMISSIONS_ARR = [];
    permissions.map((user_permission) => {
      Object.keys(PERMISSIONS).map(function (key) {
        if (PERMISSIONS[key] == user_permission) {
          PERMISSIONS_ARR.push(key);
        }
      });
    });
    return PERMISSIONS_ARR;
  }
};

export const isUserHasPermission = (permission) => {
  let USER_PERMISSIONS = ConvertPermissions();
  // console.log(USER_PERMISSIONS)
  return USER_PERMISSIONS ? USER_PERMISSIONS.includes(permission) : null;
};

export const isSuperAdmin = () => {
  if (localStorage.getItem("token") && localStorage.getItem("roles")) {
    let rolesArr = localStorage.getItem("roles").split(",");
    let isSuperAdmin = rolesArr.includes("SUPER_ADMIN");
    return isSuperAdmin;
  }
};
