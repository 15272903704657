export const ADD_DEPARTMENT_REQUEST = 'ADD_DEPARTMENT_REQUEST';
export const ADD_DEPARTMENT_RECEIVE = 'ADD_DEPARTMENT_RECEIVE';

export const GET_SINGLE_DEPARTMENT_REQUEST = 'GET_SINGLE_DEPARTMENT_REQUEST';
export const GET_SINGLE_DEPARTMENT_RECEIVE = 'GET_SINGLE_DEPARTMENT_RECEIVE';

export const GET_DEPARTMENTS_LIST_REQUEST = 'GET_DEPARTMENTS_LIST_REQUEST';
export const GET_DEPARTMENTS_LIST_RECEIVE = 'GET_DEPARTMENTS_LIST_RECEIVE';

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_RECEIVE = 'DELETE_DEPARTMENT_RECEIVE';

export const EDIT_DEPARTMENT_REQUEST = 'EDIT_DEPARTMENT_REQUEST';
export const EDIT_DEPARTMENT_RECEIVE = 'EDIT_DEPARTMENT_RECEIVE';