import { axiosInstance } from "./config";

let handlerEnabled = true;

const candidatePortalSetup = async () => {
  return await axiosInstance.get(`/admin/setup/candidate_portal_setup`, {
    handlerEnabled,
  });
};
const saveCandidatePortalSetup = async (data) => {
  return await axiosInstance.put(`/admin/setup/candidate_portal_setup`, data, {
    handlerEnabled,
  });
};

const getrecommendedCandidatesSetup = async () => {
  return await axiosInstance.get(`/admin/setup/recommended_candidates_email_interval`, {
    handlerEnabled,
  });
};
const recommendedCandidatesSetup = async (data) => {
  return await axiosInstance.put(`/admin/setup/recommended_candidates_email_interval`, data, {
    handlerEnabled,
  });
};
const candidatePortalWithHistoryPlan = async () => {
  return await axiosInstance.get(
    `/admin/subscriptions/copany_susbscription_details`,
    { handlerEnabled }
  );
};

const setCandidateDefaultLogo = async () => {
  return await axiosInstance.put(
    `/admin/setup/set_candidate_home_Image`,
    { handlerEnabled }
  );
};
const setCandidateDefaultColor = async () => {
  return await axiosInstance.put(
    `/admin/setup/set_candidate_default_color`,
    { handlerEnabled }
  );
};

export default {
  candidatePortalSetup,
  saveCandidatePortalSetup,
  candidatePortalWithHistoryPlan,
  setCandidateDefaultColor,
  setCandidateDefaultLogo,
  recommendedCandidatesSetup,
  getrecommendedCandidatesSetup
};
