import * as types from "../types/candidateTalentPool";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CANDIDATE_TALENT_POOL_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
