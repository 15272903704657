import React from "react";
import Dialog from "../../../components/Dialog/Dialog";
import { ButtonComponent } from "../../../components/Controls/Button/Button";
import SyncingAccounts from "components/SyncingAccounts/SyncingAccounts";

const RemindSyncModal = ({
  show_email_sync_reminder,
  messages,
  closeModal,
  handleNotAllowReminder,
}) => {
  return (
    <div className="remind-sync-modal">
      <Dialog
        className="sync-reminder-modal"
        hasNegativeAction={false}
        open={show_email_sync_reminder}
        dialogContent={
          <div className="pb-4">
            <span>{messages.integrations.emailSync.reminderMessage}</span>
            <div className="py-4">
                <SyncingAccounts/>
                </div>
            <div className="d-flex mt-3">
              <ButtonComponent
                variant="contained"
                content={messages.integrations.emailSync.remindLater}
                color="primary"
                handleClick={closeModal}
              />
              <ButtonComponent
                variant="contained"
                content={messages.integrations.emailSync.donotAllow}
                color="secondary"
                handleClick={handleNotAllowReminder}
                classes="mx-2"
              />
            </div>
          </div>
        }
        isWithImage={false}
      />
    </div>
  );
};

export default RemindSyncModal;
