import * as types from './../types/interviewEvaluationSetup'



export const requestGetInterviewEvaluationSetup=()=>{
    return {
        type:types.GET_INTERVIEW_EVALUATION_SETUP_REQUEST
    }
}

export const receiveGetInterviewEvaluationSetup=(data)=>{
    return {
        type:types.GET_INTERVIEW_EVALUATION_SETUP_RECEIVE,
        payload:data
    }
}


export const requestAddInterviewEvaluationSetup=(data)=>{
    return {
        type:types.ADD_INTERVIEW_EVALUATION_SETUP_REQUEST,
        payload:data
    }
}

export const receiveAddInterviewEvaluationSetup=(data)=>{
    return {
        type:types.ADD_INTERVIEW_EVALUATION_SETUP_RECEIVE,
        payload:data
    }
}