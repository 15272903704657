import { call, put } from "redux-saga/effects";
import API from "../../network/apis/requests";
import * as ACTION from "../actions/requests";
import History from "routes/History";
import { showSnackbar, dispatchError, getURLParams } from "utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/requests";

export function* requests({ payload }) {
  try {
    const response = yield call(API.requests, payload);
    yield put(ACTION.requestsReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* oldRequests({ payload }) {
  try {
    const response = yield call(API.oldRequests, payload);
    yield put(ACTION.requestsReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* approveRequests({ payload }) {
  try {
    yield call(API.approverRequests, payload);
    showSnackbar("done");
    yield put(
      ACTION.requestsRequest({
        page_number: +History.location.search.split("=")[1] || 1,
        page_size: 10,
      })
    );
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* CandidateApprovals({ payload }) {
  try {
    const response = yield call(API.candidateApprovalsRequests, payload);
    yield put(ACTION.candidateApprovalsReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* approveCandidateApprovalsRequest({ payload }) {
  try {
    yield call(API.approveCandidateApprovalsRequests, payload);
    yield put(
      ACTION.candidateApprovalsRequest({
        page_number: getURLParams("page"),
        page_size: 10,
        approval_status: null,
      })
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* rejectCandidateApprovalsRequests({ payload }) {
  try {
    yield call(API.rejectCandidateApprovalsRequests, payload);
    yield put(
      ACTION.candidateApprovalsRequest({
        page_number: getURLParams("page"),
        page_size: 10,
        approval_status: null,
      })
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* disApproveRequests({ payload }) {
  try {
    yield call(API.disApproveRequests, payload);
    showSnackbar("rejectedSuccessfully");
    History.location.pathname.split("/")[1] === "RequestDetails"
      ? History.goBack()
      : yield put(
          ACTION.requestsRequest({
            page_number: +History.location.search.split("=")[1] || 1,
            page_size: 10,
          })
        );
  } catch (error) {
    console.log(error);
  }
}

export function* getOfferTemplate({ payload }) {
  const offerTemplateType = {
    old: API.getOfferTemplateOldRequests,
    new: API.getOfferTemplatePendingRequests,
  };
  try {
    const response = yield call(
      offerTemplateType[payload.offerType],
      payload.id
    );
    yield put(ACTION.getOfferTemplateReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* saveSignatureImage({ payload }) {
  try {
    const response = yield call(API.uploadSignature, payload);
    yield put(
      ACTION.saveSignatureReceive({
        signature: response.data.data,
        uploadedTime: new Date().getTime(),
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* saveSignedOfferTemplate({ payload }) {
  try {
    const response = yield call(API.sendOfferTemplate, payload);
    yield put(ACTION.saveSignedOfferTemplateReceive(response.data.data));
    History.goBack();
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* isUnifunicActive({ payload }) {
  try {
    const response = yield call(API.getActiveUnifunicSMS);
    yield put(ACTION.recieveIsActiveUnifonic(response.data));

    // update store with unifiunc key
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* saveUploadedAprovalOffer({ payload }) {
  try {
    yield call(API.saveUploadedAprovalOffer, payload);
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* saveSignedContractTemplate({ payload }) {
  try {
    const response = yield call(API.sendContractTemplate, payload);
    yield put(ACTION.saveSignedContractTemplateReceive(response.data.data));
    History.goBack();
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* saveUploadedAprovalContract({ payload }) {
  try {
    yield call(API.saveUploadedAprovalContract, payload);
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* getContractTemplate({ payload }) {
  const TemplateType = {
    old: API.getContractTemplateOldRequests,
    new: API.getContractTemplatePendingRequests,
  };
  try {
    const response = yield call(
      TemplateType[payload.type],
      payload.id
    );
    yield put(ACTION.getContractTemplateReceive(response.data.data));
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}

export function* getCandidateInfoByOffer({ payload }) {
  try {
    const response = yield call(API.candidateInfoByOffer, payload);
    yield put(ACTION.getCandidateInfoByOfferReceive(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* getCandidateInfoByContract({ payload }) {
  try {
    const response = yield call(API.candidateInfoByContract, payload);
    yield put(ACTION.getCandidateInfoByContractReceive(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* RequestsSagasWatch() {
  yield takeLatest(type.REQUESTS_REQUEST, requests);
  yield takeLatest(type.OLD_REQUESTS_REQUEST, oldRequests);
  yield takeLatest(type.APPROVE_REQUESTS_REQUEST, approveRequests);
  yield takeLatest(type.DISAPPROVE_REQUESTS_REQUEST, disApproveRequests);
  yield takeLatest(type.GET_OFFER_TEMPLATE_REQUEST, getOfferTemplate);
  yield takeLatest(type.SAVE_OFFER_SIGNATURE_REQUEST, saveSignatureImage);
  yield takeLatest(type.SAVE_OFFER_TEMPLATE_REQUEST, saveSignedOfferTemplate);
  yield takeLatest(type.CANDIDATE_REQUESTS_REQUEST, CandidateApprovals);
  yield takeLatest(
    type.APPROVE_CANDIDATE_REQUESTS_REQUEST,
    approveCandidateApprovalsRequest
  );
  yield takeLatest(
    type.REJECT_CANDIDATE_REQUESTS_REQUEST,
    rejectCandidateApprovalsRequests
  );
  yield takeLatest(type.GET_ACTIVE_UNIFUNIC, isUnifunicActive);
  yield takeLatest(type.SAVE_UPLOADED_APPROVAL_OFFER, saveUploadedAprovalOffer);
  yield takeLatest(type.SAVE_UPLOADED_APPROVAL_CONTRACT, saveUploadedAprovalContract);
  yield takeLatest(type.SAVE_UPLOADED_APPROVAL_OFFER, saveUploadedAprovalOffer);
  yield takeLatest(type.SAVE_CONTRACT_TEMPLATE_REQUEST, saveSignedContractTemplate);
  yield takeLatest(type.GET_CONTRACT_TEMPLATE_REQUEST, getContractTemplate);
  yield takeLatest(type.GET_CANDIDATE_INFO_BY_OFFER_REQUEST, getCandidateInfoByOffer);
  yield takeLatest(type.GET_CANDIDATE_INFO_BY_CONTRACT_REQUEST, getCandidateInfoByContract);
}
