import * as types from "../types/pipelineSetup";

export const getFLowsRequest = (payload) => ({
  type: types.GET_FLOWS_GRID_REQUEST,
  payload,
});
export const getFlowsReceive = (payload) => ({
  type: types.GET_FLOWS_GRID_RECEIVE,
  payload,
});

export const deleteFlowRequest = (payload) => ({
  type: types.DELETE_FLOW_REQUEST,
  payload,
});
export const deleteFlowReceive = (payload) => ({
  type: types.DELETE_FLOW_RECEIVE,
  payload,
});
export const saveNewFlow = (payload) => ({
  type: types.SAVE_NEW_FLOW_REQUEST,
  payload,
});

export const requestEditFlowDetails = (payload) => ({
  type: types.EDIT_FLOW_DETAILS_REQUEST,
  payload,
});
export const receiveEditFlowDetails = (payload) => ({
  type: types.EDIT_FLOW_DETAILS_RECEIVE,
  payload,
});

export const saveEditedFlow = (payload) => ({
  type: types.SAVE_EDITED_FLOW_REQUEST,
  payload,
});
export const saveSingleFlowStage = (payload) => ({
  type: types.SAVE_SINGLE_FLOW_STAGE_REQUEST,
  payload,
});
export const requestDeleteStage = (payload) => ({
  type: types.DELETE_STAGE_REQUEST,
  payload,
});
export const receiveDeleteStage = (payload) => ({
  type: types.DELETE_STAGE_RECEIVE,
  payload,
});
export const moveApplicantsToStage = (payload) => ({
  type: types.MOVE_APPLICANTS_TO_STAGE_REQUEST,
  payload,
});
export const setDefaultflowRequest = (payload) => ({
  type: types.SET_DEFAULT_FLOW_REQUEST,
  payload,
});
export const disableSendOfferRequest = () => ({
  type: types.DISABLE_SEND_OFFER_REQUEST,
});
export const disableSendOfferReceive = (payload) => ({
  type: types.DISABLE_SEND_OFFER_RECEIVE,
  payload,
});
export const disablePipelineRequest = () => ({
  type: types.DISABLE_PIPELINE_REQUEST,
});
export const disablePipelineReceive = (payload) => ({
  type: types.DISABLE_PIPELINE_RECEIVE,
  payload,
});

export const adsSettingsRequest = () => ({
  type: types.ADS_SETTINGS_REQUEST
})
export const adsSettingsResponse = (payload) => ({
  type: types.ADS_SETTINGS_RESPONSE,
  payload
})