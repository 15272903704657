import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';

export const InputField = ({
  name,
  id,
  className,
  label,
  value,
  error,
  changeHandler,
  helperText,
  isMultiline,
  isRequired,
  max,
  disabled,
  dense,
  icon,
  margin
}) => {
  return (
    <TextField
      disabled={disabled}
      className={`${margin ? margin : 'my-3'} ${className ? className : ''}`}
      name={name}
      id={id}
      type="text"
      label={isRequired ? label + '*' : label}
      inputProps={{ maxLength: max ? max : isMultiline ? 500 : 50 }}
      variant="outlined"
      fullWidth
      value={value}
      error={error}
      margin={dense ? dense : 'normal'}
      helperText={error && helperText}
      onChange={changeHandler}
      multiline={isMultiline}
      rows={isMultiline ? 6 : 1}
      InputProps={icon ? {
        endAdornment: (
          <InputAdornment>
            <IconButton>{icon}</IconButton>
          </InputAdornment>
        ),
      } : {}}
    />
  );
};
