import { axiosInstance } from "./config";

let handlerEnabled = true;

const postContactUs = async (data) => {
    return await axiosInstance.post(
      "/hrcomsite/pages/ContactUs/SendingEmail",
      data,
      { handlerEnabled }
    );
  };
  
  const getContactUs = async () => {
    return await axiosInstance.get("/hrcomsite/pages/ContactUs", {
      handlerEnabled,
    });
  };
export default {
    getContactUs,
    postContactUs
}  