import * as types from "../types/EditEmail";

const INITIAL_STATE = {
  errors: false,
  source: "api",
  data: {
    subject: "",
    title: "",
    body: {
      value: "",
      plain_text: "",
    },
  },
};

export default function EditEmail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.EDIT_EMAIL_REQUEST:
      return {
        ...state,
        ...action.payload,
        data: {
          ...action.payload.data,
          body: action.payload.data.body,
        },
        errors: action.payload.errors,
      };
    case types.GET_TEMPLATE_EMAIL_RECIEVE:
      return {
        ...state,
        source: action.payload.source || state.source,
        data: {
          ...action.payload,
          body: {
            value: action.payload.body,
            plain_text: action.payload.body,
          },
        },
      };
    default:
      return state;
  }
}
