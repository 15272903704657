export const static_features_plans = {
    basic: {
      customize_pipeline_stage: true,
      manage_roles_and_permissions: true,
      e_signatures: true,
      pre_screening_video_assessment: true,
      pre_screening_text_assessment: true,
      live_video_interview: true,
      brand_your_post: true,
      Dedicated_account_manager: false,
      google_search: true,
      send_sms_to_candidates: true,
      single_sign_on: true,
    },
    advance: {
      customize_pipeline_stage: true,
      manage_roles_and_permissions: true,
      e_signatures: true,
      pre_screening_video_assessment: true,
      pre_screening_text_assessment: true,
      live_video_interview: true,
      brand_your_post: true,
      Dedicated_account_manager: false,
      google_search: true,
      send_sms_to_candidates: true,
      single_sign_on: true,
    },
    premium: {
      customize_pipeline_stage: true,
      manage_roles_and_permissions: true,
      e_signatures: true,
      pre_screening_video_assessment: true,
      pre_screening_text_assessment: true,
      live_video_interview: true,
      brand_your_post: true,
      Dedicated_account_manager: true,
      google_search: true,
      send_sms_to_candidates: true,
      single_sign_on: true,
    },
  };