import React from "react";
import { useSelector } from "react-redux";
import messages from "assets/Local/messages";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { ButtonComponent } from "../Controls/Button/Button";
import { IllustrationIcon } from "./SvgImages";
import "./Dialog.scss";

const DialogComponent = ({
  open,
  dialogHeader,
  dialogContent,
  onCloseModal,
  handleDispatchAction,
  isWithImage,
  negativeModalBtnText,
  positiveModalBtnText,
  hasPositiveBtn,
  width,
  hasNegativeAction,
  negativeActionText,
  className
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const message = messages[lang];
  const is_a_ghost_user = JSON.parse(localStorage.getItem("is_ghost"));

  return (
    <Dialog
      open={open}
      maxWidth={width ? width : "sm"}
      fullWidth
      scroll="body"
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={lang === "ar" ? `${className} text-right` : `${className} text-left`}
    >
      <div className="row">
        <div className={`${isWithImage ? "col-md-6 col-10" : "col-12"}`}>
          <DialogTitle id="alert-dialog-title">
            <span className="bold_font">{dialogHeader}</span>
          </DialogTitle>
          <DialogContent>
            <div className="medium_font text-dark">{dialogContent}</div>
            <div
              className={`${
                isWithImage ? "my-4" : "my-4 d-flex justify-content-end"
              }`}
            >
              <ButtonComponent
                variant="outlined"
                classes={hasNegativeAction ? "" : "mx-3"}
                content={
                  negativeModalBtnText
                    ? negativeModalBtnText
                    : message.buttons.no
                }
                handleClick={() => onCloseModal(false)}
              />
              {hasNegativeAction && (
                <ButtonComponent
                variant={is_a_ghost_user ? "contained":"outlined"}
                disabled={is_a_ghost_user}
                classes="mx-3"
                content={
                  negativeActionText
                    ? negativeActionText
                    : message.buttons.no
                }
                  handleClick={() => handleDispatchAction('negative')}
                />
              )}
              {hasPositiveBtn && (
                <ButtonComponent
                  variant="contained"
                  disabled={positiveModalBtnText && is_a_ghost_user}
                  content={
                    positiveModalBtnText
                      ? positiveModalBtnText
                      : message.buttons.yes
                  }
                  handleClick={() => handleDispatchAction('positive')}
                />
              )}
            </div>
          </DialogContent>
        </div>
        {isWithImage && (
          <div className="col-md-6 col-2 d-flex justify-content-end align-items-end">
            <IllustrationIcon />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DialogComponent;
