export const GET_COMPANY_DETAILS_REQUEST = 'GET_COMPANY_DETAILS_REQUEST';
export const GET_COMPANY_DETAILS_RECEIVE = 'GET_COMPANY_DETAILS_RECEIVE ';
export const EDIT_COMPANY_DETAILS_REQUEST = 'EDIT_COMPANY_DETAILS_REQUEST';
export const EDIT_COMPANY_DETAILS_RECEIVE = 'EDIT_COMPANY_DETAILS_RECEIVE ';
export const GET_BRANCHES_REQUEST = 'GET_BRANCHES_REQUEST';
export const GET_BRANCHES_RECEIVE = 'GET_BRANCHES_RECEIVE ';
export const ADD_UPDATE_BRANCH_REQUEST = 'ADD_UPDATE_BRANCH_REQUEST';
export const ADD_UPDATE_BRANCH_RECEIVE = 'ADD_UPDATE_BRANCH_RECEIVE';
export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_RECEIVE = 'DELETE_BRANCH_RECEIVE';
export const GET_COMPANY_PORTAL_SETUP_REQUEST = 'GET_COMPANY_PORTAL_SETUP_REQUEST';
export const GET_COMPANY_PORTAL_SETUP_RECIEVE = 'GET_COMPANY_PORTAL_SETUP_RECIEVE';

export const GET_COMPANY_PROFILE_STEPS_STATUS_REQUEST = 'GET_COMPANY_PROFILE_STEPS_STATUS_REQUEST';
export const GET_COMPANY_PROFILE_STEPS_STATUS_RECIEVE = 'GET_COMPANY_PROFILE_STEPS_STATUS_RECIEVE';


export const PROFILE_STEPS_FEEDBACK_REQUEST = 'PROFILE_STEPS_FEEDBACK_REQUEST';
export const PROFILE_STEPS_FEEDBACK_RECIEVE = 'PROFILE_STEPS_FEEDBACK_RECIEVE';