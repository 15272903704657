import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.scss";

function TextEditor({
  editorInitialState,
  customClasses,
  placeholder,
  onEditorStatePlainText,
  onEditorStateChange,
}) {
  const _getInitialHTML = (value) => {
    const contentBlocks = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    return contentState;
  };
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(_getInitialHTML(""))
  );
  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(_getInitialHTML(editorInitialState))
    );
  }, [editorInitialState]);
  const handleEditorStateChange = (value) => {
    setEditorState(value);
    onEditorStateChange(
      draftToHtml(convertToRaw(value.getCurrentContent())),
      value.getCurrentContent().getPlainText()
    );
  };
  const toolBar = {
    options: ["inline", "list"],
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline"],
    },
    list: {
      inDropdown: false,
    },
  };
  return (
    <Editor
      editorState={editorState}
      key="editor"
      toolbarClassName="toolbar-custom"
      wrapperClassName={`wrapper-custom marginb-5 ${customClasses || ""}`}
      editorClassName="editor-custom px-3"
      toolbar={toolBar}
      placeholder={placeholder || ""}
      onEditorStateChange={handleEditorStateChange}
    />
  );
}

export default TextEditor;
