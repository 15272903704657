import { axiosInstance } from "./config";

let handlerEnabled = true;

const addDepartment = async data => {
    return await axiosInstance.post(`/admin/departments`, data, { handlerEnabled });
};

const getAllDepartments = async pageNumber => {
    let params = {
        page_number: pageNumber ? pageNumber : 1,
        page_size: 10
    };
    return await axiosInstance.get(`/admin/departments`, {
        params,
        handlerEnabled
    });
};


const getDepartment = async id => {
    return await axiosInstance.get(`/admin/departments/${id}`, { handlerEnabled });
};

const editDepartment = async data => {
    return await axiosInstance.put(`/admin/departments/${data.id}`, data, {
        handlerEnabled
    });
};

const deleteDepartment = async id => {
    return await axiosInstance.delete(`/admin/departments/${id}`, {
        handlerEnabled
    });
};

export default {
    addDepartment,
    getAllDepartments,
    getDepartment,
    editDepartment,
    deleteDepartment
};
