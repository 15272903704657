import * as types from "../types/Integrations";

const INITIAL_STATE = {
  integrations: {},
  unifonicIntegration: {},
  office365Integration: {},
  office365UsersCalendar: [],
  integrationStatus: {},
  integrationSnackbar: {
    successSnackbarOpen: false,
    successSnackbarMessage: "",
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECIEVE_INTEGRATION_STATUS:
      return { ...state, integrationStatus: { ...action.payload } };
    case types.INTEGRATION_RECEIVE:
      return { ...state, integrations: { ...action.payload } };
    case types.UNIFONIC_INTEGRATION_RECEIVE:
      return { ...state, unifonicIntegration: { ...action.payload } };
    case types.OFFICE365_INTEGRATION_RECEIVE:
      return { ...state, office365Integration: { ...action.payload } };
    case types.GET_USER_CALENDAR_RECIEVE:
      return { ...state, office365UsersCalendar: action.payload };
    case types.SHOW_SNACKBAR_INTEGRATION:
      return {
        ...state,
        integrationSnackbar: {
          successSnackbarOpen: true,
          successSnackbarMessage: action.payload.message,
          snackType: action.payload.snackType,
        },
      };
    case types.HIDE_SNACKBAR_INTEGRATION:
      return {
        ...state,
        integrationSnackbar: {
          successSnackbarOpen: false,
          successSnackbarMessage: false
        },
      };
    default:
      return state;
  }
};
