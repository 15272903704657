import { all } from "redux-saga/effects";
import { AgentSagasWatch } from "./AgentSagas";
import { CandidatePortalSetupSagasWatch } from "./candidatePortalSetup";
import { ManpowerRequestSagaWatch } from "./createNewRequest";
import { JobRequisitionSetupSagasWatch } from "./jobRequisitionSetupSagas";
import { JobTitleSagasWatch } from "./jobTitlesSagas";
import { LookupsSagasWatch } from "./lookupSagas";
import { ManageCandidateSagasWatch } from "./manageCandidates";
import { MyInterviewSagasWatch } from "./MyInterviews";
import { RequestsSagasWatch } from "./requests";
import { SalaryGradeSagasWatch } from "./salaryGradeSagas";
import { SocialMediaSetupSagasWatch } from "./socialMediaSetupSagas";
import { UploadExcelSagasWatch } from "./uploadExcel";
import { VideoInterviewSagasWatch } from "./videoInterview";
import { ViewAllRequestsSagasWatch } from "./viewAllRequestsSagas";
import { WizardSetupSagasWatch } from "./wizardSetup";
import { AuthSagasWatch } from "./Auth";
import { JobRolesSagasWatch } from "./jobRoles";
import { CompanySetupSagasWatch } from "./CompanySetup";
import { ApplicantProfileSagasWatch } from "./applicantProfile";
import { PipelineSetupSagasWatch } from "./pipelineSetup";
import { dashboardSagasWatch } from "./dashboard";
import { interviewEvaluationSetupSagasWatch } from "./interviewEvaluationSetup";
import { PaymentSagasWatch } from "./payment";
import { OfferTemplateSetupSagasWatch } from "./offerTemplateSetup";
import { CandidateTalentPoolWatch } from "./candidateTalentPool";
import { ContactUsSagasWatch } from "./contactUs";
import { InvoicesSagasWatch } from "./invoices";
import { IntegrationSagasWatch } from "./Integrations";
import { EditEmailSagasWatch } from "./EditEmail";
import { EmailTemplateSagasWatch } from "./emailTemplates";
import { NotificationSagasWatch } from "./notification";
import { RejectionReasonsSagasWatch } from "./rejectionReasons";
import { ContractTemplateSetupSagasWatch } from "./ContractTemplate";
import { DepartmentsSagasWatch } from "./DepartmentsSetupSagas";
import { PreOfferDocsSagasWatch } from "./PreOfferSetupSagas";
export function* watchAll() {
  yield all([
    AuthSagasWatch(),
    JobRolesSagasWatch(),
    AgentSagasWatch(),
    CandidatePortalSetupSagasWatch(),
    ManpowerRequestSagaWatch(),
    JobRequisitionSetupSagasWatch(),
    JobTitleSagasWatch(),
    LookupsSagasWatch(),
    ManageCandidateSagasWatch(),
    MyInterviewSagasWatch(),
    RequestsSagasWatch(),
    SalaryGradeSagasWatch(),
    SocialMediaSetupSagasWatch(),
    UploadExcelSagasWatch(),
    VideoInterviewSagasWatch(),
    ViewAllRequestsSagasWatch(),
    WizardSetupSagasWatch(),
    CompanySetupSagasWatch(),
    ApplicantProfileSagasWatch(),
    PipelineSetupSagasWatch(),
    dashboardSagasWatch(),
    PaymentSagasWatch(),
    OfferTemplateSetupSagasWatch(),
    CandidateTalentPoolWatch(),
    ContactUsSagasWatch(),
    InvoicesSagasWatch(),
    IntegrationSagasWatch(),
    EditEmailSagasWatch(),
    EmailTemplateSagasWatch(),
    NotificationSagasWatch(),
    RejectionReasonsSagasWatch(),
    ContractTemplateSetupSagasWatch(),
    DepartmentsSagasWatch(),
    PreOfferDocsSagasWatch(),
    interviewEvaluationSetupSagasWatch()
  ]);
}
