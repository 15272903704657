import React, { useState, useRef } from "react";
import { injectIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
import "./UploadExcelFile.scss";
import { LinkComponent } from "components/Link/Link";

const UploadExcelFile = ({
  onUploadFile,
  intl: { messages },
  tooltip,
  wizardStep,
  downloadFileURL,
  addApplicantFromExcel,
  is_a_ghost_user,
  isGrid,
  disable_add_agent,
}) => {
  const is_view_only = JSON.parse(localStorage.getItem("is_view_only"));
  const fileInput = useRef();
  const [fileTypeErr, setFileTypeErr] = useState(false);
  const [fileSizeErr, setFileSizeErr] = useState(false);
  const [fileName, setFileName] = useState("");
  const previewFile = (e) => {
    let fileSize = e.target.files[0].size / 1024 / 1024;
    let fileExtension = e.target.files[0].name.split(".").pop();
    let isFileCorrect = false;
    if (
      fileExtension === "xlsx" ||
      fileExtension === "csv" ||
      fileExtension === "xls"
    ) {
      if (fileSize > 3) {
        handleUploadFile(false, true, null);
        isFileCorrect = false;
      } else {
        isFileCorrect = true;
      }
    } else {
      handleUploadFile(true, false, null);
      isFileCorrect = false;
    }
    if (isFileCorrect) {
      setFileName(e.target.files[0].name);
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
        let resultFile = reader.result;
        handleUploadFile(false, false, resultFile);
      };
    }
    fileInput.current.value = "";
  };

  const handleUploadFile = (typeErr, sizeErr, file) => {
    setFileSizeErr(sizeErr);
    setFileTypeErr(typeErr);
    onUploadFile(file, wizardStep);
  };

  return (
    <div className="d-flex align-items-center excel-uploader flex-column">
      <div className="d-flex flex-column align-items-center">
        <span className="file-uploader text-center">
          <Tooltip title={tooltip} disabled={disable_add_agent || is_view_only || is_a_ghost_user}>
            <label
              className={`upload-btn uploade-image__btn position-relative borderRaduis
               medium_font mb-0 px-4 py-2 ${(disable_add_agent || is_a_ghost_user) ? "disabled is-ghost" : ""}`}
            >
              {messages.buttons.uploadExcel}
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={(e) => previewFile(e)}
                disabled={
                  is_view_only ||
                  disable_add_agent ||
                  is_a_ghost_user
                }
                ref={fileInput}
              />
            </label>
          </Tooltip>
        </span>
        <Tooltip title={tooltip} disabled={disable_add_agent || is_view_only}>
          <a
            href={downloadFileURL}
            download
            target="_blank"
            className="mt-3"
            disabled={disable_add_agent || is_view_only}
          >
            <p className="download-sample d-inline medium_font primary_color mt-2">
              {messages.buttons.downloadSample}
              <GetAppIcon />
            </p>
          </a>
        </Tooltip>
      </div>
      <div>
        {fileTypeErr && (
          <small className="err-msg py-3">
            {messages.errors.wizardFileTypeErr}
          </small>
        )}
        {fileSizeErr && (
          <small className="err-msg py-3">
            {messages.errors.wizardFileSizeErr}
          </small>
        )}
        {!isGrid && <small>{fileName}</small>}
      </div>
    </div>
  );
};

const UploadExcelFileComponent = injectIntl(UploadExcelFile);

export default UploadExcelFileComponent;

