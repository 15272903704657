import * as types from "../types/rejectionReasons";
export const editRejectionReasonRequest = (payload) => {
  return {
    type: types.EDIT_REJECTION_REASON_REQUEST,
    payload,
  };
};
export const editRejectionReasonRecieve = (payload) => {
  return {
    type: types.EDIT_REJECTION_REASON_RECIEVE,
    payload,
  };
};
export const getRejectionReasonsRequest = (payload) => {
  return {
    type: types.GET_REJECTION_REASONS_REQUEST,
    payload,
  };
};
export const getRejectionReasonsRecieve = (payload) => {
  return {
    type: types.GET_REJECTION_REASONS_RECIEVE,
    payload,
  };
};
export const getRejectionReasonRequest = (payload) => {
  return {
    type: types.GET_REJECTION_REASON_REQUEST,
    payload,
  };
};
export const getRejectionReasonRecieve = (payload) => {
  return {
    type: types.GET_REJECTION_REASON_RECIEVE,
    payload,
  };
};
export const addRejectionReasonRequest = (payload) => {
  return {
    type: types.ADD_REJECTION_REASON_REQUEST,
    payload,
  };
};
export const addRejectionReasonRecieve = (payload) => {
  return {
    type: types.ADD_REJECTION_REASON_RECIEVE,
    payload,
  };
};

export const deleteRejectionReasonRequest = (payload) => {
  return {
    type: types.DELETE_REJECTION_REASON_REQUEST,
    payload,
  };
};
export const deleteRejectionReasonsRecieve = (payload) => {
  return {
    type: types.DELETE_REJECTION_REASON_RECIEVE,
    payload,
  };
};
