//INPROGRESS
export const REQUEST_REQUESTS_IN_PROGRESS = "REQUEST_REQUESTS_IN_PROGRESS";
export const RECEIVE_REQUESTS_IN_PROGRESS = "RECEIVE_REQUESTS_IN_PROGRESS";
export const DELETE_REQUEST_IN_PROGRESS = "DELETE_REQUEST_IN_PROGRESS";

//PUBLISHED
export const PUBLISHED_JOBS_REQUEST = "PUBLISHED_JOBS_REQUEST";
export const PUBLISHED_JOBS_RECIEVE = "PUBLISHED_JOBS_RECIEVE";

//CLOSED
export const REQUESTS_CLOSED_REQUEST = "REQUESTS_CLOSED_REQUEST";
export const REQUESTS_CLOSED_RECEIVE = "RECEIVES_CLOSED_RECEIVE";
// ALL JOB OPENINGS
export const REQUEST_ALL_JOB_OPENINGS = "REQUEST_ALL_JOB_OPENINGS";
export const RECEIVE_ALL_JOB_OPENINGS = "RECEIVE_ALL_JOB_OPENINGS";

export const DELAY_CLOSING_DATE_REQUSET = "DELAY_CLOSING_DATE_REQUSET";
export const DELAY_CLOSING_DATE_RECIEVE = "DELAY_CLOSING_DATE_RECIEVE";

export const TOGGLE_ARCHIVE_REQUSET="TOGGLE_ARCHIVE_REQUSET"
export const TOGGLE_ARCHIVE_RECIEVE="TOGGLE_ARCHIVE_RECIEVE"

export const APPROVAL_SETUP_STATUS_FLOW_REQUEST =
  "APPROVAL_SETUP_STATUS_FLOW_REQUEST";
export const APPROVAL_SETUP_STATUS_FLOW_RECEIVE =
  "APPROVAL_SETUP_STATUS_FLOW_RECEIVE";

export const COMPANY_INTERVIEW_MINUTES_REQUEST =
  "COMPANY_INTERVIEW_MINUTES_REQUEST";
export const COMPANY_INTERVIEW_MINUTES_RECEIVE =
  "COMPANY_INTERVIEW_MINUTES_RECEIVE";

export const CHECK_JOB_REQUESTS_PLAN_LIMIT_REQUEST =
  "CHECK_JOB_REQUESTS_PLAN_LIMIT_REQUEST";
export const CHECK_JOB_REQUESTS_PLAN_LIMIT_RECEIVE =
  "CHECK_JOB_REQUESTS_PLAN_LIMIT_RECEIVE";

export const CLOSE_JOB_OPENING_REQUEST = "CLOSE_JOB_OPENING_REQUEST";

export const HOLD_PUBLISHED_JOBS_REQUEST = "HOLD_PUBLISHED_JOBS_REQUEST";
export const HOLD_PUBLISHED_JOBS_RECEIVE = "HOLD_PUBLISHED_JOBS_RECEIVE";

export const REOPEN_HOLDED_JOBS_REQUEST = "REOPEN_HOLDED_JOBS_REQUEST";
export const REOPEN_HOLDED_JOBS_RECEIVE = "REOPEN_HOLDED_JOBS_RECEIVE";

export const GET_HOLDED_JOBS_REQUEST = "GET_HOLDED_JOBS_REQUEST";
export const GET_HOLDED_JOBS_RECEIVE = "GET_HOLDED_JOBS_RECEIVE";

export const DUPLICATE_JOB_POST_REQUEST = "DUPLICATE_JOB_POST_REQUEST";
export const DUPLICATE_JOB_POST_RECEIVE = "DUPLICATE_JOB_POST_RECEIVE";

export const FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_RECIEVE = "FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_RECIEVE";
export const FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_REQUEST = "FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_REQUEST";



export const EXPORT_EXCEL_ALL_REQUEST = 'EXPORT_EXCEL_ALL_REQUEST'
export const EXPORT_EXCEL_ALL_RECIEVE = 'EXPORT_EXCEL_ALL_RECIEVE'
export const EXPORT_CVS_ALL_REQUEST = 'EXPORT_CVS_ALL_REQUEST'
export const EXPORT_CVS_ALL_RECIEVE = 'EXPORT_CVS_ALL_RECIEVE'



export const EXPORT_EXCEL_PENDING_REQUEST = 'EXPORT_EXCEL_PENDING_REQUEST'
export const EXPORT_EXCEL_PENDING_RECIEVE = 'EXPORT_EXCEL_PENDING_RECIEVE'
export const EXPORT_CVS_PENDING_REQUEST = 'EXPORT_CVS_PENDING_REQUEST'
export const EXPORT_CVS_PENDING_RECIEVE = 'EXPORT_CVS_PENDING_RECIEVE'

export const EXPORT_EXCEL_PUBLISHED_REQUEST = 'EXPORT_EXCEL_PUBLISHED_REQUEST'
export const EXPORT_EXCEL_PUBLISHED_RECIEVE = 'EXPORT_EXCEL_PUBLISHED_RECIEVE'
export const EXPORT_CVS_PUBLISHED_REQUEST = 'EXPORT_CVS_PUBLISHED_REQUEST'
export const EXPORT_CVS_PUBLISHED_RECIEVE = 'EXPORT_CVS_PUBLISHED_RECIEVE'

export const EXPORT_EXCEL_CLOSED_REQUEST = 'EXPORT_EXCEL_CLOSED_REQUEST'
export const EXPORT_EXCEL_CLOSED_RECIEVE = 'EXPORT_EXCEL_CLOSED_RECIEVE'
export const EXPORT_CVS_CLOSED_REQUEST = 'EXPORT_CVS_CLOSED_REQUEST'
export const EXPORT_CVS_CLOSED_RECIEVE = 'EXPORT_CVS_CLOSED_RECIEVE'


export const EXPORT_EXCEL_HOLD_REQUEST = 'EXPORT_EXCEL_HOLD_REQUEST'
export const EXPORT_EXCEL_HOLD_RECIEVE = 'EXPORT_EXCEL_HOLD_RECIEVE'
export const EXPORT_CVS_HOLD_REQUEST = 'EXPORT_CVS_HOLD_REQUEST'
export const EXPORT_CVS_HOLD_RECIEVE = 'EXPORT_CVS_HOLD_RECIEVE'