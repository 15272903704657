import { call, put } from "redux-saga/effects";
import API from "../../network/apis/salaryGradeAPI";
import * as ACTION from "../actions/salaryGrade";
import store from "../../store";
import history from "../../routes/History";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/SalaryGrade";

export function* getSalaryGrade(action) {
  try {
    const response = yield call(API.getSalaryGrade, action.payload);
    yield put(ACTION.receiveGetSalaryGrade(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* addEditSalaryGrade({ payload }) {
  try {
    const response = yield call(API.addEditSalaryGrade, payload.data);
    yield put(ACTION.receiveAddEditSalaryGrade(response.data));
    showSnackbar("savedSuccessfuly");
    payload.type !== "saveNew" && history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deleteSalaryGrade(action) {
  try {
    const response = yield call(API.deleteSalaryGrade, action.payload);
    yield put(ACTION.receiveDeleteSalaryGrade(response.data));
    store.dispatch(ACTION.requestGetSalaryGrade());
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* SalaryGradeSagasWatch() {
  yield takeLatest(type.GET_SALARY_GRADE_REQUEST, getSalaryGrade);
  yield takeLatest(type.ADD_EDIT_SALARY_GRADE_REQUEST, addEditSalaryGrade);
}
