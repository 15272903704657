import * as types from "../types/advertisementSetup";

const INITIAL_STATE = {
    socialMediaPlaceholder : [] ,
    socialMediaSettings : {} ,
    addEditSocialMediaSettings : {}
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.SOCIAL_MEDIA_PLACEHOLDER_RECEIVE:
            return { ...state, socialMediaPlaceholder : [...action.payload]};
        case types.GET_SOCIAL_MEDIA_SETTINGS_RECEIVE:
            return { ...state, socialMediaSettings : {...action.payload}};
        case types.ADD_EDIT_SOCIAL_MEDIA_RECEIVE:
            return { ...state, addEditSocialMediaSettings :{...action.payload}};
        default: return state;
    }
}