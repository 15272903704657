export const ADD_PREOFFER_REQUEST = 'ADD_PREOFFER_REQUEST';
export const ADD_PREOFFER_RECEIVE = 'ADD_PREOFFER_RECEIVE';

export const GET_SINGLE_PREOFFER_REQUEST = 'GET_SINGLE_PREOFFER_REQUEST';
export const GET_SINGLE_PREOFFER_RECEIVE = 'GET_SINGLE_PREOFFER_RECEIVE';

export const GET_PREOFFER_LIST_REQUEST = 'GET_PREOFFER_LIST_REQUEST';
export const GET_PREOFFER_LIST_RECEIVE = 'GET_PREOFFER_LIST_RECEIVE';

export const DELETE_PREOFFER_REQUEST = 'DELETE_PREOFFER_REQUEST';
export const DELETE_PREOFFER_RECEIVE = 'DELETE_PREOFFER_RECEIVE';

export const EDIT_PREOFFER_REQUEST = 'EDIT_PREOFFER_REQUEST';
export const EDIT_PREOFFER_RECEIVE = 'EDIT_PREOFFER_RECEIVE';