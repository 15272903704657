import {axiosInstance} from './config';

let handlerEnabled = true;

const getSalaryGrade = async (pageNumber) => {
    let params = {
        page_number : pageNumber ? pageNumber : 1 ,
        page_size : 10
    }
    return await axiosInstance.get(`/admin/salary_grade/get`, {params ,handlerEnabled});
}

const getSalaryGradeLookup = async () => {
    return await axiosInstance.get(`/admin/salary_grade/Lookup/get`, {handlerEnabled});
}

const addEditSalaryGrade = async (data) => {
    return await axiosInstance.put(`/admin/salary_grade/add_edit`, data , {handlerEnabled});
}

const deleteSalaryGrade = async (data) => {
    return await axiosInstance.delete(`/admin/salary_grade/delete`, data , {handlerEnabled});
}

export default {
    getSalaryGrade ,
    getSalaryGradeLookup ,
    addEditSalaryGrade ,
    deleteSalaryGrade
};