import { axiosInstance } from "./config";
var qs = require("qs");

let handlerEnabled = true;

const getCompanyIntegrations = async () => {
  return await axiosInstance.get(
    `/admin/companyIntegration/getCompanyIntegrationsActivation`,
    { handlerEnabled }
  );
};
const getCompanyIntegrationsStatus = async () => {
  return await axiosInstance.get(
    `/admin/companyIntegration/status`,
    { handlerEnabled }
  );
};

const getCompanyUnifonicIntegration = async () => {
  return await axiosInstance.get(
    `/admin/companyIntegration/getCompanyUnifonicIntegration`,
    { handlerEnabled }
  );
};

const addEditCompanyUnifonicIntegration = async (data) => {
  return await axiosInstance.put(
    `/admin/companyIntegration/addEdit_CompanyUnifonicIntegrationData`,
    data,
    {
      handlerEnabled,
    }
  );
};

const getCompanyOffice365Integration = async () => {
  return await axiosInstance.get(
    `/admin/companyIntegration/getCompanyMicrosoftCalendarIntegration`,
    { handlerEnabled }
  );
};
const addEditCompanyOffice365Integration = async (data) => {
  return await axiosInstance.put(
    `/admin/companyIntegration/addEdit_CompanyMicrosoftCalendarIntegrationData`,
    data,
    {
      handlerEnabled,
    }
  );
};
const getUserCalendarRequest = async (data) => {
  return await axiosInstance.get(
    `/admin/job_requests/${data.job_request_id}/getUserCalendar`,
    {
      params: data.params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    },
    {
      handlerEnabled,
    }
  );
};
export default {
  getCompanyIntegrations,
  getCompanyUnifonicIntegration,
  addEditCompanyUnifonicIntegration,
  addEditCompanyOffice365Integration,
  getCompanyOffice365Integration,
  getUserCalendarRequest,
  getCompanyIntegrationsStatus
};
