/**
 *  Redirections from company Dashoboard to HRCOM Website
 *  Redirections from company Dashoboard to HRCOM Candidate Portal for specific Company
 */
import store from "../store";
import { getDomain } from "./shared";

const getLocale = () => {
  return store.getState().locale?.lang;
};
export const getCandidatePortalHome = () => {
  if (window.location.href.includes("stage.hrcom.site")) {
    return `https://${getDomain()}-candidate.stage.hrcom.site`;
  }else if(window.location.href.includes("hrcom.co")){
    return `https://${getDomain()}.candidates.hrcom.co`;
  }else {
    const domainWithPrefix = process.env.REACT_APP_URL_CONFIG.includes("stage")
      ? `${getDomain()}-candidate-stage.`
      : `${getDomain()}.candidate.`;

    return `https://${domainWithPrefix}${process.env.REACT_APP_BASE_URL.substring(
      process.env.REACT_APP_BASE_URL.indexOf("hrcom")
    )}`;
  }
};
export const adminPortalRedirect = () => {
  //staging
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return adminPortalRedirectWithParams(
      "https://admin-stage-hrcom.ibtikar.sa"
    );
  }
  // new stage
  else if (window.location.href.includes("stage.hrcom.site")) {
    return adminPortalRedirectWithParams("https://admin-stage.hrcom.site");
  }
  //prelive
  else if (window.location.href.includes("hrcom.site")) {
    return adminPortalRedirectWithParams("http://admin.hrcom.site");
  }
  //production
  else if (window.location.href.includes("hrcom.io")) {
    return adminPortalRedirectWithParams("http://hrcom.io");
  }
  else if(window.location.href.includes('localhost')) {
    return '/login'
  }
  //adaa live
  else if (window.location.href.includes("hrcom.co")) {
    return adminPortalRedirectWithParams("http://hrcom.co");
  }
};
const adminPortalRedirectWithParams = (url) => {
  return `${url}/redirect-company?locale=${getLocale()}&domain=${getDomain()}`;
};
export const getEnv = () => {
  //stage
  if (window.location.href.includes("stage.hrcom.site")) {
    return ".hrcom.site";
  }
  //prelive
  else if (window.location.href.includes("hrcom.site")) {
    return ".hrcom.site";
  }
  //production
  else if (window.location.href.includes("hrcom.io")) {
    return ".hrcom.io";
  }
  //adaa live
  else if (window.location.href.includes("hrcom.co")) {
    return ".hrcom.co";
  }
};
export const jobSeekerUrl = () => {
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return "https://jobseeker-stage.hrcom.ibtikar.sa";
  } else if (window.location.href.includes("stage.hrcom.site")) {
    return "https://jobseeker.stage.hrcom.site";
  } else if (window.location.href.includes("hrcom.site")) {
    return "https://jobseeker.hrcom.site";
  } else if (window.location.href.includes("hrcom.io")) {
    return `https://jobseeker.hrcom.io`;
  }
};
