import * as types from "../types/CompanySetup";

const INITIAL_STATE = { steps:null};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_COMPANY_DETAILS_RECEIVE:
      return { ...state, ...action.payload };
    case types.EDIT_COMPANY_DETAILS_RECEIVE:
      return { ...state, ...action.payload };
      case types.GET_COMPANY_PROFILE_STEPS_STATUS_RECIEVE:
        return { ...state, steps:action.payload };
    default:
      return state;
  }
};
