import React from "react";
import SideMenu from "components/SideMenu/SideMenu";
import Navbar from "../../../components/Navbar/Navbar";
import PopupNotification from "../../../components/popupNotifiaction/popupNotifiaction";
import "./MainLayout.scss";
import { connect, useSelector } from "react-redux";
import { setClickedPageIndex } from "../../../store/actions/clickedPage";
import favicon from "../../../assets/images/favicon-32x32.png";
import { Helmet } from "react-helmet";
import History from "routes/History";
import FreeTrailCountdown from "components/FreeTrailCountdown/FreeTrailCountdown";
import { setNotificationSeenRequest } from "../../../store/actions/notification";
import { notAllowReminderRequest } from "../../../store/actions/agents";
import Alert from "@material-ui/lab/Alert";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import RemindSyncModal from "./RemindSyncModal";
import StartYourJournyMenu from "components/StartYourJournyMenu/StartYourJournyMenu";
import { isSuperAdmin } from 'utils/Permissions';
import { getDecodedAccessToken } from "utils/shared";
import CongratulationModal from "components/ManageCandidates/CongratulationModal/CongratulationModal";

import {
  verifyEmailRequest,
  verifyEmailRecieve,
} from "store/actions/dashboard";
class MainLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      pathname: "/",
      popupNotification: false,
      windowLocation: window.location.href,
      isModalOpen: false,
      isModalCongratulationOpen: false,
      logo: null,
    };
    this.handleNotificationBellClick =
      this.handleNotificationBellClick.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleNotAllowReminder = this.handleNotAllowReminder.bind(this);
  }

  componentDidMount() {
    const { setClickedPageIndex,steps } = this.props;
    setClickedPageIndex({ index: History.location.pathname });
    this.setState({ logo: localStorage.getItem("company_image_url") });

  }

  handleModalState = (state) => {
    this.setState({ ...this.state, isModalCongratulationOpen: false });
  }
  componentDidUpdate(prevProps) {
    const { setClickedPageIndex ,steps} = this.props;
    if (History.location.pathname !== this.state.pathname) {
      this.setState({ pathname: History.location.pathname });
      setClickedPageIndex({ index: History.location.pathname });
   
    }
    History.listen((location, action) => {
      this.setState({ ...this.state, popupNotification: false });
   
    });
    if(steps?.progress_percent!==prevProps.steps?.progress_percent){
      if(steps?.progress_percent==100)
      this.setState({ ...this.state, isModalCongratulationOpen: true }); 
      if(window.location.pathname=="/manpowerRequests"){
        this.setState({ ...this.state, isModalCongratulationOpen: false });
      }
    }
 
  }

  handleNotificationBellClick() {
    if (!this.state.popupNotification) {
      this.props.setNotificationSeenRequest();
    }

    this.setState({
      ...this.state,
      popupNotification: !this.state.popupNotification,
    });
  }

  handleClickAway() {
    this.setState({ ...this.state, popupNotification: false });
  }
  closeModal() {
    localStorage.setItem("show_email_sync_reminder", false);
    this.setState({ ...this.state, isModalOpen: false });
  }
  handleNotAllowReminder() {
    localStorage.setItem("show_email_sync_reminder", false);
    this.setState({ ...this.state, isModalOpen: false });
    this.props.notAllowReminderRequest();
  }
  render() {
    const {
      sideMenu,
      intl: { messages },
      userProfile,
      steps
    } = this.props;
    const companyLogo = localStorage.getItem("company_image_url");
    const is_wizard = JSON.parse(localStorage.getItem("is_wizard"));
    const is_company_profile_complete =
      localStorage.getItem("is_company_profile_complete") &&
      JSON.parse(
        localStorage.getItem("is_company_profile_complete").toLowerCase()
      );
    const show_email_sync_reminder =
      localStorage.getItem("show_email_sync_reminder") &&
      JSON.parse(
        localStorage.getItem("show_email_sync_reminder").toLowerCase()
      ) &&
      !JSON.parse(localStorage.getItem("is_view_only"));
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={companyLogo === "null" ? favicon : companyLogo}
            sizes="16x16"
          />
        </Helmet>
        {(this.props.wizardStepper === 2 || is_wizard) && <SideMenu />  }
        {(this.props.wizardStepper === 2 || is_wizard) &&!this.props.login?.is_completed_profile_steps&&isSuperAdmin()&& window.location.pathname!=="/"&&steps?.progress_percent!=100&&<StartYourJournyMenu />  }

        <Navbar
          handleNotificationBellClick={this.handleNotificationBellClick}
          handleClickAway={this.handleClickAway}
        />
        <div
          className="main"
          id="main"
          style={
            this.props.wizardStepper === 2 || is_wizard
              ? {
                  marginLeft: sideMenu.mainMrginLeft,
                  marginRight: sideMenu.mainMarginRight,
                  ...(sideMenu.backgroundColor && {
                    backgroundColor: sideMenu.backgroundColor,
                  }),
                  marginTop: sideMenu.mainMarginTop,
                }
              : {
                  marginLeft: 0,
                  marginRight: 0,
                }
          }
        >
        
          {steps?.progress_percent!=100&& isSuperAdmin()&&is_wizard && (
            <Link to="/">
              <Alert severity="warning" className="mb-3">
                {messages.shared.Completerequirementjourney}
              </Alert>
            </Link>
          )}
             {!userProfile?.is_agent_email_verified && is_wizard && (
         
              <Alert severity="warning" className="mb-3 pointer" onClick={()=>{
                const decodedToken = localStorage.getItem("token")
                ? getDecodedAccessToken(localStorage.getItem("token"))
                : null;
                if (decodedToken) {
                  
                    this.props.verifyEmailRequest({
                      user_id: decodedToken.sub,
                    }
                  );
                }
              }}>
                {messages.shared.Verify}
              </Alert>
          
          )}
          <FreeTrailCountdown />
          {this.props.children}
          {is_company_profile_complete && (
            <RemindSyncModal
              show_email_sync_reminder={show_email_sync_reminder}
              messages={messages}
              closeModal={this.closeModal}
              handleNotAllowReminder={this.handleNotAllowReminder}
            />
          )}
          {steps?.progress_percent==100&&!userProfile?.is_completed_profile_steps_feedback  &&<CongratulationModal
            isOpen={this.state.isModalCongratulationOpen}
            handleModalState={this.handleModalState}
          /> }
         
        </div>
        {this.state.popupNotification && <PopupNotification />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sideMenu: state.sideMenu,
    wizardStepper: state.wizardStep.step,
    login:state.login,
    userProfile:state.userProfile,
    steps: state.company.steps

  };
};

export default connect(mapStateToProps, {
  setClickedPageIndex,
  setNotificationSeenRequest,
  notAllowReminderRequest,
  verifyEmailRequest
})(injectIntl(MainLayout));
