import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/emailTemplates";
import API from "../../network/apis/emailTemplate";
import { dispatchError, showSnackbar } from "utils/shared";
import * as ACTION from "../actions/emailTemplates";
import History from "routes/History";

export function* emailTemplatesRequest({ payload }) {
  try {
    const response = yield call(API.getEmailTemplatesRequest, payload);
    yield put(ACTION.recieveCustomizeEmailTemplate(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* addEmailTemplateRequest({ payload }) {
  try {
    yield call(API.addEmailTemplateRequest, payload);
    showSnackbar("savedSuccessfuly");
    History.push("/emailTemplates?page=1");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* editEmailTemplateRequest({ payload }) {
  try {
    const response = yield call(
      !payload.editTemplate
        ? API.getEmailTemplateRequest
        : API.editEmailTemplateRequest,
      payload
    );
    yield put(ACTION.editTemplateEmailRecieve(response.data.data));
    if (payload.editTemplate) History.push("/emailTemplates?page=1");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* deleteEmailTemplateRequest({ payload }) {
  try {
    yield call(API.deleteEmailTemplateRequest, payload);
    showSnackbar("done");
    yield call(emailTemplatesRequest, {
      payload: { pageNumber: 1, page_size: 11 },
    });
    yield put(ACTION.EmailTemplateIsDeleted(true));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* emailTemplatePlaceholdersRequest() {
  try {
    const response = yield call(
      API.CustomizedEmailTemplatesPlaceholdersRequest
    );
    yield put(
      ACTION.CustomizedEmailTemplatesPlaceholdersRecieve(response.data.data)
    );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getCustomizeEmailTemplateList() {
  try {
    const response = yield call(API.getCustomizeEmailTemplateList);
    yield put(ACTION.getCustomizeEmailTemplateListRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getCustomizeEmailTemplateRequest({ payload }) {
  try {
    const response = yield call(API.getCustomizeEmailTemplateRequest, payload);
    yield put(ACTION.getCustomizeEmailTemplateRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* backToDefaultRequest({ payload }) {
  try {
    const response = yield call(API.backToDefaultRequest, payload);
    yield put(ACTION.backToDefaultRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* EmailTemplateSagasWatch() {
  yield takeEvery(
    types.CUSTOMIZE_EMAIL_TEMPLATE_REQUEST,
    emailTemplatesRequest
  );
  yield takeEvery(
    types.BACK_TO_DEFAULT_REQUEST,
    backToDefaultRequest
  );
  yield takeEvery(types.ADD_EMAIL_TEMPLATE_REQUEST, addEmailTemplateRequest);
  yield takeEvery(types.EDIT_EMAIL_TEMPLATE_REQUEST, editEmailTemplateRequest);
  yield takeEvery(
    types.DELETE_EMAIL_TEMPLATE_REQUEST,
    deleteEmailTemplateRequest
  );
  yield takeEvery(
    types.GET_EMAIL_TEMPLATE_PLACEHOLDERS_REQUEST,
    emailTemplatePlaceholdersRequest
  );
  yield takeEvery(
    types.GET_EMAIL_TEMPLATE_LIST_REQUEST,
    getCustomizeEmailTemplateList
  );
  yield takeEvery(
    types.GET_CANDIDATE_EMAIL_TEMPLATE_REQUEST,
    getCustomizeEmailTemplateRequest
  );
}
