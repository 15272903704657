export const GET_CONTRACT_TEMPLATE_SETTINGS_REQUEST = "GET_CONTRACT_TEMPLATE_SETTINGS_REQUEST";
export const GET_CONTRACT_TEMPLATE_SETTINGS_RECEIVE = "GET_CONTRACT_TEMPLATE_SETTINGS_RECEIVE";

export const SAVE_CONTRACT_TEMPLATE_SETTINGS_REQUEST = "SAVE_CONTRACT_TEMPLATE_SETTINGS_REQUEST";
export const SAVE_CONTRACT_TEMPLATE_SETTINGS_RECEIVE = "SAVE_CONTRACT_TEMPLATE_SETTINGS_RECEIVE";

export const DELETE_CONTRACT_TEMPLATE_COVER_LETTER_REQUEST = "DELETE_CONTRACT_TEMPLATE_COVER_LETTER_REQUEST";
export const DELETE_CONTRACT_TEMPLATE_COVER_LETTER_RECEIVE = "DELETE_CONTRACT_TEMPLATE_COVER_LETTER_RECEIVE";

export const GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_REQUEST = "GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_REQUEST";
export const GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_RECEIVE = "GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_RECEIVE";

export const GET_CONTRACT_TEMPLATE_PLACEHOLDERS_REQUEST = "GET_CONTRACT_TEMPLATE_PLACEHOLDERS_REQUEST";
export const GET_CONTRACT_TEMPLATE_PLACEHOLDERS_RECEIVE = "GET_CONTRACT_TEMPLATE_PLACEHOLDERS_RECEIVE";
