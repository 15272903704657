import { call, put } from "redux-saga/effects";
import API from "../../network/apis/apis";
import lookupAPI from "../../network/apis/lookup";
import * as ACTION_lookup from "../actions/lookups";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/lookups";

export function* getCountries() {
  try {
    const response = yield call(API.getCountriesLookup);
    yield put(ACTION_lookup.receiveCountries(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getCities(action) {
  try {
    const response = yield call(API.getCitiesLookup, action.payload);
    yield put(ACTION_lookup.receiveCities(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getAllPermissions() {
  try {
    const response = yield call(API.getAllPermissions);
    yield put(ACTION_lookup.receiveAllPermissions(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getAllJobRolesLookup() {
  try {
    const response = yield call(API.getAllJobRolesLookup);
    yield put(ACTION_lookup.receiveAllJobRolesLookup(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* lookupSalaryGrades() {
  try {
    const response = yield call(lookupAPI.salaryGradesLookup);
    yield put(ACTION_lookup.receiveSalaryGradesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* lookupResidencies() {
  try {
    const response = yield call(lookupAPI.residenciesLookup);
    yield put(ACTION_lookup.receiveResidenciesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupNationalities() {
  try {
    const response = yield call(lookupAPI.nationalitiesLookup);
    yield put(ACTION_lookup.receiveNationalitiesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupLanguages() {
  try {
    const response = yield call(lookupAPI.languagesLookup);
    yield put(ACTION_lookup.receiveLanguagesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupSalaryGradesFilter(action) {
  try {
    const response = yield call(
      lookupAPI.salaryGradeFilterLookup,
      action.payload
    );
    yield put(ACTION_lookup.receiveSalaryGradeFilterLookup(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupCareerLevels() {
  try {
    const response = yield call(lookupAPI.careerLevelsLookup);
    yield put(ACTION_lookup.receiveCareerLevelsLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupCompanyBranches(action) {
  try {
    const response = yield call(lookupAPI.companyBranchesLookup);
    yield put(ACTION_lookup.receiveCompanyBranches(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupJobTitles() {
  try {
    const response = yield call(lookupAPI.jobtitlesLookup);
    yield put(ACTION_lookup.receiveJobTitlesLookup(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupColleges() {
  try {
    const response = yield call(lookupAPI.collegesLookup);
    yield put(ACTION_lookup.receiveCollegesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupDegrees() {
  try {
    const response = yield call(lookupAPI.degreesLookup);
    yield put(ACTION_lookup.receiveDegreesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupCompetencies() {
  try {
    const response = yield call(lookupAPI.competenciesLookup);
    yield put(ACTION_lookup.receiveCompetenciesLookup(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* lookupCurrencies() {
  try {
    const response = yield call(lookupAPI.currencyLookup);
    yield put(ACTION_lookup.receiveCurrency(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* lookupJobtitlesCategories() {
  try {
    const response = yield call(lookupAPI.jobTitlesCategoryLookup);
    yield put(ACTION_lookup.receiveJobTitlesCategories(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* agentsLookups() {
  try {
    const response = yield call(lookupAPI.agentsLookup);
    yield put(ACTION_lookup.agentsLookupsRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* companyFieldsLookups() {
  try {
    const response = yield call(lookupAPI.companyFieldsLookup);
    yield put(ACTION_lookup.companyFieldsLookupsRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* jobOpeningsLookups() {
  try {
    const response = yield call(lookupAPI.jobOpeningsLookup);
    yield put(ACTION_lookup.jobOpeningsLookupReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* pipelineFlowLookups() {
  const response = yield call(lookupAPI.pipelineFlowsLookup);
  yield put(ACTION_lookup.pipelineFlowsLookupReceive(response.data.data));
}

export function* rejectReasonsLookups() {
  const response = yield call(lookupAPI.rejectionReasonsLookup);
  yield put(ACTION_lookup.rejectionReasonsReceive(response.data.data));
}

export function* companySizesLookups() {
  try {
    const response = yield call(lookupAPI.companySizesLookup);
    yield put(ACTION_lookup.companysizesReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* companyTypesLookups() {
  try {
    const response = yield call(lookupAPI.companyTypesLookup);
    yield put(ACTION_lookup.companyTypesReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* getCompanyPlan({ payload }) {
  try {
    const response = yield call(lookupAPI.getCompanyPlan, payload);
    yield put(ACTION_lookup.receiveCompanyPlan(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* jobTitlesFilterLookups() {
  try {
    const response = yield call(lookupAPI.getJobTitlesFilterLookup);
    yield put(ACTION_lookup.jobTitlesFiltersLookupReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* jobStatusFilterLookups() {
  try {
    const response = yield call(lookupAPI.getJobStatusFilterLookup);
    yield put(ACTION_lookup.jobStatusFiltersLookupReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* branchesFilterLookups() {
  try {
    const response = yield call(lookupAPI.getBranchesFilterLookup);
    yield put(ACTION_lookup.branchesFiltersLookupReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* departmentsLookups() {
  try {
    const response = yield call(lookupAPI.getDepartmentsLookup);
    yield put(ACTION_lookup.departmentsLookupReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}


export function* LookupsSagasWatch() {
  yield takeLatest(type.GET_COUNTRIES_REQUEST, getCountries);
  yield takeLatest(type.GET_CITIES_REQUEST, getCities);
  yield takeLatest(type.GET_ALL_JOB_ROLES_LOOKUP_REQUEST, getAllJobRolesLookup);
  yield takeLatest(type.GET_ALL_PERMISSIONS_REQUEST, getAllPermissions);
  yield takeLatest(type.LOOKUP_SALARY_GRADES_REQUEST, lookupSalaryGrades);
  yield takeLatest(type.LOOKUP_RESIDENCIES_REQUEST, lookupResidencies);
  yield takeLatest(type.LOOKUP_NATIONALITIES_REQUEST, lookupNationalities);
  yield takeLatest(type.LOOKUP_LANGUAGES_REQUEST, lookupLanguages);
  yield takeLatest(type.LOOKUP_CAREER_LEVELS_REQUEST, lookupCareerLevels);
  yield takeLatest(type.LOOKUP_JOB_TITLES_REQUEST, lookupJobTitles);
  yield takeLatest(type.LOOKUP_COLLEGES_REQUEST, lookupColleges);
  yield takeLatest(type.LOOKUP_DEGREES_REQUEST, lookupDegrees);
  yield takeLatest(type.LOOKUP_COMPETENCIES_REQUEST, lookupCompetencies);
  yield takeLatest(
    type.LOOKUP_SALARY_GRADES_FILTER_REQUEST,
    lookupSalaryGradesFilter
  );
  yield takeLatest(type.LOOKUP_COMPANY_BRANCHES_REQUEST, lookupCompanyBranches);
  yield takeLatest(type.LOOKUP_CURRENCY_REQUEST, lookupCurrencies);
  yield takeLatest(
    type.LOOKUP_JOBTITLES_CATEGORIES_REQUEST,
    lookupJobtitlesCategories
  );
  yield takeLatest(type.LOOKUP_AGENTS_REQUEST, agentsLookups);
  yield takeLatest(type.LOOKUP_COMPANY_FIELDS_REQUEST, companyFieldsLookups);
  yield takeLatest(type.LOOKUP_GET_JOB_OPENINGS_REQUEST, jobOpeningsLookups);
  yield takeLatest(type.LOOKUP_GET_PIPELINE_FLOWS_REQUEST, pipelineFlowLookups);
  yield takeLatest(
    type.LOOKUP_GET_REJECTION_REASONS_REQUEST,
    rejectReasonsLookups
  );
  yield takeLatest(type.LOOKUP_COMPANY_SIZES_REQUEST, companySizesLookups);
  yield takeLatest(type.LOOKUP_COMPANY_TYPES_REQUEST, companyTypesLookups);
  yield takeLatest(type.GET_COMP_PLAN, getCompanyPlan);
  yield takeLatest(
    type.JOB_TITLE_FILTER_LOOKUP_REQUEST,
    jobTitlesFilterLookups
  );
  yield takeLatest(
    type.JOB_STATUS_FILTER_LOOKUP_REQUEST,
    jobStatusFilterLookups
  );
  yield takeLatest(type.BRANCH_FILTER_LOOKUP_REQUEST, branchesFilterLookups);
  yield takeLatest(type.LOOKUP_DEPARTMENTS_REQUEST, departmentsLookups);
}
