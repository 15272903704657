import * as types from "../types/invoices";

const INITIAL_STATE = {
  invoices: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_INVOICES_RECIEVE:
      return {
        ...state,
        paging: action.payload.paging,
        invoices: action.payload.data,
      };
    case types.HANDLE_MODAL_STATE:
      return {
        ...state,
        invoiceModalOpen: action.payload,
      };
    default:
      return state;
  }
};
