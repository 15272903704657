import { combineReducers } from "redux";
import login from "./Login";
import loader from "./Loader";
import locale from "./Lang";
import snackbar from "./Snackbar";
import company from "./Company";
import branches from "./Branches";
import jobRole from "./JobRole";
import lookups from "./Lookups";
import agents from "./Agents";
import permissions from "./userPermissions";
import activePageIndex from "./ClickedPageIndex";
import salaryGrade from "./SalaryGrade";
import jobRequisitionSetup from "./jobRequisitionSetup";
import jobTitles from "./JobTitles";
import jobDetailsRequest from "./createNewRequest";
import stepper from "./Stepper";
import sideMenu from "./SideMenu";
import socialMediaSetup from "./SocialMediaSetup";
import userProfile from "./userProfile";
import viewAllRequests from "./viewAllRequests";
import publishedJobs from "./PublishedJobs";
import manageCandidates from "./manageCandidates";
import myInterviews from "./MyInterviews";
import wizardStep from "./wizardStep";
import candidatePortalSetup from "./candidatePortalSetup";
import videoInterview from "./videoInterview";
import uploadExcel from "./uploadExcel";
import requests from "./requests";
import applicantProfile from "./applicantProfile";
import pipelineSetup from "./pipelineSetup";
import dashboardData from "./dasboard";
import payment from "./payment";
import offerTemplateSetup from "./offerTemplateSetup";
import candidateTalentPoolTable from "./candidateTalentPool";
import contactUs from "./contactUs";
import invoices from "./invoices";
import companyIntegrations from "./Integration";
import editEmail from "./EditEmail";
import emailTemplates from "./emailTemplates";
import notification from "./notification";
import rejectionReasons from "./rejectionReasons";
import contractTemplateSetup from "./ContractTemplate";
import departments from "./DepartmentsSetup";
import preOfferDocs from "./PreOfferSetup";
import interviewEvaluationSetup from "./interviewEvaluationSetup";
import startYourJournyMenu from './StartYourJournyMenu'
export default combineReducers({
  locale,
  emailTemplates,
  loading: loader,
  editEmail,
  snackbar,
  login,
  company,
  branches,
  jobRole,
  lookups,
  agents,
  permissions,
  activePageIndex,
  salaryGrade,
  jobRequisitionSetup,
  jobTitles,
  jobDetailsRequest,
  stepper,
  sideMenu,
  startYourJournyMenu,
  socialMediaSetup,
  userProfile,
  viewAllRequests,
  publishedJobs,
  manageCandidates,
  myInterviews,
  wizardStep,
  candidatePortalSetup,
  videoInterview,
  requests,
  uploadExcel,
  applicantProfile,
  pipelineSetup,
  dashboardData,
  payment,
  offerTemplateSetup,
  candidateTalentPoolTable,
  contactUs,
  invoices,
  companyIntegrations,
  notification,
  rejectionReasons,
  contractTemplateSetup,
  departments,
  preOfferDocs,
  interviewEvaluationSetup,
});
