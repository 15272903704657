export const GET_APPLICANT_PROFILE_REQUEST = "GET_APPLICANT_PROFILE_REQUEST";
export const GET_APPLICANT_PROFILE_RECEIVE = "GET_APPLICANT_PROFILE_RECEIVE";

export const GET_APPLICATION_REQUEST = "GET_APPLICATION_REQUEST";
export const GET_APPLICATION_RECEIVE = "GET_APPLICATION_RECEIVE";

export const GET_CANDIDATE_APPLICATIONS_REQUEST =
  "GET_CANDIDATE_APPLICATIONS_REQUEST";
export const GET_CANDIDATE_APPLICATIONS_RECEIVE =
  "GET_CANDIDATE_APPLICATIONS_RECEIVE";
export const GET_APPLICANT_MAIN_DATA_REQUEST =
  "GET_APPLICANT_MAIN_DATA_REQUEST";
export const GET_APPLICANT_MAIN_DATA_RECEIVE =
  "GET_APPLICANT_MAIN_DATA_RECEIVE";

export const GET_APPLICANT_PREOFFER_ATTACHEMENTS_REQUEST =
  "GET_APPLICANT_PREOFFER_ATTACHEMENTS_REQUEST";
export const GET_APPLICANT_PREOFFER_ATTACHEMENTS_RECEIVE =
  "GET_APPLICANT_PREOFFER_ATTACHEMENTS_RECEIVE";
export const GET_APPLICANT_INTERVIEWS_REQUEST =
  "GET_APPLICANT_INTERVIEWS_REQUEST";
export const GET_APPLICANT_INTERVIEWS_RECEIVE =
  "GET_APPLICANT_INTERVIEWS_RECEIVE";

export const GET_CANDIDATE_LOG_REQUEST = "GET_CANDIDATE_LOG_REQUEST";
export const GET_CANDIDATE_LOG_RECEIVE = "GET_CANDIDATE_LOG_RECEIVE";

export const GET_ALL_CANDIDATE_LOGS_REQUEST = "GET_ALL_CANDIDATE_LOGS_REQUEST";
export const GET_ALL_CANDIDATE_LOGS_RECEIVE = "GET_ALL_CANDIDATE_LOGS_RECEIVE";

export const DISABLE_ADVERT_REQUEST = "DISABLE_ADVERT_REQUEST";
export const DISABLE_ADVERT_RECEIVE = "DISABLE_ADVERT_RECEIVE";

export const GET_MORE_INFO_ANSWERS_REQUEST = "GET_MORE_INFO_ANSWERS_REQUEST";
export const GET_MORE_INFO_ANSWERS_RECEIVE = "GET_MORE_INFO_ANSWERS_RECEIVE";

export const GET_APPLICANT_OFFERS_REQUEST = "GET_APPLICANT_OFFERS_REQUEST";
export const GET_APPLICANT_OFFERS_RECEIVE = "GET_APPLICANT_OFFERS_RECEIVE";
export const GET_APPLICANT_CONTRACTS_REQUEST = "GET_APPLICANT_CONTRACTS_REQUEST";
export const GET_APPLICANT_CONTRACTS_RECEIVE = "GET_APPLICANT_CONTRACTS_RECEIVE";
export const DISABLE_SEND_MORE_INFO_REQUEST = "DISABLE_SEND_MORE_INFO_REQUEST";
export const DISABLE_SEND_EMAIL_REQUEST = "DISABLE_SEND_EMAIL_REQUEST";
export const DISABLE_SEND_MORE_INFO_RECIEVE = "DISABLE_SEND_MORE_INFO_RECIEVE";
export const DISABLE_SEND_EMAIL_RECIEVE = "DISABLE_SEND_EMAIL_RECIEVE";
export const GET_CONTRACT_FILE_BYTES = "GET_CONTRACT_FILE_BYTES";
export const GET_CONTRACT_FILE_BYTES_RECIEVE = "GET_CONTRACT_FILE_BYTES_RECIEVE";

export const GET_CANDIDATE_MAIN_DATA_REQUEST =
  "GET_CANDIDATE_MAIN_DATA_REQUEST";
export const GET_CANDIDATE_MAIN_DATA_RECEIVE =
  "GET_CANDIDATE_MAIN_DATA_RECEIVE";