import * as types from '../types/payment';

export const requestSavedCards = () => ({
  type: types.REQUEST_SAVED_CARDS,
});
export const receiveSavedCards = (payload) => ({
  type: types.RECEIVE_SAVED_CARDS,
  payload,
});
export const requestCheckoutId = (payload) => ({
  type: types.REQUEST_CHECKOUT_ID,
  payload,
});
export const receiveCheckoutId = (payload) => ({
  type: types.RECEIVE_CHECKOUT_ID,
  payload,
});
export const requestCheckoutStatus = (payload) => ({
  type: types.REQUEST_CHECKOUT_STATUS,
  payload,
});
export const receiveCheckoutStatus = (payload) => ({
  type: types.RECEIVE_CHECKOUT_STATUS,
  payload,
});
export const requestActivatePaymentCard = (payload) => ({
  type: types.ACTIVATE_PAYMENT_CARD_REQUEST,
  payload,
});

export const requestDeletePaymentCard = (payload) => ({
  type: types.DELETE_PAYMENT_CARD_REQUEST,
  payload,
});
export const requestSubscriptionPlans = () => ({
  type: types.SUBSCRIPTION_PLANS_REQUEST,
});
export const receiveSubscriptionPlans = (payload) => ({
  type: types.SUBSCRIPTION_PLANS_RECEIVE,
  payload,
});

export const requestCompanyVatNumber = () => ({
  type: types.VAT_NUMBER_REQUEST,
});
export const receiveCompanyVatNumber = (payload) => ({
  type: types.VAT_NUMBER_RECEIVE,
  payload,
});

export const receiveCompanyVatNumberUpdate = (payload) => ({
  type: types.VAT_NUMBER_UPDATE_RECIEVE,
  payload,
});

export const requestSubscriptionPayment = (payload) => ({
  type: types.SUBSCRIBTION_PAYMENT_REQUEST,
  payload,
});
export const receiveSubscriptionPayment = (payload) => ({
  type: types.SUBSCRIBTION_PAYMENT_RECEIVE,
  payload,
});
export const requestCanelSubscription = () => ({
  type: types.CANCEL_SUBSCRIPTION_REQUEST,
});
export const receiveCanelSubscription = (payload) => ({
  type: types.CANCEL_SUBSCRIPTION_RECEIVE,
  payload,
});
export const requestBillingInfo = () => ({
  type: types.BILLING_INFO_REQUEST,
});
export const receiveBillingInfo = (payload) => ({
  type: types.BILLING_INFO_RECEIVE,
  payload,
});

export const requestPaymentWitSavedCards = (payload) => {
  return {
    type: types.PAYMENT_WITH_SAVED_CARDS_REQUEST,
    payload,
  };
};

export const receivePaymentWitSavedCards = (payload) => ({
  type: types.PAYMENT_WITH_SAVED_CARDS_RECEIVE,
  payload,
});

export const requestRenewStatus = (payload) => ({
  type: types.RENEW_STATUS_RESPONSE_REQUEST,
  payload,
});
export const requestPaymentCalculation = (payload) => ({
  type: types.PAYMENT_CALCULATION_REQUEST,
  payload,
});

export const receivePaymentCalculation = (payload) => ({
  type: types.PAYMENT_CALCULATION_RECEIVE,
  payload,
});

export const requestPaymentWithWallet = (payload) => ({
  type: types.PAYMENT_WITH_WALLET_REQUEST,
  payload,
});

export const receivePaymentWithWallet = (payload) => ({
  type: types.PAYMENT_WITH_WALLET_RECEIVE,
  payload,
});

export const requestDowngradeLostData = () => ({
  type: types.DOWNGRADE_LOST_DATA_REQUEST,
});

export const receiveDowngradeLostData = (payload) => ({
  type: types.DOWNGRADE_LOST_DATA_RECEIVE,
  payload,
});

export const requestValidatePromocode = (payload) => ({
  type: types.VALIDATE_PROMO_CODE_REQUEST,
  payload,
});

export const receiveValidatePromocode = (payload) => ({
  type: types.VALIDATE_PROMO_CODE_RECEIVE,
  payload,
});

export const requestInvoiceData = (payload) => ({
  type: types.INVOICE_GENERATEION_REQUEST,
  payload,
});

export const receiveInvoiceData = (payload) => ({
  type: types.INVOICE_GENERATEION_RECEIVE,
  payload,
});

export const updateVatNumber = (data) => {
  return {
    type: types.VAT_NUMBER_UPDATE_REQUEST,
    payload: data,
  };
};

export const getCompanySubscribtionEndDate_Receieve = (data) => {
  return {
    type: types.SUBSCRIPTION_END_DATE_RECEIVE,
    payload: data,
  };
};

export const getCompanySubscribtionEndDate_Request = () => ({
  type: types.SUBSCRIPTION_END_DATE_REQUEST,
});
