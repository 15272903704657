import * as types from "../types/manageCandidates";

export const scheduleInterviewRequest = (payload) => {
  return {
    type: types.SCHEDULE_INTERVIEW_REQUEST,
    payload,
  };
};
export const scheduleInterviewRecieve = (payload) => ({
  type: types.SCHEDULE_INTERVIEW_RECIEVE,
  payload,
});

export const requestGetPipelineApplications = (payload) => {
  return {
    type: types.REQUEST_GET_PIPELINE_APPLICATIONS,
    payload,
  };
}
export const receiveGetPipelineApplications = (payload) => ({
  type: types.RECEIVE_GET_PIPELINE_APPLICATIONS,
  payload,
});

export const requestGetScheduleData = (payload) => {
  return {
    type: types.REQUEST_GET_RESCHEDULE_DATA,
    payload,
  };
};
export const receiveGetScheduleData = (payload) => ({
  type: types.RECEIVE_GET_RESCHEDULE_DATA,
  payload,
});

export const requestRescheduleData = (payload) => {
  return {
    type: types.REQUEST_RESCHEDULE_INTERVIEW,
    payload,
  };
};
export const receiveRescheduleData = (payload) => ({
  type: types.RECEIVE_RESCHEDULE_INTERVIEW,
  payload,
});



export const requestMeetingPlatforms = (payload) => {
  return {
    type: types.REQUEST_MEETING_PLATFORMS,
    payload,
  };
};
export const receiveMeetingPlatforms = (payload) => ({
  type: types.RECEIVE_MEETING_PLATFORMS,
  payload,
});




export const requestAddApplicants = (payload) => ({
  type: types.REQUEST_ADD_APPLICANTS,
  payload,
});

export const receiveAddApplicants = (payload) => ({
  type: types.RECEIVE_ADD_APPLICANTS,
  payload,
});

export const requestPipelineStages = (payload) => {
  return {
    type: types.REQUEST_GET_PIPELINE_STAGES,
    payload,
  };
};

export const receivePipelineStages = (payload) => ({
  type: types.RECEIVE_GET_PIPELINE_STAGES,
  payload,
});

export const requestRejectApplicants = (payload) => ({
  type: types.REQUEST_REJECT_APPLICANTS,
  payload,
});

export const saveCurrentStageTabIndex = (payload) => ({
  type: types.SAVE_CURRENT_STAGE_TAB_INDEX,
  payload,
});

export const moveCandidatesToAnotherStageRequest = (payload) => ({
  type: types.REQUEST_MOVE_APPLICANT_ANOTHER_STAGE,
  payload,
});

export const requestPreofferData = (payload) => ({
  type: types.REQUEST_PREOFFER_DATA,
  payload,
});
export const receivePreofferData = (payload) => ({
  type: types.RECEIVE_PREOFFER_DATA,
  payload,
});

export const sendApplicantPreoffer = (payload) => ({
  type: types.SEND_APPLICANT_PREOFFER_REQUEST,
  payload,
});
export const requestAddRateToApplicants = (payload) => ({
  type: types.REQUEST_ADD_RATE_TO_APPLICANTS,
  payload,
});

export const requestGetApplicantRate = (payload) => ({
  type: types.REQUEST_GET_APPLICANT_RATE,
  payload,
});

export const receiveGetApplicantRate = (payload) => ({
  type: types.RECEIVE_GET_APPLICANT_RATE,
  payload,
});

export const requestSendCustomizedEmail = (payload) => ({
  type: types.REQUEST_SEND_CUSTOMIZED_EMAIL,
  payload,
});

export const requestSendUpdateCVEmail = (payload) => ({
  type: types.REQUEST_SEND_UPDATE_CV_EMAIL,
  payload,
});

export const requestAddApplicantToAnotherJob = (payload) => ({
  type: types.ADD_APPLICANT_TO_ANOTHER_JOB_OPENING_REQUEST,
  payload,
});

export const requestDisableAddApplicants = () => ({
  type: types.DISABLE_ADD_APPLICANTS_REQUEST,
});

export const receiveDisableAddApplicants = (payload) => ({
  type: types.DISABLE_ADD_APPLICANTS_RECEIVE,
  payload,
});
export const requestDisableEngagement = () => ({
  type: types.DISABLE_ENGAGEMENT_REQUEST,
});
export const receiveDisableEngagement = (payload) => ({
  type: types.DISABLE_ENGAGEMENT_RECEIVE,
  payload,
});

export const requestAddMoreApplicantInfo = (payload) => ({
  type: types.REQUEST_ADD_APPLICANT_MORE_INFO,
  payload
});
export const receiveAddMoreApplicantInfo = (payload) => ({
  type: types.RECEIVE_ADD_APPLICANT_MORE_INFO,
  payload,
});

export const requestSendEmailChangeState = (payload) => ({
  type: types.REQUEST_CHANGE_STAGE_SEND_EMAIL,
  payload
});

export const requestGetSendOfferTemplate = (payload) => ({
  type: types.GET_SEND_OFFER_TEMPLATE_REQUEST,
  payload
});

export const receiveGetSendOfferTemplate = (payload) => ({
  type: types.GET_SEND_OFFER_TEMPLATE_RECEIVE,
  payload
});
export const requestGetSendContractTemplate = (payload) => ({
  type: types.GET_SEND_CONTRACT_TEMPLATE_REQUEST,
  payload
});

export const receiveGetSendContractTemplate = (payload) => ({
  type: types.GET_SEND_CONTRACT_TEMPLATE_RECEIVE,
  payload
});
export const requestSendContract = (payload) => ({
  type: types.SEND_CONTRACT_REQUEST,
  payload
});
export const requestSendReContract = (payload) => ({
  type: types.SEND_RECONTRACT_REQUEST,
  payload
});
export const requestSendOffer = (payload) => ({
  type: types.SEND_OFFER_REQUEST,
  payload
});
export const requestSendReOffer = (payload) => ({
  type: types.SEND_REOFFER_REQUEST,
  payload
});

export const requestGetOfferApprovalsSetup = () => ({
  type: types.GET_OFFER_APPROVALS_SETUP_REQUEST,
});
export const receiveGetOfferApprovalsSetup = (payload) => ({
  type: types.GET_OFFER_APPROVALS_SETUP_RECEIVE,
  payload
});
export const requestGetContractApprovalsSetup = () => ({
  type: types.GET_CONTRACT_APPROVALS_SETUP_REQUEST,
});
export const receiveGetContractApprovalsSetup = (payload) => ({
  type: types.GET_CONTRACT_APPROVALS_SETUP_RECEIVE,
  payload
});
export const requestGetOfferApprovalStatusList = (payload) => ({
  type: types.GET_OFFER_APPROVALS_STATUS_LIST_REQUEST,
  payload
});
export const receiveGetOfferApprovalStatusList = (payload) => ({
  type: types.GET_OFFER_APPROVALS_STATUS_LIST_RECEIVE,
  payload
});
export const requestFavoriteCandidate = (payload) => ({
  type: types.REQUEST_TOGGLE_FAVORITE_CANDIDATE,
  payload
});

export const requestDemoAction = (payload) => ({
  type: types.REQUEST_DEMO_ACTION,
  payload
});

export const requestDemoModal = (payload) => ({
  type: types.REQUEST_DEMO_Modal,
  payload
});
export const requestGetContractApprovalStatusList = (payload) => ({
  type: types.GET_CONTRACT_APPROVALS_STATUS_LIST_REQUEST,
  payload
});
export const receiveGetContractApprovalStatusList = (payload) => ({
  type: types.GET_CONTRACT_APPROVALS_STATUS_LIST_RECEIVE,
  payload
});
