import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export function Checkboxes({
  isChecked,
  disabled,
  value,
  handleChange,
  name,
  classes,
}) {
  return (
    <>
      <Checkbox
        disabled={disabled}
        edge="start"
        name={name}
        checked={isChecked}
        disableRipple
        onChange={handleChange}
      />
      <label className={`medium_font ${classes}`}>{value}</label>
    </>
  );
}
