import React from "react";
import { TextField ,} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";

export const AutocompleteField = ({
  propId,
  name,
  id,
  key_id,
  key_ar,
  key_en,
  value_ar,
  value_en,
  dataArr,
  label,
  error,
  index,
  helperText,
  handleChange,
  isDisabled,
  multiple,
  isRequired,
  filterOptions,
  getOptionDisabled,
  disableClearable,
  multipleValue,
  classes,
  size,
  handleBlur,
  PaperComponent
}) => {
  const lang = useSelector((state) => state.locale.lang);
  return (
    <Autocomplete
      multiple={multiple}
      disabled={isDisabled}
      onBlur={handleBlur}
      PaperComponent={PaperComponent}
      getOptionDisabled={getOptionDisabled}
      size={size || "medium"}
      disableClearable={disableClearable}
      value={
        !multiple
          ? lang === "en"
            ? {
                [key_id]: id,
                [key_en]: value_en,
              }
            : {
                [key_id]: id,
                [key_ar]: value_ar,
              }
          : multipleValue
      }
      className={`w-100 ${classes}`}
      name={name}
      id={propId}
      options={dataArr}
      getOptionLabel={(option) =>
        lang === "en" ? option[key_en] : option[key_ar]
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={isRequired ? label + "*" : label}
          variant="outlined"
          error={error}
          helperText={error && helperText}
        />
      )}
      onChange={(event, value) => handleChange(value, name, index)}
    />
  );
};
