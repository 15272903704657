import * as types from '../types/payment';

const INITIAL_STATE = {
  promoCode: false,
  savedCards: [],
  checkoutId: '',
  checkoutStatus: {},
  subscriptionPlans: {
    subscriptionPlans: [],
  },
  subscriptionPayment: '',
  refundData: null,
  billingInfo: {},
  paymentNote: '',
  payWithSavedCardsCheckoutId: '',
  downgradeLostItems: {},
  invoice: {},
  CompanyHasVatNumber: null,
  vatNumberUpdated: null,
  checkVatStatusRecieved: null,
  end_date: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VALIDATE_PROMO_CODE_RECEIVE:
      return { ...state, promoCode: action.payload };
    case types.RECEIVE_SAVED_CARDS:
      return { ...state, savedCards: [...action.payload] };
    case types.RECEIVE_CHECKOUT_ID:
      return { ...state, checkoutId: action.payload };
    case types.RECEIVE_CHECKOUT_STATUS:
      return { ...state, checkoutStatus: action.payload };
    case types.SUBSCRIPTION_PLANS_RECEIVE:
      return { ...state, subscriptionPlans: action.payload };
    case types.VAT_NUMBER_RECEIVE:
      return {
        ...state,
        CompanyHasVatNumber: !action.payload,
        checkVatStatusRecieved: !state.checkVatStatusRecieved,
      };
    case types.VAT_NUMBER_UPDATE_RECIEVE:
      return { ...state, vatNumberUpdated: action.payload };
    case types.SUBSCRIBTION_PAYMENT_RECEIVE:
      return { ...state, subscriptionPayment: action.payload };
    case types.CANCEL_SUBSCRIPTION_RECEIVE:
      return { ...state, refundData: action.payload };
    case types.BILLING_INFO_RECEIVE:
      return { ...state, billingInfo: action.payload };
    case types.PAYMENT_CALCULATION_RECEIVE:
      return { ...state, paymentNote: action.payload };
    case types.PAYMENT_WITH_SAVED_CARDS_RECEIVE:
      return { ...state, payWithSavedCardsCheckoutId: action.payload };
    case types.DOWNGRADE_LOST_DATA_RECEIVE:
      return { ...state, downgradeLostItems: action.payload };
    case types.INVOICE_GENERATEION_RECEIVE:
      return { ...state, invoice: action.payload };
    case types.SUBSCRIPTION_END_DATE_RECEIVE:
      return { ...state, end_date: action.payload };
    default:
      return state;
  }
};
