import * as types from "../types/pipelineSetup";

const INITIAL_STATE = {
  flows: {},
  deleteFlow: {},
  singleFlow: {},
  stageHasApplicants: false,
  disableSendOffer: null,
  disablePipeline: null,
  adsSettings: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_FLOWS_GRID_RECEIVE:
      return {
        ...state,
        flows: { ...action.payload },
      };
    case types.DELETE_FLOW_RECEIVE:
      return {
        ...state,
        deleteFlow: { ...action.payload },
      };
    case types.EDIT_FLOW_DETAILS_RECEIVE:
      return {
        ...state,
        singleFlow: { ...action.payload },
      };
    case types.DELETE_STAGE_RECEIVE:
      return {
        ...state,
        stageHasApplicants: action.payload,
      };
    case types.DISABLE_SEND_OFFER_RECEIVE:
      return {
        ...state,
        disableSendOffer: action.payload,
      };
    case types.DISABLE_PIPELINE_RECEIVE:
      return {
        ...state,
        disablePipeline: action.payload,
      };
    case types.ADS_SETTINGS_RESPONSE:
      return {
        ...state,
        adsSettings: action.payload,
      };
    default:
      return state;
  }
};
