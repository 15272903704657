import * as types from "../types/PreOfferSetup";

export const requestAddNewPreOffer = (data, type) => {
  return {
    type: types.ADD_PREOFFER_REQUEST,
    payload: { data, type }
  };
};

export const receiveAddNewPreOffer = (data, type) => {
  return {
    type: types.ADD_PREOFFER_RECEIVE,
    payload: { data, type }
  };
};

export const requestGetAllPreOffer = data => {
  return {
    type: types.GET_PREOFFER_LIST_REQUEST,
    payload: data
  };
};

export const receiveGetAllPreOffer = data => {
  return {
    type: types.GET_PREOFFER_LIST_RECEIVE,
    payload: data
  };
};

export const requestGetSinglePreOffer = data => {
  return {
    type: types.GET_SINGLE_PREOFFER_REQUEST,
    payload: data
  };
};

export const receiveGetSinglePreOffer = data => {
  return {
    type: types.GET_SINGLE_PREOFFER_RECEIVE,
    payload: data
  };
};

export const requestEditPreOffer = data => {
  return {
    type: types.EDIT_PREOFFER_REQUEST,
    payload: data
  };
};

export const receiveEditPreOffer = data => {
  return {
    type: types.EDIT_PREOFFER_RECEIVE,
    payload: data
  };
};

export const requestDeletePreOffer = data => {
  return {
    type: types.DELETE_PREOFFER_REQUEST,
    payload: data
  };
};

export const receiveDeletePreOffer = data => {
  return {
    type: types.DELETE_PREOFFER_RECEIVE,
    payload: data
  };
};
