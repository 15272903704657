import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/rejectionReasons";
import API from "../../network/apis/rejectionReasons";
import { dispatchError, showSnackbar, getURLParams } from "utils/shared";
import * as ACTION from "../actions/rejectionReasons";

export function* getRejectionReasonsRequest_({ payload }) {
  try {
    const response = yield call(API.getRejectionReasonsRequest, payload);
    yield put(ACTION.getRejectionReasonsRecieve(response.data));
  } catch (err) {
    console.log(err);
    dispatchError(err.response?.data);
  }
}
export function* addRejectionReasonRequest_({ payload }) {
  try {
    yield call(API.addRejectionReasonRequest, payload);
    showSnackbar("savedSuccessfuly");
    setTimeout(() => (window.location.href = "/rejectionReasons?page=1"), 100);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* editRejectionReasonRequest_({ payload }) {
  try {
    const response = yield call(API.editRejectionReasonRequest, payload.data);
    window.location.href = `/rejectionReasons?page=${payload.page}`;
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getRejectionReasonRequest_({ payload }) {
  try {
    const response = yield call(API.getRejectionReasonRequest, payload);
    yield put(ACTION.getRejectionReasonRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* deleteRejectionReasonRequest_({ payload }) {
  try {
    yield call(API.deleteRejectionReasonRequest, payload);
    yield put(ACTION.deleteRejectionReasonsRecieve(true));
    showSnackbar("done");
    // call get list rejection reasons "sagas"
    yield call(getRejectionReasonsRequest_, {
      payload: { pageNumber: getURLParams('page'), page_size: 10 },
    });
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}

export function* RejectionReasonsSagasWatch() {
  yield takeEvery(
    types.ADD_REJECTION_REASON_REQUEST,
    addRejectionReasonRequest_
  );
  yield takeEvery(
    types.DELETE_REJECTION_REASON_REQUEST,
    deleteRejectionReasonRequest_
  );
  yield takeEvery(
    types.EDIT_REJECTION_REASON_REQUEST,
    editRejectionReasonRequest_
  );
  yield takeEvery(
    types.GET_REJECTION_REASONS_REQUEST,
    getRejectionReasonsRequest_
  );
  yield takeEvery(
    types.GET_REJECTION_REASON_REQUEST,
    getRejectionReasonRequest_
  );
}