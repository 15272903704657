import { axiosInstance } from "./config";

let handlerEnabled = true;

const requests = async (params) => {
  return await axiosInstance.get(`/admin/approval_request/pending`, {
    params,
    handlerEnabled,
  });
};
const oldRequests = async (params) => {
  return await axiosInstance.get(`/admin/approval_request/oldRequests`, {
    params,
    handlerEnabled,
  });
};
const approverRequests = async (id) => {
  return await axiosInstance.put(`/admin/approval_request/approve/${id}`, {
    handlerEnabled,
  });
};
const candidateApprovalsRequests = async (params) => {
  return await axiosInstance.get(
    `/admin/moreInformationRequest/extend_expiration_requests`,
    {
      params,
    }
  );
};
const approveCandidateApprovalsRequests = async ({ request_id, data }) => {
  return await axiosInstance.post(
    `/admin/moreInformationRequest/extend_expiration_requests/${request_id}/approve/`,
    data
  );
};

const rejectCandidateApprovalsRequests = async (request_id) => {
  return await axiosInstance.post(
    `/admin/moreInformationRequest/extend_expiration_requests/${request_id}/reject`
  );
};

const disApproveRequests = async ({ id, data }) => {
  return await axiosInstance.put(`/admin/approval_request/reject/${id}`, data, {
    handlerEnabled,
  });
};

const uploadSignature = async (data) =>
  await axiosInstance.post(`admin/agent/signature`, data);

const getOfferTemplateOldRequests = async (offer_id) =>
  await axiosInstance.get(`/admin/offers/oldRequests/${offer_id}`);

const getOfferTemplatePendingRequests = async (offer_id) =>
  await axiosInstance.get(`/admin/offers/pending/${offer_id}`);

const sendOfferTemplate = async ({ data, offer_id, approval_request_id }) =>
  await axiosInstance.post(
    `/admin/offers/${offer_id}/approval_requests/${approval_request_id}/sign_and_approve`,
    data
  );

const saveUploadedAprovalOffer = async (data) =>
  await axiosInstance.post(`/admin/offers/uploadOffer_AttachFile`, data);

const getActiveUnifunicSMS = async (data) => {
  return await axiosInstance.get(
    "admin/companyIntegration/getCompanyUnifonicIntegrationsActivation"
  );
};
/* CONTRACT  */
const getContractTemplateOldRequests = async (offer_id) =>
  await axiosInstance.get(`/admin/contracts/oldRequests/${offer_id}`);

const getContractTemplatePendingRequests = async (offer_id) =>
  await axiosInstance.get(`/admin/contracts/pending/${offer_id}`);

const sendContractTemplate = async ({ data }) =>
  await axiosInstance.post(
    `/admin/contracts/approval_requests/sign_and_approve`,
    data
  );
const saveUploadedAprovalContract = async (data) =>
  await axiosInstance.post(`/admin/contracts/uploadContract_AttachFile`, data);

const candidateInfoByOffer = async ({ offer_id }) => {
  return await axiosInstance.get(
    `/admin/candidates/info_by_offer/${offer_id}`,
    {
      handlerEnabled,
    }
  );
};

const candidateInfoByContract = async ({ contract_id }) => {
  return await axiosInstance.get(
    `/admin/candidates/info_by_contract/${contract_id}`,
    {
      handlerEnabled,
    }
  );
};

export default {
  saveUploadedAprovalOffer,
  requests,
  approverRequests,
  disApproveRequests,
  uploadSignature,
  getOfferTemplatePendingRequests,
  getOfferTemplateOldRequests,
  sendOfferTemplate,
  candidateApprovalsRequests,
  approveCandidateApprovalsRequests,
  rejectCandidateApprovalsRequests,
  getActiveUnifunicSMS,
  oldRequests,
  saveUploadedAprovalContract,
  sendContractTemplate,
  getContractTemplateOldRequests,
  getContractTemplatePendingRequests,
  candidateInfoByOffer,
  candidateInfoByContract
};
