import * as types from "../types";

const INITIAL_STATE = {
  activeStep: 0,
  activeStepTitle: "JOB_DETAILS",
  totalSteps: 4,
  isLastStep: false,
  completedSteps: [false, false, false, false]
};

const stepper = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_STEPPER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default stepper;
