import * as types from "../types/EditEmail";

export const requestEditEmail = (payload) => {
  return {
    type: types.EDIT_EMAIL_REQUEST,
    payload
  };
};
export const getTemplateEmailRequest = (payload) => {
  return {
    type: types.GET_TEMPLATE_EMAIL_REQUEST,
    payload
  };
};
export const getTemplateEmailRecieve = (payload) => {
  return {
    type: types.GET_TEMPLATE_EMAIL_RECIEVE,
    payload
  };
};
