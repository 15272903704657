import * as types from '../types';

export const setStepper = (data) => {
  return {
  type: types.SET_STEPPER, 
  payload : data ,
  };
};

export const getStepper = (data) => {
  return {
   type: types.GET_STEPPER,
   payload:data 
  };
};