import React, { Fragment, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { EditEmailSchema, EditEmailEditorValidation } from "./EditEmailSchema";
import {
  requestEditEmail,
  getTemplateEmailRequest,
} from "store/actions/editEmail";
import { useSelector, useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { InputField } from "../Controls/InputField/InputField";
import TextEditor from "components/TextEditor/TextEditor";
import "./EditEmail.scss";
import {
  dynamicTextEditorUpdates,
  replaceText,
} from "components/EditEmail/EditEmailUtils";
const Fields = injectIntl(
  ({
    initialValues,
    params,
    showTemplate,
    isTitleNotEditable,
    intl: { messages },
  }) => {
    const { values, errors, setFieldValue } = useFormikContext();
    const [init, setInit] = React.useState("");
    const dispatch = useDispatch();

    const handleChange = (name, value) => {
      setFieldValue(name, value);
    };

    useEffect(() => {
      dispatch(
        requestEditEmail({
          data: values,
          errors: Object.keys(errors).length > 0 || false,
        })
      );
   
    }, [errors, values, dispatch]);

    useEffect(() => {
      if (init === "") {
setInit(        replaceText(values.body.value,{
    
  INTERVIEW_LOCATION:params.location
}))
        // setInit(values.body.value);
      } else if (initialValues.source === "api") {
        setInit(replaceText(values.body.value,{
    
          INTERVIEW_LOCATION:params.location
        }))
        // setInit(initialValues.data.body.value);
        dispatch(
          requestEditEmail({
            source: "user",
            data: initialValues.data,
            errors: Object.keys(errors).length > 0 || false,
          })
        );
      }
    }, [values, init, initialValues, dispatch, errors]);

    useEffect(() => {
      // update init values with change on template type
      if (
        params.template_type !==
        JSON.parse(localStorage.getItem("email_template"))
      ) {
        localStorage.setItem(
          "email_template",
          JSON.stringify(params.template_type)
        );
        setTimeout(() => setInit(""), 1000)
      }
    }, [params.template_type, values]);
    return (
      <div className="row">
        {showTemplate && (
          <>
            <div className="col-md-12">
              <InputField
                name={"title"}
                id={"title"}
                label={messages.manageCandidates.title}
                value={values.title}
                changeHandler={(e) =>
                  handleChange(e.target.name, e.target.value)
                }
                isRequired={true}
                disabled={isTitleNotEditable}
                error={errors.title}
                helperText={messages.errors.required}
              />
            </div>
            <div className="col-md-12">
              <InputField
                name={"subject"}
                id={"subject"}
                label={messages.manageCandidates.subject}
                value={values.subject}
                changeHandler={(e) =>
                  handleChange(e.target.name, e.target.value)
                }
                isRequired={true}
                error={errors.subject}
                helperText={messages.errors.required}
              />
            </div>

            <div className="col-md-12">
              <TextEditor
                editorInitialState={init}
                customClasses={"edit-email-template"}
                placeholder={messages.manageCandidates.body + "*"}
                onEditorStateChange={(value, plain_text) => {
                  handleChange("body", {
                    value,
                    plain_text,
                  });
                }}
              />
              {errors.body && (
                <p className="err-msg">{messages.errors.required}</p>
              )}
            </div>
            {values.urls && (
              <div className="col-md-12">
                <InputField
                  name={"urls"}
                  id={"urls"}
                  value={values.urls}
                  isMultiline={true}
                  label=""
                  disabled={true}
                  rows="6"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export const EditEmail = ({
  params,
  isTitleNotEditable,
  addApplicantTitle,
  showTemplate,
}) => {
  const initialValues = useSelector((state) => state.editEmail);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getTemplateEmailRequest({
        ...params,
      })
    );
  }, []);
  return (
    <div className="edit-email-template-wrapper">
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{
          ...initialValues.data,
          title: addApplicantTitle || initialValues.data.title,
        }}
        validate={EditEmailEditorValidation}
        validationSchema={EditEmailSchema}
      >
        {() => {
          return (
            <>
              <Form>
                <Fields
                  isTitleNotEditable={isTitleNotEditable}
                  showTemplate={showTemplate}
                  initialValues={initialValues}
                  params={params}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
