import * as types from "../types/offerTemplateSetup";

const INITIAL_STATE = {
  settings: {},
  default: {},
  placeholders: [],
  attachPDFFile: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_OFFER_TEMPLATE_SETTINGS_RECEIVE:
      return {
        ...state,
        settings: { ...action.payload },
      };
    case types.GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_RECEIVE:
      return {
        ...state,
        default: { ...action.payload },
      };
    case types.GET_OFFER_TEMPLATE_PLACEHOLDERS_RECEIVE:
      return {
        ...state,
        placeholders: { ...action.payload },
      };
    case types.GET_OFFER_FILE_BYTES_RECIEVE:
      return {
        ...state,
        attachPDFFile: action.payload,
      };
    default:
      return state;
  }
};
