import * as types from "../types/viewAllRequests";

const INITIAL_STATE = {
  publishedJobs:{
    list: {
      data: [],
      paging: null
    }
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PUBLISHED_JOBS_RECIEVE:
      return {
        ...state,
        publishedJobs: {
          ...state.publishedJobs,
          list: {
            ...state.publishedJobs.list,
            data: action.payload.data,
            paging: action.payload.paging.total_records
          }
        }
      };
    default:
      return state;
  }
};
