import * as types from "../types/advertisementSetup";

export const requestGetSociaMediaPlaceholder = () => {
    return {
      type: types.SOCIAL_MEDIA_PLACEHOLDER_REQUEST
    }
};

export const receiveGetSociaMediaPlaceholder = (data) => {
    return {
      type: types.SOCIAL_MEDIA_PLACEHOLDER_RECEIVE,
      payload : data
    }
};

export const requestGetSociaMediaSettings = () => {
    return {
      type: types.GET_SOCIAL_MEDIA_SETTINGS_REQUEST
    }
};

export const receiveGetSociaMediaSettings = (data) => {
    return {
      type: types.GET_SOCIAL_MEDIA_SETTINGS_RECEIVE,
      payload : data
    }
};

export const requestAddEditSociaMediaSettings = (data) => {
    return {
      type: types.ADD_EDIT_SOCIAL_MEDIA_REQUEST,
      payload : data
    }
};

export const receiveAddEditSociaMediaSettings = (data) => {
    return {
      type: types.ADD_EDIT_SOCIAL_MEDIA_RECEIVE,
      payload : data
    }
};