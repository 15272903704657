import * as types from "../types/candidatePortalSetup";

const INITIAL_STATE = {
  plan_features: {},
  emailInterval:null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CANDIDATE_PORTAL_SETUP_RECIEVE:
      return {
        ...state,
        ...action.payload
      };
      case types.SUBSCRIPTIONS_HISTORY_PLAN_RECIEVE:
        return {
          ...state,
          historyPlan: action.payload
        }
        case types.GET_RECOMMENDED_CANDIDATE__RECIEVE:
          return {
            ...state,
            emailInterval: action.payload
          }
    default:
      return state;
  }
};
