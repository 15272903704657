import { axiosInstance } from "./config";


let handlerEnabled = true;

const getInterviewEvaluationSetup = async () => {
  return await axiosInstance.get(`/admin/interview_evaluation_setup`);
};

const addInterviewEvaluationSetup = async (data) => {
  return await axiosInstance.put(`/admin/interview_evaluation_setup`, data, {handlerEnabled});
};
export default {
  getInterviewEvaluationSetup,
  addInterviewEvaluationSetup,
};
