import React from "react";
import ReactDOM from "react-dom";
import Theme from "./Theme";
import { Provider } from "react-redux";
import store from "./store";
import { addLocaleData } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as ar from "react-intl/locale-data/ar";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./components/SocialLogins/Microsoft/authConfig";
const msalInstance = new PublicClientApplication(msalConfig);

addLocaleData(en);
addLocaleData(ar);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <Theme />
    </Provider>
  </MsalProvider>,
  document.querySelector("#root")
);
