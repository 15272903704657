import { call, put , takeLatest } from "redux-saga/effects";
import API from "../../network/apis/departmentsSetupAPI";
import * as ACTION from "../actions/DepartmentsSetup";
import * as type from "../types/DepartmentsSetup";
import store from "../../store";
import history from "../../routes/History";
import {
  dispatchError,
  showSnackbar,
  pushToUrlNewParam,
} from "../../utils/shared";

export function* addNewDepartment(action) {
  try {
    const response = yield call(API.addDepartment, action.payload.data);
    yield put(ACTION.receiveAddNewDepartment(response.data.data));
    showSnackbar("savedSuccessfuly");
    action.payload.type !== "saveNew" && history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAllDepartments(action) {
  try {
    const response = yield call(API.getAllDepartments, action.payload);
    yield put(ACTION.receiveGetAllDepartments(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getSingleDepartment(action) {
  try {
    const response = yield call(API.getDepartment, action.payload);
    yield put(ACTION.receiveGetSingleDepartment(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* editDepartment(action) {
  try {
    const response = yield call(API.editDepartment, action.payload);
    yield put(ACTION.receiveEditDepartment(response.data));
    showSnackbar("savedSuccessfuly");
    history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deleteDepartment({
  payload: { departmentId, departmentsLength, activePage },
}) {
  try {
    const response = yield call(API.deleteDepartment, departmentId);
    yield put(ACTION.receiveDeleteDepartment(response.data));
    store.dispatch(
      ACTION.requestGetAllDepartments(
        departmentsLength === 1 && activePage > 1 ? activePage - 1 : activePage
      )
    );
    pushToUrlNewParam(
        departmentsLength === 1 && activePage > 1 ? activePage - 1 : activePage
    );
    showSnackbar("deletedSuccessfully");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}


export function* DepartmentsSagasWatch() {
  yield takeLatest(type.ADD_DEPARTMENT_REQUEST, addNewDepartment);
  yield takeLatest(type.GET_DEPARTMENTS_LIST_REQUEST, getAllDepartments);
  yield takeLatest(type.GET_SINGLE_DEPARTMENT_REQUEST, getSingleDepartment);
  yield takeLatest(type.EDIT_DEPARTMENT_REQUEST, editDepartment);
  yield takeLatest(type.DELETE_DEPARTMENT_REQUEST, deleteDepartment);
}