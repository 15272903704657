import { axiosInstance } from "./config";

let handlerEnabled = true;

const addRejectionReasonRequest = async (data) => {
  return await axiosInstance.post(
    `/admin/companyRejectionReasons/add_rejectionReason`,
    data,
    { handlerEnabled }
  );
};
const editRejectionReasonRequest = async (data) => {
  return await axiosInstance.post(
    `/admin/companyRejectionReasons/edit_rejectionReason`,
    data,
    { handlerEnabled }
  );
};
const deleteRejectionReasonRequest = async (data) => {
  return await axiosInstance.delete(
    `/admin/companyRejectionReasons/delete_rejectionReason`,
    {
      data,
    },
    { handlerEnabled }
  );
};
const getRejectionReasonsRequest = async ({ page_size, pageNumber }) => {
  return await axiosInstance.get(
    `/admin/companyRejectionReasons/getlist?page_size=${page_size}&page_number=${pageNumber}`,
    { handlerEnabled }
  );
};
const getRejectionReasonRequest = async ({ id }) => {
  return await axiosInstance.get(
    `/admin/companyRejectionReasons/get_rejectionReason/${id}`,
    { handlerEnabled }
  );
};
export default {
  deleteRejectionReasonRequest,
  addRejectionReasonRequest,
  editRejectionReasonRequest,
  getRejectionReasonsRequest,
  getRejectionReasonRequest,
};
