import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.scss';

export const Loader = () => {
    return(
        <div className="spinnerContainer d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" role="status" variant="dark" className="align-self-center">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    )
}