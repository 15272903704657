import { call, put } from "redux-saga/effects";
import API from "../../network/apis/integrationApi";
import * as ACTION from "../actions/Integrations";
import {
  dispatchError,
  showSnackbar,
  showWarningSnackbar,
} from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/Integrations";
import history from "../../routes/History";

export function* compnayIntegration(action) {
  try {
    const response = yield call(API.getCompanyIntegrations, action.payload);
    yield put(ACTION.InteggrationList(response.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* compnayIntegrationStatus(action) {
  try {
    const response = yield call(API.getCompanyIntegrationsStatus, action.payload);
    yield put(ACTION.recieveInteggrationStatus(response.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* compnayUnifonicIntegration(action) {
  try {
    const response = yield call(
      API.getCompanyUnifonicIntegration,
      action.payload
    );
    yield put(ACTION.CompanyUnifonicResponse(response.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* compnayAddEditUnifonicIntegration(action) {
  try {
    yield call(API.addEditCompanyUnifonicIntegration, action.payload);
    showSnackbar("savedSuccessfuly");
    history.goBack();
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* compnayOffice365Integration() {
  try {
    const response = yield call(API.getCompanyOffice365Integration);
    yield put(ACTION.recieveCompanyOffice365(response.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* addEditCompanyOffice365Integration(action) {
  try {
    yield call(API.addEditCompanyOffice365Integration, action.payload);
    showSnackbar("done");
    history.push("/integrations");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* getUserCalendarRequest(action) {
  let users = [];
  try {
    const response = yield call(API.getUserCalendarRequest, action.payload);
    yield put(ACTION.getUserCalendarRecieve(response.data.data));
    const no_calendars = response.data.data
      .filter((item) => !item.calendar_obj_list)
      .map((i) => i.user_id);
    yield action.payload.agents.forEach((agent) => {
      if (no_calendars.includes(agent.id)) {
        users.push(
          action.payload.lang === "ar" ? agent.name_ar : agent.name_en
        );
      }
    });
    if (users.length > 0) {
      let names = users.join(", ");
      yield put(
        ACTION.showSnackbarIntegrationAction({
          message:
            action.payload.lang === "ar"
              ? `التقويم الخاص بالمستخدم ${names} غير متاح.`
              : `The calendar of ${names} is not available.`,
          snackType: "warning",
        })
      );
    }
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}

export function* IntegrationSagasWatch() {
  yield takeLatest(type.INTEGRATION_REQUEST, compnayIntegration);
  yield takeLatest(type.REQUEST_INTEGRATION_STATUS, compnayIntegrationStatus);

  yield takeLatest(
    type.UNIFONIC_INTEGRATION_REQUEST,
    compnayUnifonicIntegration
  );
  yield takeLatest(
    type.ADD_EDIT_UNIFONIC_INTEGRATION_REQUEST,
    compnayAddEditUnifonicIntegration
  );
  yield takeLatest(
    type.OFFICE365_INTEGRATION_REQUEST,
    compnayOffice365Integration
  );
  yield takeLatest(
    type.ADD_EDIT_OFFICE365_INTEGRATION_REQUEST,
    addEditCompanyOffice365Integration
  );
  yield takeLatest(type.GET_USER_CALENDAR_REQUEST, getUserCalendarRequest);
}
