import * as types from "../types/Agent";

const INITIAL_STATE = {}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.GET_AGENT_PROFILE_RECEIVE:
            return { ...state, ...action.payload};
        default: return state;
    }
}