import * as types from "../types";

const INITIAL_STATE = {
  Open: false,
  socialLoginPreContent: {},
  userToken:localStorage.getItem("token")
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_RECEIVE:
      return { ...state, ...action.payload };
    case types.VALIDATE_DOMAIN_RECEIVE:
      return { ...state, err: action.payload };
    case types.FORGET_PASSWORD_RECEIVE:
      return { ...state, ...action.payload };
    case types.RESET_PASSWORD_RECEIVE:
      return { ...state, ...action.payload };
    case types.SOCIAL_LOGIN_PREREQUESTION_MODAL_REQUEST:
      return {
        ...state,
        Open: action.payload.open,
      };
    case types.SOCIAL_LOGIN_PREREQUESTION_FILLED_REQUEST:
      return {
        ...state,
        Open: false,
      };
    case types.SOCIAL_LOGIN_POPUP_OPENED_REQUEST:
      return {
        ...state,
        Open: false,
      };
    case types.SOCIAL_LOGIN_PHONE_NUMBER_REQUIRED:
      return {
        ...state,
        phoneNumberRequired: true,
        socialLoginPreContent: action.payload,
        Open: true,
      };
    default:
      return state;
  }
};
