import * as types from "../types/DepartmentsSetup";

export const requestAddNewDepartment = (data , type) => {
  return {
    type: types.ADD_DEPARTMENT_REQUEST,
    payload: {data , type}
  };
};

export const receiveAddNewDepartment = (data , type) => {
  return {
    type: types.ADD_DEPARTMENT_RECEIVE,
    payload: {data , type}
  };
};

export const requestGetAllDepartments = data => {
  return {
    type: types.GET_DEPARTMENTS_LIST_REQUEST,
    payload: data
  };
};

export const receiveGetAllDepartments = data => {
  return {
    type: types.GET_DEPARTMENTS_LIST_RECEIVE,
    payload: data
  };
};

export const requestGetSingleDepartment = data => {
  return {
    type: types.GET_SINGLE_DEPARTMENT_REQUEST,
    payload: data
  };
};

export const receiveGetSingleDepartment = data => {
  return {
    type: types.GET_SINGLE_DEPARTMENT_RECEIVE,
    payload: data
  };
};

export const requestEditDepartment = data => {
  return {
    type: types.EDIT_DEPARTMENT_REQUEST,
    payload: data
  };
};

export const receiveEditDepartment = data => {
  return {
    type: types.EDIT_DEPARTMENT_RECEIVE,
    payload: data
  };
};

export const requestDeleteDepartment = data => {
  return {
    type: types.DELETE_DEPARTMENT_REQUEST,
    payload: data
  };
};

export const receiveDeleteDepartment = data => {
  return {
    type: types.DELETE_DEPARTMENT_RECEIVE,
    payload: data
  };
};
