import { axiosInstance } from "./config";
let handlerEnabled = true;

const getflows = async (page_number) =>
  await axiosInstance.get(`/company/pipelineSetup/get_piplineSetupFlows`, {
    params: {
      page_number,
      page_size: 10,
    },
    handlerEnabled,
  });

const saveNewFlow = async (data) =>
  await axiosInstance.post(
    `/company/pipelineSetup/add_CandidateApplicationFlowSetup`,
    data,
    { handlerEnabled }
  );

const requestEditFlowDetails = async (id) =>
  await axiosInstance.get(
    `/company/pipelineSetup/get_piplineFlow_setup/${id}`,
    { handlerEnabled }
  );
const saveEditedFlow = async (data) =>
  await axiosInstance.post(
    `/company/pipelineSetup/update_CandidateApplicationFlowSetup`,
    data,
    { handlerEnabled }
  );

const saveSingleFlowStage = async (data) =>
  await axiosInstance.post(
    `/company/pipelineSetup/update_CandidateApplicationStageSetup`,
    data,
    { handlerEnabled }
  );

const deleteStage = async (id) =>
  await axiosInstance.delete(`/company/pipelineSetup/stages/${id}`, {
    handlerEnabled,
  });

const moveApplicantsToStage = async ({ from_id, to_id }) =>
  await axiosInstance.put(
    `/company/pipelineSetup/stages/${from_id}/move/${to_id}`,
    {},
    { handlerEnabled }
  );

const deleteFlow = async (id) =>
  await axiosInstance.delete(`/company/pipelineSetup/${id}`, {
    handlerEnabled,
  });
const setDefaultFlow = async (id) =>
  await axiosInstance.put(
    `/company/pipelineSetup/update_default_pipelineFLow/${id}`,
    {},
    {
      handlerEnabled,
    }
  );
const disableSendOffer = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_Send_offer`, {
    handlerEnabled,
  });
const disablePipeline = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_pipeline`, {
    handlerEnabled,
  });
const adsSettings = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_advertisement_setting`, {
    handlerEnabled,
  });

export default {
  getflows,
  deleteFlow,
  saveNewFlow,
  requestEditFlowDetails,
  saveEditedFlow,
  deleteStage,
  moveApplicantsToStage,
  setDefaultFlow,
  saveSingleFlowStage,
  disableSendOffer,
  disablePipeline,
  adsSettings
};
