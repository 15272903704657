import * as types from "../types/wizardSetup";

export const wizardSetupRequest = payload => ({
  type: types.WIZARD_SETUP_REQUEST,
  payload
});

export const setWizardStep = payload => ({
  type: types.SET_WIZARD_STEP,
  payload
});
export const setupWizardIndex = payload => {
  return {
    type: types.SETUP_WIZARD_INDEX,
    payload
  };
}

export const phoneValidationRequest = payload => ({
  type: types.PHONE_VALIDATION_REQUEST,
  payload
});

export const phoneValidationRecieve = payload => ({
  type: types.PHONE_VALIDATION_RECIEVE,
  payload
});

export const isPhoneValid = (data) => {
  return{
    type: types.IS_PHONE_VALID,
    payload: data 
  }
};

