export const DASHBOARD_CARDS_DATA_REQUEST = 'DASHBOARD_CARDS_DATA_REQUEST'
export const DASHBOARD_CARDS_DATA_RECEIVE = 'DASHBOARD_CARDS_DATA_RECEIVE'
export const REPORT_DATA_REQUEST = 'REPORT_DATA_REQUEST'
export const REPORT_DATA_RECIEVE = 'REPORT_DATA_RECIEVE'
export const DOWNLOAD_PDF_FILE = 'DOWNLOAD_PDF_FILE';
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_RECIEVE = 'VERIFY_EMAIL_RECIEVE'
export const EXPORT_EXCEL_REQUEST = 'EXPORT_EXCEL_REQUEST'
export const EXPORT_EXCEL_RECIEVE = 'EXPORT_EXCEL_RECIEVE'
export const EXPORT_CVS_REQUEST = 'EXPORT_CVS_REQUEST'
export const EXPORT_CVS_RECIEVE = 'EXPORT_CVS_RECIEVE'