import * as types from "../types/SalaryGrade";

export const requestGetSalaryGrade = (data) => {
    return {
      type: types.GET_SALARY_GRADE_REQUEST,
      payload : data
    }
};

export const receiveGetSalaryGrade = (data) => {
    return {
      type: types.GET_SALARY_GRADE_RECEIVE,
      payload : data
    }
};

export const requestAddEditSalaryGrade = (data , type) => {
    return {
      type: types.ADD_EDIT_SALARY_GRADE_REQUEST,
      payload : {data , type}
    }
};

export const receiveAddEditSalaryGrade = (data) => {
    return {
      type: types.ADD_EDIT_SALARY_GRADE_RECEIVE,
      payload : data
    }
};

export const requestDeleteSalaryGrade = (data) => {
    return {
      type: types.DELETE_SALARY_GRADE_REQUEST,
      payload : data
    }
};

export const receiveDeleteSalaryGrade = (data) => {
    return {
      type: types.DELETE_SALARY_GRADE_RECEIVE,
      payload : data
    }
};

