import { axiosInstance } from "./config";

let handlerEnabled = true;

const requestAgentsExcelSample = async () => {
  return await axiosInstance.get(`/admin/agent/Get_AgentSampleExcelUrl`, {
    handlerEnabled,
  });
};
const requestUploadAgentsExcel = async (data) => {
  return await axiosInstance.post(`/admin/agent/Upload_AgentExcel`, data,  {
    handlerEnabled,
  });
};
const requestSalaryExcelSample = async () => {
  return await axiosInstance.get(`/admin/salary_grade/Get_SalaryGradeSampleExcelUrl`, {
    handlerEnabled,
  });
};
const requestUploadSalaryExcel = async (data) => {
  return await axiosInstance.post(`/admin/salary_grade/Upload_SalaryGradeExcel`, data,  {
    handlerEnabled,
  });
};

const UploadApplicantsExcel = async (data) => {
  return await axiosInstance.post(`/admin/candidates/Upload_ApplicantExcel`, data,  {
    handlerEnabled,
  });
};

const downloadApplicantsSample = async  ()=> {
  return await axiosInstance.get(`/admin/candidates/Get_ApplicantExcelSampleUrl`,  {
    handlerEnabled,
  });
};

export default {
  requestAgentsExcelSample,
  requestUploadAgentsExcel,
  requestSalaryExcelSample,
  requestUploadSalaryExcel,
  UploadApplicantsExcel,
  downloadApplicantsSample
};
