import React, { useState, useEffect } from "react";
import "./SideMenu.scss";
import cssVariables from 'scss/_export-variables.scss';

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Popper,
  MenuItem,
  MenuList,
  Paper,
  Fade,
  ClickAwayListener,
} from "@material-ui/core";
import ProgressBar from "@ramonak/react-progress-bar";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { isUserHasPermission, isSuperAdmin } from "../../utils/Permissions";
import { setClickedPageIndex } from "../../store/actions/clickedPage";
import { setStartMenu  } from "../../store/actions/startYourJournyMenu";
import { injectIntl } from "react-intl";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import logo from "../../assets/images/0-01.png";
import {
  DashboardIcon,
  RequestIcon,
  ReportIcon,
  ManpowerRequestIcon,
  TalentPoolIcon,
  InterviewIcon,
  JobOpeningIcon,
  SettingIcon,
} from "./InjectedImages";
import { Block } from "@material-ui/icons";
import HighlightOff from "@material-ui/icons/PanoramaFishEye";
import TaskAlt from "@material-ui/icons/CheckCircleOutlineRounded";
const StartYourJournyMenu = ({ intl }) => {
  const lang = useSelector((state) => state.locale.lang);
  const startYourJournyMenu = useSelector((state) => state.startYourJournyMenu);
  const [open, setOpen] = useState(false);
  const [poperType, setPoperType] = useState("manpower");
  const [openApprovals, setOpenApprovals] = useState(false);
  const [openJobOpening, setJobOpening] = useState(false);
  const {steps} = useSelector((state) => state.company);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { logo_url } = useSelector((state) => state.company);
  const locale = intl.locale;

  const company_logo =
    localStorage.getItem("company_image_url") === null
      ? logo_url
      : localStorage.getItem("company_image_url");
  const activePageIndex = useSelector((state) => state.activePageIndex.index);
  const dispatch = useDispatch();
  const { messages } = intl;

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      openMenu(false);
    }
  }

  const handleClickPopper = (newPlacement, type) => (event) => {
    setPoperType(type);
    if (startYourJournyMenu.sideMenuWidth === "70px") {
      setAnchorEl(event.currentTarget);
      setOpenPopper((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    } else {
      if(type === "approvals")  setOpenApprovals(!openApprovals);
      if(type==="manpower") setOpen(!open)
      if(type==="jobOpening")setJobOpening(!openJobOpening)
    }
  };

  useEffect(() => {
    locale === "ar"
      ? startYourJournyMenu.sideMenuWidth === "70px"
        ? dispatch(
          setStartMenu({
              sideMenuContent: "none",
              mainMrginLeft: window.location.href.includes("interview")
                ? "0"
                : "40px",
              mainMarginRight: window.location.href.includes("interview")
                ? "70px"
                : "120px",
              navbarMarginLeft: "0px",
              navbarMarginRight: "70px",
              mainMarginTop: window.location.href.includes("interview")
                ? 0
                : "30px",
              backgroundColor: window.location.href.includes("interview")
                ? "rgba(246, 246, 246, 1)"
                : "#fff",
              curvedNavbLeft: "auto",
              curvedNavbRight: "3.2em",
            })
          )
        : dispatch(
          setStartMenu({
              sideMenuContent: "block",
              mainMrginLeft: window.location.href.includes("interview")
                ? "0"
                : "40px",
              mainMarginRight: window.location.href.includes("interview")
                ? window.innerWidth < 900
                  ? "0"
                  : "250px"
                : window.innerWidth < 900
                ? "40px"
                : "290px",
              navbarMarginLeft: "0px",
              navbarMarginRight: "250px",
              mainMarginTop: window.location.href.includes("interview")
                ? 0
                : "30px",
              backgroundColor: window.location.href.includes("interview")
                ? "rgba(246, 246, 246, 1)"
                : "#fff",
              curvedNavbLeft: "auto",
              curvedNavbRight: "",
            })
          )
      : startYourJournyMenu.sideMenuWidth === "70px"
      ? dispatch(
        setStartMenu({
            sideMenuContent: "none",
            mainMrginLeft: window.location.href.includes("interview")
              ? "70px"
              : "120px",
            mainMarginRight: window.location.href.includes("interview")
              ? "0"
              : "40px",
            navbarMarginLeft: "70px",
            mainMarginTop: window.location.href.includes("interview")
              ? 0
              : "30px",
            backgroundColor: window.location.href.includes("interview")
              ? "rgba(246, 246, 246, 1)"
              : "#fff",
            navbarMarginRight: "0px",
            curvedNavbLeft: "3.2em",
            curvedNavbRight: "auto",
          })
        )
      : dispatch(
        setStartMenu({
            sideMenuContent: "block",
            mainMarginTop: window.location.href.includes("interview")
              ? 0
              : "30px",
            backgroundColor: window.location.href.includes("interview")
              ? "rgba(246, 246, 246, 1)"
              : "#fff",
            mainMrginLeft: window.location.href.includes("interview")
              ? window.innerWidth < 900
                ? "0"
                : "250px"
              : window.innerWidth < 900
              ? "40px"
              : "290px",
            mainMarginRight: window.location.href.includes("interview")
              ? "0"
              : "40px",
            navbarMarginLeft: "250px",
            navbarMarginRight: "0px",
             curvedNavbLeft: "15em",
            // curvedNavbRight: "auto",
          })
        );
       closeNav()
  }, [locale, startYourJournyMenu.sideMenuWidth, window.location.href, dispatch]);

  
  const closeNav = () => {
    setOpen(false);
    setOpenApprovals(false);
    setJobOpening(false)
    lang === "en"
      ? dispatch(
        setStartMenu({
            closeBtn: "none",
            openBtn: "block",
            companyLogoSideMenuHeight: "50px",
            companyLogoSideMenuWidth: "50px",
            sideMenuContent: "none",
            sideMenuContentPaddingTop: "16px",
            sideMenuContentPaddingBottom: "16px",
            sideMenuWidth: "250px",
            mainMrginLeft: "120px",
            mainMarginRight: "40px",
            navbarMarginLeft: "70px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "3.2em",
            curvedNavbRight: "auto",
            showPopover: "block",
            completeNave:"69px",
            visibility:"hidden"
          })
        )
      : dispatch(
          setStartMenu({
            closeBtn: "none",
            openBtn: "block",
            companyLogoSideMenuHeight: "50px",
            companyLogoSideMenuWidth: "50px",
            sideMenuContent: "none",
            sideMenuContentPaddingTop: "16px",
            sideMenuContentPaddingBottom: "16px",
            sideMenuWidth: "250px",
            mainMrginLeft: "40px",
            mainMarginRight: "120px",
            navbarMarginLeft: "0px",
            navbarMarginRight: "70px",
            curvedNavbLeft: "auto",
            curvedNavbRight: "3.2em",
            showPopover: "block",
            completeNave:"70px",
            visibility:"hidden"
          })
        );
  };

  const openNav = () => {
    lang === "en"
      ? dispatch(
        setStartMenu({
            closeBtn: "block",
            openBtn: "none",
            companyLogoSideMenuHeight: "100px",
            companyLogoSideMenuWidth: "100px",
            sideMenuContent: "block",
            sideMenuContentPaddingTop: "8px",
            sideMenuContentPaddingBottom: "8px",
            sideMenuWidth: "250px",
            mainMrginLeft: "290px",
            mainMarginRight: "40px",
            navbarMarginLeft: "250px",
            navbarMarginRight: "0px",
            // curvedNavbLeft: "16em",
            closebtnStartLeft:"1077px",
            visibility:"visible",
            curvedNavbRight: "auto",
             showPopover: "none",
            completeNave:"-164px"
          })
        )
      : dispatch(
        setStartMenu({
            closeBtn: "block",
            openBtn: "none",
            companyLogoSideMenuHeight: "100px",
            companyLogoSideMenuWidth: "100px",
            sideMenuContent: "block",
            sideMenuContentPaddingTop: "8px",
            sideMenuContentPaddingBottom: "8px",
            sideMenuWidth: "250px",
            mainMrginLeft: "40px",
            mainMarginRight: "290px",
            navbarMarginLeft: "0px",
            navbarMarginRight: "250px",
            curvedNavbLeft: "auto",
            curvedNavbRight: "16em",
            visibility:"visible",
            showPopover: "none",
            completeNave:"-164px"
          })
        );
  };

  const handleListItemClick = (event, index) => {
    dispatch(setClickedPageIndex({ index }));
    index ==="/Archive" && localStorage.setItem("archived",true) ;
    index === "/jobOpenings" && localStorage.setItem("archived", false);
  };
  const setStepperToInitialState = () => {
    localStorage.setItem("createStepperIndex", 0);
    localStorage.removeItem("jobOpeningId");
    localStorage.removeItem("editInprogressRequest");
    localStorage.removeItem("editMode");
    localStorage.setItem("editStep", 0);
    window.location.reload();
  };

  const renderListItem = (
    link,
    message,
    title,
    icon,
    opacity,
    isDone
  ) => {
    return (
      <>
        <Link to={!isDone?link:{}}>
          <Tooltip title={message} placement="top-end">
            <ListItem
              className="listItemStart"
              button
              style={{
                paddingTop: startYourJournyMenu.sideMenuContentPaddingTop,
                paddingBottom: startYourJournyMenu.sideMenuContentPaddingBottom,
              }}
              selected={activePageIndex === link}
              onClick={(event) =>{ 
                if(!isDone)
                handleListItemClick(event, link)}}
             
            >
              <ListItemIcon>
        
               <span style={{color:isDone?"rgba(0, 163, 58, 1)":"black"}}> {isDone?<TaskAlt/>:<HighlightOff/>} </span>
              </ListItemIcon>
              <ListItemText
                className="medium_font"
                primary={message}
                style={{
                  display: startYourJournyMenu.sideMenuContent,
                  transition: "0.5s",
                  color:isDone?"rgba(0, 163, 58, 1)":"black"
                }}
              />
            </ListItem>
          </Tooltip>
        </Link>
      </>
    );
  };

  const renderMenuItem = (link, message) => {
    return (
      <Link to={link}>
        <MenuItem
          onClick={handleCloseMenu}
          className={
            lang === "ar"
              ? "text-right medium_font text-dark"
              : "text-left medium_font text-dark"
          }
          dir={lang === "ar" ? "ltr" : "rtl" }
        >
          {message}
        </MenuItem>
      </Link>
    );
  };

  return (
    <React.Fragment>
      <Popper
        style={{ display: startYourJournyMenu.showPopover }}
        dir={lang === "ar" ? "ltr": "rtl"}
        className={lang === "ar" ?  "text-left":"text-right" }
        open={openPopper}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
             
              <ClickAwayListener onClickAway={handleCloseMenu}>
                {poperType === "manpower" ? (
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <div onClick={setStepperToInitialState}>
                      {renderMenuItem(
                        "/manpowerRequests",
                        messages.sideMenu.createNewRequest
                      )}
                    </div>
                    {renderMenuItem(
                      "/viewAllRequests",
                      messages.sideMenu.viewAllrequests
                    )}
                  </MenuList>
                ) : (
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {renderMenuItem(
                      "/Requests",
                      messages.sideMenu.agentsRequests
                    )}
                    {renderMenuItem(
                      "/candidateApprovals",
                      messages.sideMenu.candidatesRequests
                    )}
                    {renderMenuItem(
                      "/candidateTalentPool",
                      messages.sideMenu.TalentPool
                    )}
                  </MenuList>
                )}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div
        className={lang==="en"?"sideStartEn":"sideStartAr"}
        id="mySidenavStart"
        style={{ width: startYourJournyMenu.sideMenuWidth  ,visibility:startYourJournyMenu.visibility}}
      >
             <div className={lang==="en"?"mm_en":"mm_ar"} style={lang==="en"?{ left: startYourJournyMenu.completeNave }:{ right: startYourJournyMenu.completeNave }} >

              
              <span>{messages.dashboardContent.completeyourprofile}</span>
              
              <button
            className="btn closebtnStart "
            onClick={closeNav}
            // style={{ display: startYourJournyMenu.closeBtn ,left: startYourJournyMenu.closebtnStartLeft}}
            style={lang==="en"?{ left: startYourJournyMenu.closebtnStartLeft, display: startYourJournyMenu.closeBtn }:{ right: startYourJournyMenu.closebtnStartLeft, display: startYourJournyMenu.closeBtn }}
          >
            <ArrowBackIosIcon fontSize="small" />
          </button>
          <button
            className="btn openBtnStart"
            onClick={openNav}
            style={{ display: startYourJournyMenu.openBtn }}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </button>
          </div>

    
        <div
          className="curvedNavStart"
          style={{
            left: startYourJournyMenu.curvedNavbLeft,
            right: startYourJournyMenu.curvedNavbRight,
          }}
        >
    
        </div>
        <diV>    <h5 className="main-Title    ">{messages.dashboardContent.completeyourprofile}</h5></diV>
    

        <List
          component="nav"
          className="sideMenuItemListStart"
          aria-label="sideMenu"
        >  

          {renderListItem(
            "/quickManpowerRequests",
            messages.dashboardContent.Publishajob,
            "Dashboard",
            <DashboardIcon />,
            1,
           steps?.publish_job_is_completed
          )}
        
          <>

            {
              renderListItem(
                "/TalentPool?page=1",
                messages.dashboardContent.FilterTalentPool,
                "My interviews",
                <HighlightOff />,
                1,
                steps?.filter_talent_pool_is_completed
              )}
            {
              renderListItem(
                "/jobOpenings?page=1",
                messages.dashboardContent.ManageCandidate,
                "Talent Pool",
                <TalentPoolIcon />,
                1,
                steps?.manage_candidates_is_completed

              )}
            {
              renderListItem(
                "/CandidatePortalSetup",
                messages.dashboardContent.BuildyourCompany,
                "Report",
                <ReportIcon />,
                1,
                steps?.build_your_company_is_completed
              )}
            {
              renderListItem(
                "/agent",
                messages.dashboardContent.Addyourteam,
                "Settings",
                <SettingIcon />,
                1,
                steps?.add_your_team_is_completed
              )}
          </>
          {/* )} */}
        </List>
        <ProgressBar completed={steps?.progress_percent}   className="wrappers"
         dir={lang==="en"?"ltr":"rtl"}
         baseBgColor={cssVariables.primaryColor}
         bgColor={cssVariables.secondaryColor}
         customLabelStyles={{fontSize:"12px"}}
         />
      </div>
    </React.Fragment>
  );
};

const StartYourJournyMenuComponent = injectIntl(StartYourJournyMenu);

export default StartYourJournyMenuComponent;
