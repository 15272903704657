import * as types from "../types/uploadExcel";

const INITIAL_STATE = {
  fileURLAgents: "",
  fileURLSalary: "",
  fileUrlApplicants:'',
  salaryExcelFileUploaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.AGENTS_EXCEL_SAMPLE_RECEIVE:
      return {
        ...state,
        fileURLAgents: action.payload,
      };
    case types.SALARY_EXCEL_SAMPLE_RECEIVE:
      return {
        ...state,
        fileURLSalary: action.payload,
      };
    case types.SALARY_EXCEL_FILE_UPLOADED:
      return {
        ...state,
        salaryExcelFileUploaded: action.payload,
      };
      case types.DOWNLOAD_APPLICANTS_SAMPLE_RECIEVE:
      return {
        ...state,
        fileUrlApplicants: action.payload,
      };
    default:
      return state;
  }
};
