import React from "react";
import { useDispatch } from "react-redux";
import googleIcon from "../../assets/images/ic_google.svg";
import microsoftIcon from "../../assets/images/ic_microsoft.svg";
import {
  googleSyncRequest,
  microsoftSyncRequest,
} from "../../store/actions/agents";
import "./SyncingAccounts.scss";

const SyncingAccounts = () => {
  const dispatch = useDispatch();
  const handleGoogleSync = () => {
    localStorage.setItem("show_email_sync_reminder", false);
    dispatch(googleSyncRequest());
  };
  const handleMicrosoftSync = () => {
    localStorage.setItem("show_email_sync_reminder", false);
    dispatch(microsoftSyncRequest());
  };
  return (
    <>
      <div className="agent-sync-actions d-flex">
        {/* <div
          className="d-flex align-items-center justify-content-between agent-sync-actions-buttons px-4 py-3 mx-3"
          onClick={handleGoogleSync}
        >
          <img src={googleIcon} alt="google" />
          <p className="mb-0 pb-0 px-2">Google</p>
        </div> */}
        <div
          className="d-flex align-items-center justify-content-between agent-sync-actions-buttons px-4 py-3"
          onClick={handleMicrosoftSync}
        >
          <img src={microsoftIcon} alt="google" />
          <p className="mb-0 pb-0 px-2">Microsoft</p>
        </div>
      </div>
    </>
  );
};

export default SyncingAccounts;
