import * as jwt_decode from 'jwt-decode';
import Moment from 'moment';
import moment from "moment-timezone";
import { extendMoment } from 'moment-range';
import History from 'routes/History';
import store from '../store';
import messages from '../assets/Local/messages';
import { showSnackbarAction } from '../store/actions/snackbar';
import { shallowEqual } from 'recompose';
export function getDecodedAccessToken(token) {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}
export function pushToUrlNewParam(page) {
  History.push({
    search: `?page=${page}`,
  });
}
export const setURLParams = (paramName, paramValue) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.set(paramName, paramValue);
  window.history.replaceState(null, null, "?" + urlParams.toString());

};
export const deleteUrlParam = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.delete(paramName);
  window.history.replaceState(null, null, "?" + urlParams.toString());
}

export const encodeObj = (obj) => {
  return encodeURI(JSON.stringify(obj))
}

export const decodeObj = (encodedObj) => {
  let stringfiedObj = decodeURI(encodedObj)
  return (JSON.parse(stringfiedObj))
}

export const shallowEqualValues = (init, value) => {
  return shallowEqual(init, value);
};

export function dispatchError(data, message) {
  const errorMsg = data
    ? data.error
      ? data.error.message
      : data.Error.Message
    : message;
  store.dispatch(showSnackbarAction(errorMsg, 'error'));
}
export function getTomorrowDate() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}
export function getDomain() {
  //new env
  if (window.location.href.includes("stage.hrcom.site")) {
    let host = window.location.host;
    let parts = host.split('.');
    let domain = parts[0].split(`-company`)[0];
    const domain_name = process.env.REACT_APP_DOMAIN
      ? process.env.REACT_APP_DOMAIN
      : domain;
    localStorage.setItem('domain', domain_name);
    return domain_name;
    //adaa live
  } else if (window.location.href.includes("hrcom.co")) {
      let host = window.location.host;
      let parts = host.split('.');
      let domain = parts[0].split(`.company`)[0];
      const domain_name = process.env.REACT_APP_DOMAIN
        ? process.env.REACT_APP_DOMAIN
        : domain;
      localStorage.setItem('domain', domain_name);
      return domain_name;
     
  }
  else {
    let host = window.location.host;
    let parts = host.split('.');
    let domain = parts[0].split(`-${process.env.REACT_APP_URL_CONFIG}`)[0];
    const domain_name = process.env.REACT_APP_DOMAIN
      ? process.env.REACT_APP_DOMAIN
      : domain;
    localStorage.setItem('domain', domain_name);
    return domain_name;
  }
}
export function getURLParams(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}
export function showSnackbar(message) {
  const lang = store.getState().locale.lang;
  store.dispatch(
    showSnackbarAction(messages[lang].snackbar[message], 'success')
  );
}

export function showWarningSnackbar(message) {
  store.dispatch(showSnackbarAction(message, 'warning'));
}
const patternValidURL = new RegExp(
  '^' +
  // protocol identifier (optional)
  // short syntax // still required
  '(?:(?:(?:https?|ftp):)?\\/\\/)' +
  // user:pass BasicAuth (optional)
  '(?:\\S+(?::\\S*)?@)?' +
  '(?:' +
  // IP address exclusion
  // private & local networks
  '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
  '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
  '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
  '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
  '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '|' +
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  '(?:' +
  '(?:' +
  '[a-z0-9\\u00a1-\\uffff]' +
  '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
  ')?' +
  '[a-z0-9\\u00a1-\\uffff]\\.' +
  ')+' +
  // TLD identifier name, may end with dot
  '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
  ')' +
  // port number (optional)
  '(?::\\d{2,5})?' +
  // resource path (optional)
  '(?:[/?#]\\S*)?' +
  '$',
  'i'
);
export const ValidURL = (val) => {
  if (val.length === 0) {
    return false;
  } else {
    return !patternValidURL.test(val);
  }
};
export const deepUrlValidation = (val) => {
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  var regexUrl = new RegExp(expression);
  const isMatch = (val).match(regexUrl)
  return !isMatch
};

export const isSubscriptionEnd = () => {
  const endDate = new Date(localStorage.getItem('subscription_end'));
  const today = new Date();
  return today >= endDate;
};

export const getRemainingPlanTime = (countDownTime) => {
  const today = new Date();
  const moment = extendMoment(Moment);
  const currentTime = moment(today);
  const expirationTime = moment(countDownTime);
  const duration = moment.duration(expirationTime.diff(currentTime));
  return duration;
};
export const expireAfter3Days = (date) => {
  const endDate = new Date(date);
  endDate.setDate(endDate.getDate() + 3);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return today > endDate;
};

export const expireDatePicker = (date) => {
  const endDate = new Date(date);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return today <= endDate;
};

export const isUserGhost = (is_a_ghost_user) => {
  if (is_a_ghost_user) window.localStorage.setItem('is_ghost', true);
  if (!is_a_ghost_user && window.localStorage.getItem('is_ghost')) {
    window.localStorage.setItem('is_ghost', false);
  }
};
export const convertRowTextWithNewLineToHtml = (text) => {
  var arrStr = text.split(/[\n]/);
  let content = '';
  arrStr.forEach((item) => {
    let result = item.length > 0 ? `<div>${item}</div>` : false;
    content = result ? content + result : content;
  });
  return content;
};
export const blobToBase64 = async (blob, fn) => {
  return new Promise((resolve, onerror) => {
    //represent the  blob image as a file,
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (event) {
      resolve(event.target.result);
    };
  });
};

export  const isToday = (pastDate) => {
  const formatedGiven = moment.utc(pastDate).local().format("YYYY-MM-DD");
  const given = moment(formatedGiven, "YYYY-MM-DD");
  const current = moment().startOf("day");
  return moment.duration(given.diff(current)).asDays() == 0 ? true : false;
};