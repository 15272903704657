import * as types from "../types/jobRoleSetup";

const INITIAL_STATE = {
    list:{},
    approvals:{},
    job_roles_with_Approval:[]
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.GET_ALL_JOB_ROLES_RECEIVE:
            return { ...state,list:{...action.payload}};
        case types.GET_JOB_ROLE_RECEIVE:
            return { ...state, ...action.payload};
        case types.EDIT_JOB_ROLE_RECEIVE:
            return { ...state, ...action.payload};
        case types.ADD_JOB_ROLE_RECEIVE:
            return { ...state, ...action.payload};
        case types.DELETE_JOB_ROLE_RECEIVE:
            return { ...state, ...action.payload};
        case types.APPROVAL_SETUP_RECIEVE:
            return { ...state,approvals: { ...action.payload}};
        case types.SAVE_APPROVAL_SETUP_RECIEVE:
            return { ...state ,...action.payload};
        case types.JOB_ROLES_WITH_APPROVAL_REACIEVE:
            return { ...state,job_roles_with_Approval:[...action.payload]};
        default: return state;
    }
}